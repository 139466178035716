import React, { useEffect, useRef } from 'react';
import GameButton from './GameButton';
import elipsis from '../../assets/elipsis.png'
import { Link } from 'react-router-dom';
import { useOutsideClick } from '../../pages/admin/UseOutsideClick';
import '../../App.css'


export default function ObjectiveModal({isOpen, onClose }) {

  
const objectives=localStorage.getItem('objectives')

  return (
    <>
     {
isOpen &&
<>
       <div className="absolute inset-0   "></div>
      <div  className="z-50  shadow-md p-4  h-auto w-[80vw] sm:ml-0 ml-10 md:w-[35vw]  box rounded-lg shadow-lg text-center">
        <p className="text-2xl game-font font-semibold mb-2 text-3xl">Game Objectives</p>
     <img src={elipsis} alt='...' className='h-[1.5px] w-full '/>
    
     <div className=' flex flex-col justify-between  min-h-[32vh] border-2 my-6 mx-2 rounded-lg border-[#EF9910]'>

<div className=' text-white md:text-justify text-center max-h-[32vh] overflow-y-auto  scrollbar text-[24px] md:text-2xl  flex  pr-5 mx-2 mt-2'>
    <p>
        “{objectives}”
    </p>
</div>


</div>



     <GameButton text={'Close'} classes={' text-lg cursor-pointer w-1/2  '} callFunction={onClose} />


<div className='relative'>
     <img src={elipsis} alt='...' className='h-[4px] w-full mt-5'/>
     <div className='absolute pin-box h-12 -top-5 w-full'></div>
</div>
     
      
      </div>
      </>
  }
    </>
  );
}
