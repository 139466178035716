import React from 'react';
import AdminButton from './AdminButton';
import Table from './Table';

function TextFieldsDrawer({ heading,subheading,isOpen, onClose , btnHandler,editData,textFields,addTextField,deleteTextField,shouldShowInputFieldModal,updateFieldRecord, isStudent=false, hideField}) {







const onUpdateCall=(data)=>
{
  updateFieldRecord(data)
}



  return (
    <div
      className={`fixed top-0 right-0 h-screen overflow-y-auto w-5/6  md:w-[597px] bg-white border-l shadow-lg transform transition-transform duration-300 admin-font ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
   
   <div className=' flex items-center  h-[102px] bg-[#F4F5F7] border border-[#CACACA]'>
    <div className='ml-5'>

    <h1 className='text-[24px]'>{heading}</h1>
    <p className='text-[14px] text-[#787878]'>{subheading}</p>
    </div>

      <button onClick={onClose} className="absolute bg-white text-red-500 h-6 w-6 font-bold shadow rounded-full top-4 right-4 text-gray-500 hover:text-gray-700">
        x
      </button>
   </div>




<div className='mr-2 md:mr-10 mt-5'>

<Table headers={['Label','Value','Type','Status']} data={textFields!==undefined?textFields:[]} editRecord={onUpdateCall} deleteRecord={deleteTextField} isStudent={isStudent} hideField={hideField}/>
</div>
{
!isStudent &&
<div className='mt-10 flex justify-center ml-5 '>
<AdminButton btnText={'Add more'} classess={'w-[200px] md:w-[317px] h-[44px] '} onClickCall={shouldShowInputFieldModal}/> 
</div>
}
  


    </div>


  );
}

export default TextFieldsDrawer;


