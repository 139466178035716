import React, { useState, useEffect } from "react";
import addMediaIcon from "../../assets/admin/addMedia.png";
const ImageSelector = ({ setFile, previousImage, initialImage }) => {
  const [selectedImage, setSelectedImage] = useState();
  //  const [previousImage,setPreviousImage]=useState()
  
  useEffect(() => {

    setSelectedImage(null)
  },[]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const prev = selectedImage;
        // setPreviousImage(prev)
        setSelectedImage(reader.result);
        setFile(e);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };



  return (
    <div>
      <div className="grid grid-cols-2 items-center place-items-center gap-x-2">
        <div >
          {previousImage || initialImage ? (
            <img
              src={previousImage ? previousImage : initialImage}
              alt="Existing Preview"
              className="max-h-20 rounded-md"

            />
          ) : (
            <p>No existing image</p>
          )}
        </div>
        <div>
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Selected Preview"
              className="max-h-20 rounded-md"
            />
          ) : (
            <div>No Image selected</div>
          )}
        </div>
      </div>

      <label htmlFor="file" className="cursor-pointer text-[#0E9F6E] mt-1 ">
        {/* <img src={addMediaIcon} alt="..." width="18px" /> */}
        <p className="pl-2 border-2 relative left-0 border-[#0E9F6E] inline-block mt-2 rounded-xl px-2" >Choose File</p>
      </label>
      <input
        id="file"
        name="file"
        type="file"
        accept="image/*"
        className="hidden"
        onChange={handleImageChange}
      />
    </div>
  );
};

export default ImageSelector;
