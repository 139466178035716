import React, { useRef, useState } from 'react'
import Header from '../../components/trainer/Header'
import Search from '../../assets/search.png'
import JsonData from './JsonData'
import LeftArrow from '../../assets/pagination-left-arrow.png'
import RightArrow from '../../assets/pagination_right_arrow.png'
import ArrowDown from '../../assets/arrow-down.png'
import LeftArrowSimple from '../../assets/leftArrow_simple.png'
import { useOutsideClick } from '../admin/UseOutsideClick'
import { useEffect } from 'react'
import WithAuth from '../../apiService/withAuth'
import apiService from '../../apiService/apiService'
import Toast from '../../components/Toast'
import ItemsModal from '../../components/ItemsModal'
import Legals from '../../components/Legals'
import FilterIcon from '../../assets/filterIcon.png'
import spinner from '../../assets/spinner.svg'



export default function Statistics() {

    const isAuthenticated=WithAuth('tad')
    const pin_code=atob(localStorage.getItem("tid"))
    const [isMenuVisible, setMenuVisible] = useState(Array(JsonData.length).fill(false));
    const modalRef = useRef(null);
    const [sortData,setSortData]=useState({
        orderBy:'',
        order:'ASC'
    })


  document.title = `ScanQuest | Trainer | Statistics`;


    const [product,setProduct]=useState([])
    const [data,setData]=useState([])
    const [modalItems,setModalItems]=useState([])
    const [classess,setClassess]=useState([])
    
    const [loading,setLoading]=useState(true)

    const [showFilter,setShowFilter]=useState(false)
  
  const [filterData,setFilterData]=useState({
    search_query:'',
    game_name:'',
    student_class:''
  })

  const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(100);
    const [pageSize,setPageSize] = useState(10);
  

  // Calculate the total number of pages
  const [totalPages,setTotalPages] =useState()


  const [showItemsModal,setItemsModal]=useState(false)
  const [personName,setPersonName]=useState()

  // Pagination logic to handle page changes
  const handlePageChange = (page) => {
    if(currentPage!==page)
  {


    setCurrentPage(page);


    if(filterData.search_query.length || filterData.game_name)
    {
      filterData.game_id=null
    
  

    
  
      filterData.game_id=atob(localStorage.getItem('tgid'))
    
      filterData.current_page=page
      filterData.page_size=pageSize
      apiService.post('/students/search',filterData,'tad').then((res)=>
      {
        setTotalRecords(res.data.total_records)
        setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
        setData(res.data.data.data)
      }).catch((err)=>
      {
        if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
      })
    }

    else
    {

        apiService.get(`/students/get-all?orderBy=${sortData.orderBy}&order=${sortData.order}&pageSize=${pageSize}&pageNumber=${page}&game_id=${atob(localStorage.getItem('tgid'))}`,'tad').then((res)=>
        {        
        setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setData(res.data.data.data)
    }).catch((err)=>{
      
      if(err.response.status===401)
      {
        
        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
    })
  }
  }
  };

  const generatePageNumbers = () => {
    const maxVisiblePages = 7;
    const pageNumbers = [];
    if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    } else {
        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
        if (endPage - startPage < maxVisiblePages - 1) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        if (startPage > 1) {
            pageNumbers.unshift('...');
        }
        if (endPage < totalPages) {
            pageNumbers.push('...');
        }
    }
    return pageNumbers;
};
  // Generate pagination numbers based on total pages
  const paginationNumbers = generatePageNumbers().map((pageNumber, index) => (
    <li key={index}>
        {pageNumber === '...' ? (
            <span className="px-2">...</span>
        ) : (
            <button
                className={`flex items-center justify-center px-3 h-8 leading-tight ${
                    currentPage === pageNumber ? 'text-white font-semibold rounded-md bg-[#404EED]' : 'text-gray-500 bg-white'
                }`}
                onClick={() => handlePageChange(pageNumber)}
            >
                {pageNumber}
            </button>
        )}
    </li>
));


  const handlePreviousPage = () => {
    if (currentPage > 1) {
        handlePageChange(currentPage - 1);
    }
};

const handleNextPage = () => {
    if (currentPage < totalPages) {
        handlePageChange(currentPage + 1);
    }
};


const handlePageSizeChange=(e)=>
{
    setPageSize(e.target.value)

 if(filterData.search_query.length || filterData.student_class || filterData.game_name)
    {

      filterData.current_page=currentPage
      filterData.page_size=pageSize
      apiService.post('/students/search',filterData,'tad').then((res)=>
      {

        setTotalRecords(res.data.total_records)
        setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
        setData(res.data.data.data)
      }).catch((err)=>
      {
        Toast(err.response.data.error,0)
      })
    }
      else
      {
    apiService.get(`/students/get-all?pageSize=${e.target.value}&pageNumber=${1}&pin_code=${pin_code}&&game_id=${atob(localStorage.getItem('tgid'))}`,'tad').then((res)=>
    {
        const uniqueClasses = [...new Set(res.data.data.data.map(item => item.class))];
        setClassess(uniqueClasses)
        setTotalRecords(res.data.total_records)
        setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setData(res.data.data.data)

    }).catch((err)=>
    {
    
      if(err.response.status===401)
      {

        Toast(err.response.data.error,0)
        localStorage.clear()
        WithAuth('tad')
        
      }
      Toast(err.response.data.error,0)
    })
  }

}

  const setValue = (e) => {
    const { id, value } = e.target; // Destructure id and value from e.target
  

  
    setFilterData({
      ...filterData,
      [id]: value
    });
  
   
      
  }

  
  const resetFilters=async()=>
  {
    setFilterData(
      {
        search_query:'',
        game_name:'',
        student_class:''
      }
    )
    setShowFilter(false)
    setLoading(true)
    apiService.get(`/students/get-all?pageSize=${pageSize}&pageNumber=${1}&game_id=${atob(localStorage.getItem('tgid'))}`,'tad').then((res)=>
    {
        const uniqueClasses = [...new Set(res.data.data.data.map(item => item.class))];
        setClassess(uniqueClasses)
        setTotalRecords(res.data.total_records)
        setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
        setCurrentPage(1)
      setData(res.data.data.data)
      setLoading(false)

    }).catch((err)=>
    {
    
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('tad')
        
      }
      Toast(err.response.data.error,0)
    })
    }
  
  useEffect(()=>
  {

    let data={...filterData}

  

      data.game_id=atob(localStorage.getItem('tgid'))
    
  
    data.current_page=1
    data.page_size=pageSize
  
    if( filterData.search_query.length || filterData.student_class || filterData.game_name )
    {

      
      apiService.post('/students/search',data,'tad').then((res)=>
      {

        setTotalRecords(res.data.total_records)
        setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
        setData(res.data.data.data)
        setShowFilter(true)
      }).catch((err)=>
      {
        Toast(err.response.data.error,0)
      })
    }
    else
    {
      setShowFilter(false)

      apiService.get(`/students/get-all?pageSize=${pageSize}&pageNumber=${1}&game_id=${atob(localStorage.getItem('tgid'))}`,'tad').then((res)=>
      {
          const uniqueClasses = [...new Set(res.data.data.data.map(item => item.class))];
          setClassess(uniqueClasses)
          setTotalRecords(res.data.total_records)
          setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
        setData(res.data.data.data)
  
      }).catch((err)=>
      {
      
        if(err.response.status===401)
        {
  
          Toast(err.response.data.message,0)
          localStorage.clear()
          WithAuth('tad')
          
        }
        Toast(err.response.data.error,0)
      })
    }
  },[filterData])

    // Custom hook usage
    useOutsideClick(modalRef, () => {
      // Close the dropdown when a click occurs outside the modal
      setProduct([])
      setMenuVisible(Array(JsonData.length).fill(false));
    });
    const toggleMenu = (index,name) => {
       
        const prod = {
            "products": data[index].products
          };
          
    apiService.post('/students/get-products',prod,'tad').then((res)=>
    {
            setPersonName(name)
            const responseData= res.data.data
            setModalItems(responseData)
            setItemsModal(true)
        const updatedMenuVisibility = [...isMenuVisible];
        updatedMenuVisibility[index] = !updatedMenuVisibility[index];
        setMenuVisible(updatedMenuVisibility);
        }).catch((err)=>
        {
          if(err.response.status===401)
      {
        
        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
        })
    };



    const sortBy=(columnName)=>
    {

        let newOrder;
    
    if(sortData.orderBy===columnName)
    {
        
    
        
      newOrder=sortData.order==='ASC'?'DESC':'ASC'
       
        setSortData({
            orderBy: columnName,
            order: newOrder
          });
    }
    else
    {
        
        setSortData({
            orderBy: columnName,
            order: 'ASC'
          });
          newOrder='ASC'
    }

    apiService.get(`/students/get-all?orderBy=${columnName}&order=${newOrder}&pageSize=${pageSize}&pageNumber=${1}&game_id=${atob(localStorage.getItem('tgid'))}`,'tad').then((res)=>
    {
        const uniqueClasses = [...new Set(res.data.data.data.map(item => item.class))];
        setClassess(uniqueClasses)
        
      setData(res.data.data.data)

    }).catch((err)=>
    {
  
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('tad')
        
      }
      Toast(err.response.data.error,0)
    })
    }
    
  const showProduct=(index)=>
  {
    const productData=[{
        image:modalItems[index].image,
        name:modalItems[index].name,
        price:modalItems[index].price,
        quantity:modalItems[index].quantity,
        happiness_rating:modalItems[index].happiness_rating
      }]
      setProduct(productData)
 
  }

  const onOutsideModalClick=()=>
  {
    setItemsModal(false)
    setPersonName('')
  }
  useEffect(()=>
  {},[product])

  useEffect(()=>{
    apiService.get(`/students/get-all?pageSize=${pageSize}&pageNumber=${1}&game_id=${atob(localStorage.getItem('tgid'))}`,'tad').then((res)=>
    {
        const uniqueClasses = [...new Set(res.data.data.data.map(item => item.class))];
        setClassess(uniqueClasses)
        setTotalRecords(res.data.total_records)
        setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setData(res.data.data.data)
      setLoading(false)

    }).catch((err)=>
    {
    
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('tad')
        
      }
      Toast(err.response.data.error,0)
    })
  },[])
    return (
        isAuthenticated &&    <div>
            <Header isStats={true} showFilter={showFilter} />

            <div className='flex justify-center mt-8 space-x-2 md:space-x-8 mx-2 md:mx-10 trainer-stats-font'>


                <select id="default"  onChange={handlePageSizeChange} className="w-[108px] md:text-[12px] text-[16px] md:w-[224px] font-semibold text-center focus:border-[#404EED] focus:outline-[#404EED] focus:text-[#404EED] border-[1.2px] border-[#CACACA] text-[#787878] mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5">
                    <option value='10' defaultValue >Show 10 entries</option>
                    <option value="25">Show 25 entries</option>
                    <option value="50">Show 50 entries</option>
                    <option value="75">Show 75 entries</option>
                    <option value="100">Show 100 entries</option>
                </select>


                <select id="student_class" value={filterData.student_class} onChange={setValue} className="w-[108px] md:w-[224px] md:text-[12px] text-[16px] font-semibold text-center  border-[1.2px] border-[#CACACA] focus:border-[#404EED] focus:outline-[#404EED] focus:text-[#404EED] text-[#787878] mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 ">
                    <option  selected hidden >Class</option>
               
        {
    classess.map((className, index) => {
     
        return <option key={index} value={className}>{className}</option>;
    })
}
                </select>


                <div className='flex-1'>

                    <form>
                        <label htmlFor="default-search" className="mb-2 md:text-[12px] text-[16px] font-medium text-gray-900 sr-only ">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <img src={Search} alt='...' />
                            </div>
                            <input type="search"  id='search_query' value={filterData.search_query} onChange={setValue} className="block md:text-[12px] text-[16px] font-semibold w-full focus:text-[#404EED] outline-[#404EED] h-[42px] pl-12 text-sm text-[#787878] bg-[#CACACA] border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 " placeholder="Search" required />
                        </div>
                    </form>
                </div>


            </div>




            <div className="relative overflow-x-auto shadow-md sm:w-lg mx-2 md:mx-10  rounded-lg  border-[#CACACA]  border-[1.2px] trainer-stats-font pb-[1px] ">
                <table className="w-full m text-sm text-left text-gray-500 rounded-lg border-[#CACACA]  border-[1.2px] ">
                    <thead className="text-xs text-[#313131] uppercase bg-[#F1F5F9] ">
                        <tr>

                            <th scope="col" className="px-6 py-1 border-[#CACACA] border-t-none border-[1.2px] ">
                                <div className="flex items-center">
                                    Class
                                    <svg onClick={()=>sortBy('student_class')} className="w-3 h-3 ml-1.5 text-[#404EED]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-1 border-[#CACACA] border-[1.2px]">
                                <div className="flex items-center">
                                    Name
                                    <svg onClick={()=>sortBy('student_name')} className="w-3 h-3 ml-1.5 text-[#404EED]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-1 border-[#CACACA] border-[1.2px]">
                                <div className="flex items-center">
                                    Total Guests
                                    <svg onClick={()=>sortBy('total_guests')}  className="w-3 h-3 ml-1.5 text-[#404EED]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-1 border-[#CACACA] border-[1.2px]">
                                <div className="flex items-center">
                                    Available Funds
                                    <svg onClick={()=>sortBy('available_fund')}  className="w-3 h-3 ml-1.5 text-[#404EED]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg>
                                </div>
                            </th>

                            <th scope="col" className="px-6 py-1 border-[#CACACA] border-[1.2px]">
                                <div className="flex items-center">
                                    Total Cost
                                    <svg onClick={()=>sortBy('total_cost')}  className="w-3 h-3 ml-1.5 text-[#404EED]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-1 border-[#CACACA] border-[1.2px]">
                                <div className="flex items-center">
                                    Total Happiness Rating
                                    <svg onClick={()=>sortBy('total_happiness_rating')}  className="w-3 h-3 ml-1.5 text-[#404EED]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-1 border-[#CACACA] border-[1.2px]">
                                <div className="flex items-center">
                                    Balance Shortfall
                                    <svg onClick={()=>sortBy('balance_shortfall')}  className="w-3 h-3 ml-1.5 text-[#404EED]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-1 border-[#CACACA] border-[1.2px]">
                                <div className="flex items-center text-center">
                                    Adjusted<br></br> Happiness<br></br> Rating
                                    <svg onClick={()=>sortBy('adjusted_happiness_rating')}  className="w-3 h-3 ml-1.5 text-[#404EED]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg>
                                </div>
                            </th>

                            <th scope="col" className="px-6 py-1 border-[#CACACA] border-[1.2px]  ">
                                <div className="flex items-center">
                                    View Items
                                
                                </div>
                            </th>
                        </tr>
                    </thead>


                    <tbody className={`text-[#787878] ${ loading?'h-36':''}`}>

                    {loading?
                         <div className="absolute left-1/3 md:left-1/2 ml-10 mt-10 text-black">
                         <img className='animate-spin text-center h-8 bg-[#404EED] rounded-md p-1 mx-auto' src={spinner} alt='Loading'/>
        
                        </div>
                        : data && data.length===0?
                         <div className="absolute left-1/3 md:left-1/2 mt-10 font-semibold">No Data Found.</div>
                        :data && data.map((item, index) => {

                            return <tr key={index} className="bg-white border-b text-[#787878]">
                                <td className="px-6 py-4 border-[#CACACA] border-[1.2px]">
                                    {item.class}
                                </td>
                                <td className="px-6 py-4 border-[#CACACA] border-[1.2px]">
                                    {item.name}
                                </td>
                                <td className="px-6 py-4 border-[#CACACA] border-[1.2px]">
                                    {item.total_guests}
                                </td>
                                <td className="px-6 py-4 border-[#CACACA] border-[1.2px]">
                                    {item.available_fund}
                                </td>

                                <td className="px-6 py-4 border-[#CACACA] border-[1.2px]">
                                    {item.total_cost}
                                </td>

                                <td className="px-6 py-4 border-[#CACACA] border-[1.2px]">
                                    {item.total_happiness_rating}
                                </td>


                                <td className="px-6 py-4 border-[#CACACA] border-[1.2px]">
                                    {item.balance_shortfall}
                                </td>


                                <td className="px-6 py-4 border-[#CACACA] border-[1.2px]">
                                    {item.adjusted_happiness_rating}
                                </td>


                                <td className="px-6 py-4 border-[#CACACA] border-[1.2px]">

                                    <button
                                        onClick={() => toggleMenu(index,item.name)}
                                        className="flex items-center trainer-stats-font hover:text-[#404EED]"
                                    >
                                        View Items
                                       
                                    </button>
                               
                                </td>

                            </tr>
                        })}

                    </tbody>
                </table>
            </div>

            <nav aria-label="Page navigation example trainer-stats-font">
            <ul className="flex justify-end my-5 mr-8 -space-x-px text-sm text-white items-center">
                    <li className="mr-2 cursor-pointer" onClick={handlePreviousPage}>
                        <img src={LeftArrow} alt="..." />
                    </li>
                    {paginationNumbers}
                    <li className="ml-2 cursor-pointer" onClick={handleNextPage}>
                        <img src={RightArrow} alt="..." />
                    </li>
                </ul>
        </nav>
        <ItemsModal from={"trainer"} name={personName} showItemsModal={showItemsModal} modalItems={modalItems} onOutsideClick={onOutsideModalClick}/>
        <Legals classes={' text-center my-2 text-[#404EED]'}/>

        {
 
 showFilter  &&
 <div onClick={resetFilters} className='absolute cursor-pointer z-50 top-[22.5px] right-5 bg-[#404EED] rounded-md p-2'>
 <img src={FilterIcon}
 className='h-6'
 />
 </div>
 }
        </div>
    )
}
