import React from 'react'
import AdminButton from './AdminButton'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Legals from '../Legals'

export default function AdminSidebar() {

    const location = useLocation()
    const navvigate=useNavigate()

    const logout=()=>
    {
        localStorage.clear()
        navvigate('/admin')
    }


    return (
        <div className='w-[15vw] h-screen admin-font md:block hidden'>
            <div className='flex mt-5 items-center ml-3 space-x-2'>
                <h1 className='text-[#0E9F6E] text-[24px] font-bold '>SQ</h1>
                <h1 className='text-[22px] font-semibold'>scanQuest</h1>
            </div>

            <div className='flex flex-col justify-between h-[85%]'>

                <div className=' space-y-6 mt-10  '>
                    <div className={`pl-3 cursor-pointer ${location.pathname === '/admin/games' ? 'border-l-[3px] border-[#0E9F6E] text-[#0E9F6E]' : 'text-[#818181]'}`}>

                        <Link to='/admin/games'>
                            <div className='flex items-center space-x-2'>


                                <span className=''>

                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.57002 12.46L6.52002 15.51" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.55005 12.4902L9.60005 15.5402" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M13.53 14H13.54" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.47 14H17.48" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15.5 15.98V15.96" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15.5 12.04V12.02" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9 22H15C20 22 22 20 22 15V13C22 8 20 6 15 6H9C4 6 2 8 2 13V15C2 20 4 22 9 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M13.01 2L13 3.01C12.99 3.56 12.55 4 12 4H11.97C11.42 4 10.98 4.45 10.98 5C10.98 5.55 11.43 6 11.98 6H12.98" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </span>
                                <h1 >Games</h1>
                            </div>
                        </Link>
                    </div>

                    <div className={`pl-3  cursor-pointer ${location.pathname === '/admin/categories' ? 'border-l-[3px] border-[#0E9F6E] text-[#0E9F6E]' : 'text-[#818181]'}`}>
                        <Link to='/admin/categories'>
                            <div className='flex items-center  space-x-2'>

                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.09998 19.25H16.9C18.4 19.25 19 18.61 19 17.02V15.98C19 14.39 18.4 13.75 16.9 13.75H5.09998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5.09998 5.25H11.9C13.4 5.25 14 5.89 14 7.48V8.52C14 10.11 13.4 10.75 11.9 10.75H5.09998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5 1.99023V21.9902" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <h1 >Categories</h1>
                            </div>
                        </Link>
                    </div>

                    <div className={`pl-3  cursor-pointer ${location.pathname === '/admin/products' ? 'border-l-[3px] text-[#0E9F6E] border-[#0E9F6E] text-[#0E9F6E]' : 'text-[#818181]'}`}>
                        <Link to='/admin/products'>
                            <div className='flex items-center  space-x-2'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.17001 7.43994L12 12.5499L20.77 7.46991" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 21.61V12.54" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.92999 2.48L4.59 5.45003C3.38 6.12003 2.39001 7.80001 2.39001 9.18001V14.83C2.39001 16.21 3.38 17.89 4.59 18.56L9.92999 21.53C11.07 22.16 12.94 22.16 14.08 21.53L19.42 18.56C20.63 17.89 21.62 16.21 21.62 14.83V9.18001C21.62 7.80001 20.63 6.12003 19.42 5.45003L14.08 2.48C12.93 1.84 11.07 1.84 9.92999 2.48Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17 13.2401V9.58014L7.51001 4.1001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <h1>Product</h1>
                            </div>
                        </Link>
                    </div>
                 

                    <div className={`pl-3  cursor-pointer ${location.pathname === '/admin/happiness-rating' ? 'border-l-[3px] text-[#0E9F6E] border-[#0E9F6E] text-[#0E9F6E]' : 'text-[#818181]'}`}>
                        <Link to='/admin/happiness-rating'>
                            <div className='flex items-center  space-x-2'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7 8.25C8 9.25 9.63 9.25 10.64 8.25" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.3601 8.25C14.3601 9.25 15.9901 9.25 17.0001 8.25" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.4 13H15.6C16.1 13 16.5 13.4 16.5 13.9C16.5 16.39 14.49 18.4 12 18.4C9.51 18.4 7.5 16.39 7.5 13.9C7.5 13.4 7.9 13 8.4 13Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
                      <h1>Happiness Rating</h1>
                            </div>
                        </Link>
                    </div>


                    <div className={`pl-3  cursor-pointer ${location.pathname === '/admin/4-digit-code' ? 'border-l-[3px] text-[#0E9F6E] border-[#0E9F6E] text-[#0E9F6E]' : 'text-[#818181]'}`}>
                        <Link to='/admin/4-digit-code'>
                            <div className='flex items-center  space-x-2'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5399 8.31014C18.8986 8.31014 20 7.20876 20 5.85014C20 4.49152 18.8986 3.39014 17.5399 3.39014C16.1813 3.39014 15.08 4.49152 15.08 5.85014C15.08 7.20876 16.1813 8.31014 17.5399 8.31014Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.46001 8.31014C7.81863 8.31014 8.92 7.20876 8.92 5.85014C8.92 4.49152 7.81863 3.39014 6.46001 3.39014C5.10139 3.39014 4 4.49152 4 5.85014C4 7.20876 5.10139 8.31014 6.46001 8.31014Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.5399 20.6099C18.8986 20.6099 20 19.5086 20 18.1499C20 16.7913 18.8986 15.6899 17.5399 15.6899C16.1813 15.6899 15.08 16.7913 15.08 18.1499C15.08 19.5086 16.1813 20.6099 17.5399 20.6099Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.46001 20.6099C7.81863 20.6099 8.92 19.5086 8.92 18.1499C8.92 16.7913 7.81863 15.6899 6.46001 15.6899C5.10139 15.6899 4 16.7913 4 18.1499C4 19.5086 5.10139 20.6099 6.46001 20.6099Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

                      <h1>4 digit code</h1>
                            </div>
                        </Link>
                    </div>

                    <div className={`pl-3  cursor-pointer ${location.pathname === '/admin/students' ? 'border-l-[3px] text-[#0E9F6E] border-[#0E9F6E] text-[#0E9F6E]' : 'text-[#818181]'}`}>
                        <Link to='/admin/students'>
                            <div className='flex items-center  space-x-2'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.59 22C20.59 18.13 16.74 15 12 15C7.26003 15 3.41003 18.13 3.41003 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

                      <h1>Students</h1>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className='flex justify-center '>
                    <AdminButton btnText={'Log Out'} onClickCall={logout} />
                </div>
            </div>
                <Legals classes={' text-green-600 text-xs mt-3 text-center '}/>
        </div>
    )
}
