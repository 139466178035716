import React, { useState, useEffect, useRef } from 'react';
import Headers from '../../components/game/Headers';
import QrReader from 'react-qr-scanner';
import GameButton from '../../components/game/GameButton';
import jsQR from 'jsqr';
import camera_flip from '../../assets/camera_flip.svg'

export default function Scan({ setScanner }) {
  const [cameraFacingMode, setCameraFacingMode] = useState('environment');
  const [loading,setLoading]=useState(false)

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const initialFacingMode = isMobileDevice ? 'environment' : 'user';

  const [facingMode, setFacingMode] = useState(initialFacingMode);
  const [qrData, setQrData] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [flipping, setFlipping] = useState(false);


    
  useEffect(()=>{
   
    startScanning();

},[flipping])

  
  const toggleCamera = () => {
    if (scanning) {
      setScanning(false); // Stop scanning
      const video = videoRef.current;
      const stream = video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    } else {
      // setFacingMode((prevFacingMode) =>
      //   prevFacingMode === 'user' ? 'environment' : 'user'
      // );
      setScanning(true); // Start scanning
      handleVideoStream();
      checkQRCode();
    }
  };

  
  const stopScanning = () => {
    setScanning(false);
    const video = videoRef.current;
    const stream = video.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
  };

  const startScanning = () => {
    setScanning(true);
   
    handleVideoStream();
    checkQRCode();
  };
  

  
  const toggleCam = () => {
   
    stopScanning();
    const video = videoRef.current;
    const stream = video.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
  
  
  setFacingMode((prevFacingMode) =>
    prevFacingMode === 'user' ? 'environment' : 'user'
  );





 
   setFlipping((prev)=>!prev)
  
  
};


const handleVideoStream = () => {
  if (scanning) {
    const startVideoStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode },
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error('Error accessing video stream:', error);
      }
    };

    startVideoStream();
  }
};


const checkQRCode = () => {
  const video = videoRef.current;
  const canvas = canvasRef.current;
  const context = canvas.getContext('2d');

  const scanFrame = () => {
    if (!scanning) return;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const code = jsQR(imageData.data, imageData.width, imageData.height);

    if (code) {
  
      setQrData(code.data);
      setScanner(false,code.data)
      setScanning(false);

      // Stop the video stream explicitly
      const stream = video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    } else {
      requestAnimationFrame(scanFrame);
    }
  };

  scanFrame();
};



  const handleScan = (data) => {
    if (data) {
      setLoading(true)
      setScanner(false, data);
    }
  };

  
  React.useEffect(() => {
    handleVideoStream(); // Start video stream when scanning state changes
    checkQRCode(); // Trigger the QR code checking when scanning state changes
  }, [scanning]);


  const handleStopScanningButton=()=>
  {
    setScanning(false)
    const video = videoRef.current;
    const stream = video.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
    
    setScanner(false)
  }
  const handleError = (error) => {
    console.error(error);
  };

  return (
    <div>
      {/* headerCLasses={' mx-2 my-2'} */}
      <Headers text={'Scan Your Product'} classes={'text-[28px] md:text-6xl'} />

      <div className="flex justify-center items-center px-5 md:px-0 h-[65vh]  bg-gray-200/50 mx-4 md:mx-10 my-10 md:my-2 rounded-lg">
      {
        loading?
        <div className='flex justify-evenly'>

        <button type="button" class="bg-indigo-500 ..." disabled>
  <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
  
  </svg>
  Processing...
</button>
</div>
:
  
        <div className="text-center">
          <div className="relative md:w-[450px] md:h-[405px] py-5 rounded-lg">
          
            {/* <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              className="rounded-lg w-full"
              facingMode={cameraFacingMode}
            /> */}

<video ref={videoRef} width="640" height="480" autoPlay playsInline style={{ display: scanning ? 'block' : 'none' }}></video>
<canvas ref={canvasRef} width="640" height="480" style={{ display: 'none' }}></canvas>
<div onClick={toggleCam} className='absolute cursor-pointer  right-1 md:right-2 bottom-6 md:bottom-14 z-100 text-white h-6 w-6'

>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="30px" height="30px">
          <path d="M 20 4 C 14.507813 4 10 8.507813 10 14 L 10 31.75 L 7.125 28.875 L 4.3125 31.71875 L 12 39.40625 L 19.6875 31.71875 L 16.875 28.90625 L 14 31.75 L 14 14 C 14 10.691406 16.691406 8 20 8 L 31 8 L 31 4 Z M 38 10.59375 L 30.28125 18.3125 L 33.125 21.125 L 36 18.25 L 36 36 C 36 39.308594 33.308594 42 30 42 L 19 42 L 19 46 L 30 46 C 35.492188 46 40 41.492188 40 36 L 40 18.25 L 42.875 21.125 L 45.6875 18.28125 Z"  stroke="#ffffff"  fill='#ffffff' color='#ffffff'/>

                                    </svg>
          </div>

</div>
          <div onClick={handleStopScanningButton}>
            <GameButton text={'X Stop Scanning'} />
          </div>
        </div>
            }
      </div>
    </div>
  );
}