import React from 'react'
import { useNavigate } from 'react-router-dom';
import arrow from '../../assets/arrowSquareLeft.png'


export default function GoBack({text,classes}) {

    const navigate=useNavigate()
  const goBack=()=>
  {
    navigate(-1)
  }
    

  return (
    <div className={` top-5 left-5 flex items-center ${classes} `}>
    <div onClick={goBack} className='w-[20px] md:w-[36px] h-[20px] md:h-[36px] rounded-md text-center cursor-pointer'>
     <img src={arrow} alt='...'/>
    </div>
    <h1 className='game-font ml-2 text-[20px] md:text-2xl text-white'>{text}</h1>
 </div>
  )
}
