import React from 'react'
import Plus from '../../assets/plus.png'
import Minus from '../../assets/minus.png'
import StatsTile from './StatsTile'
import { useState, useEffect } from 'react'



export default function ProductContainer({ number,image, title, price, rating, quantity, onMinusClick, decreaseCount, increaseCount }) {

    const [count, setCount] = useState(quantity)

    useEffect(() => { }, [count])
    // const decreaseCount = () => {
    //     if (Number(count) > 1) {
    //         setCount(Number(count) - 1)
    //     }
    // }

    // const increaseCount = () => {

    //     setCount(Number(count) + 1)



    // }
    return (
        <div className='flex ml-4 mt-4'>
            <div className='h-[40px] w-[40px] rounded-full game-font flex justify-center items-center number-style'>
                <h1 className='text-xl'>{number}</h1>
            </div>

            <div className='box h-[311px] w-[231px] rounded-lg mt-6 ml-2'>
                <div className='relative h-[209px] h-[196px]   px-2 py-2'>
                    <img className='h-full w-full z-10 shadow-lg rounded-lg opacity-[0.5]' src={image} alt='' />

                    <button onClick={()=>onMinusClick(number)}  className='h-[24px] w-[24px] top-4 right-3 absolute rounded-full game-font flex justify-center items-center number-style'>
                <h1 className='text-xl'>-</h1>
            </button>
                    <div className='absolute bottom-2 game-font left-5 '>
                        <h1 className='text-[24px] break-words w-[200px]'>{title}</h1>
                        <h1 className='text-[20px]'>${price}</h1>
                    </div>
                </div>

                <div>
                    <StatsTile text='Happiness Rating' price={rating} classes={'flex justify-between items-center mr-4'} divHeight={'h-[35px]'} />
                    <div className='game-font ml-3 flex relative justify-between items-center mr-4 mt-4'>
                        <h1 className='text-xl'>Quantity</h1>
                        <div className='flex space-x-2 items-center'>
                            <img onClick={()=>decreaseCount(number-1,quantity)} src={Minus} alt='...' 
                             className={`absolute ${Number(quantity)<10? 'right-12':'right-14'} cursor-pointer`}
                            />
                            <h1 className='text-xl absolute right-8'>{quantity}</h1>
                            <img 
                             className='absolute right-0 cursor-pointer'
                
                            
                            onClick={()=>increaseCount(number-1,quantity)} src={Plus} alt='...' />
                            
                            {/* <button onClick={()=>onMinusClick(number)}  className='h-[24px] w-[24px] top-4 right-3 absolute rounded-full game-font flex justify-center items-center number-style'>
                <h1 className='text-xl'>-</h1>
            </button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
