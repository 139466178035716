import React, { useEffect, useRef } from 'react';
import GameButton from './GameButton';
import elipsis from '../../assets/elipsis.png'
import { Link } from 'react-router-dom';
import { useOutsideClick } from '../../pages/admin/UseOutsideClick';
import '../../App.css'

export default function Modal({isOpen, onClose, rules, adjusted_happiness_rating, total_happiness_rating }) {

  


  return (
    <>
     {
isOpen &&
<>
       <div className="absolute inset-0   "></div>
      <div  className="z-50  shadow-md p-4  h-auto w-[80vw] sm:ml-0 ml-10 md:w-[30vw]  box rounded-lg shadow-lg text-center">
        <p className="text-2xl font-semibold mb-2 md:mb-6 text-2xl md:text-4xl">Thank You!</p>

<div className='bg-[#FFCD14]/20 rounded-md mb-5'>

        <div className='flex justify-between py-2 px-3 bg-[#FFCD14]/30 rounded-md'>
        <p className=" font-semibold thanksModal text-lg md:text-2xl">Final Happiness Rating</p>
        <p className=" font-semibold thanksModal text-lg md:text-2xl ">{adjusted_happiness_rating}</p>
        </div>
     
        <div className='  text-center py-2 max-h-[45vh] overflow-y-auto scrollbar'>

        <div className='grid grid-cols-3 '>
  <div className='flex pl-8 col-span-2 items-center mb-6  w-full h-full'>
    <p className='text-lg md:text-xl game-font'>Total Happiness Rating</p>
  </div>
  <div className='flex justify-center items-center mb-6 w-full h-full'>
    <p className='text-lg md:text-xl game-font'>{total_happiness_rating}</p>
  </div>
</div>
        <div className='grid grid-cols-3 '>
  <div className='flex pl-8 col-span-2 items-center mb-6  w-full h-full'>
    <p className='text-lg md:text-xl game-font'>Adjusted Happiness Rating</p>
  </div>
  <div className='flex justify-center items-center mb-6 w-full h-full'>
    <p className='text-lg md:text-xl game-font'>-{total_happiness_rating-adjusted_happiness_rating}</p>
  </div>
</div>
<div className='grid grid-cols-3 '>
  <div className='flex pl-8 col-span-2 items-center mb-6  w-full h-full'>
    <p className='text-lg md:text-xl game-font'>Final Happiness Rating</p>
  </div>
  <div className='flex justify-center items-center mb-6 w-full h-full'>
    <p className='text-lg md:text-xl game-font'>{adjusted_happiness_rating}</p>
  </div>
</div>

    {/* <p className='mb-6 text-lg md:text-xl mt-1'> ${rule.rating_from} to ${rule.rating_to}</p>
    <p className='mb-6 text-lg md:text-xl mt-1'> ${rule.rating_from} to ${rule.rating_to}</p> */}
        <img src={elipsis} alt='...' className='h-[3px] mx-auto w-3/4 my-2'/>

        <p className=" font-semibold thanksModal text-2xl">Rules</p>
        <img src={elipsis} alt='...' className='h-[3px] mx-auto w-3/4 mt-2'/>
<div className='grid grid-cols-2'>
<p className=" font-semibold thanksModal text-2xl">Range</p>
<p className=" font-semibold thanksModal text-2xl">Deduction</p>


{
  rules && rules.map((rule,index)=>
  {
    return  <React.Fragment key={index}>
    {/* First set of values */}
    <p className='mb-6 text-lg md:text-xl mt-1'> ${rule.rating_from} to ${rule.rating_to}</p>

    {/* Second set of values */}
    <p className='mb-6 text-lg md:text-xl mt-1'> {rule.rating_deducted} </p>
  </React.Fragment>  })
}
</div>
</div>
  </div>
<Link to='/planning'>
     <GameButton text={'Return to HomePage'} classes={' text-lg cursor-pointer  '}/>
</Link>

<div className='relative'>
     <img src={elipsis} alt='...' className='h-[4px] w-full mt-5'/>
     <div className='absolute pin-box h-12 -top-5 w-full'></div>
</div>
     
      
      </div>
      </>
  }
    </>
  );
}
