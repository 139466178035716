import React from 'react'
import * as XLSX from 'xlsx';
import Headbar from '../../components/Admin/Headbar'
import SelectFilter from '../../components/Admin/SelectFilter'
import AdminButton from '../../components/Admin/AdminButton'
import AdminHeader from '../../components/Admin/AdminHeader'
import { useState } from 'react'
import Table from '../../components/Admin/Table'
import { useEffect } from 'react';
import DeleteModal from '../../components/Admin/DeleteModal';
import WithAuth from '../../apiService/withAuth';
import Toast from '../../components/Toast';
import apiService from '../../apiService/apiService';
import { ToastContainer } from 'react-toastify'
import TextFieldsDrawer from '../../components/Admin/TextFieldsDrawer'
import LeftArrow from '../../assets/pagination-left-arrow.png'
import RightArrow from '../../assets/pagination_right_arrow.png'
import FilterIcon from '../../assets/filterIcon.png'


export default function Students() {
  const isAuthenticated=WithAuth('ad')


  const [data, setData] = useState([])

  const [showCheckbox, setShowCheckbox] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState([])

  const [textFields,setTextFields]=useState(Array(data.length).fill([]))
  const [showInputFieldModal,setShowInputFieldModal]=useState(false)
  
  const [showFilter,setShowFilter]=useState(false)


  const [checkboxRecords, setCheckboxRecords] = useState(Array(data.length).fill(false));
  const [headerCheckbox,setHeaderCheckbox]=useState(false)
 
  const [showTextFields,setShowTextFields]=useState(false)

  const [filterData,setFilterData]=useState({
    search_query:'',
    game_name:''
  })

  document.title = `ScanQuest | Admin | Students`;



  const [loading,setLoading]=useState(true)


  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [pageSize] = useState(10);


// Calculate the total number of pages
const [totalPages,setTotalPages] =useState()


// Pagination logic to handle page changes
const handlePageChange = (page) => {

  if(currentPage!==page)
  {


    setCurrentPage(page);

    
    if(filterData.search_query || filterData.game_name)
    {
      filterData.game_id=null
    
  
      if(filterData.game_name)
      {
    
  
        filterData.game_id=JSON.parse(filterData.game_name).game_id
      }
      filterData.current_page=page
      apiService.post('/students/search',filterData,'ad').then((res)=>
      {
        setTotalRecords(res.data.total_records)
        setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
        setData(res.data.data.data)
      }).catch((err)=>
      {
        if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
      })
    }

    else
    {

      apiService.get(`/students/get-all?pageSize=${pageSize}&pageNumber=${page}`,'ad').then((res)=>{
        setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setData(res.data.data.data)
    }).catch((err)=>{
      
      if(err.response.status===401)
      {
        
        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
    })
  }
  }
  // Implement logic to fetch data for the selected page from the backend
    // Example API call: fetchDataFromBackend(page);
};

const generatePageNumbers = () => {
  const maxVisiblePages = 7;
  const pageNumbers = [];
  if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
      }
  } else {
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
      let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
      if (endPage - startPage < maxVisiblePages - 1) {
          startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
      }

      if (startPage > 1) {
          pageNumbers.unshift('...');
      }
      if (endPage < totalPages) {
          pageNumbers.push('...');
      }
  }
  return pageNumbers;
};
// Generate pagination numbers based on total pages
const paginationNumbers = generatePageNumbers().map((pageNumber, index) => (
  <li key={index}>
      {pageNumber === '...' ? (
          <span className="px-2">...</span>
      ) : (
          <button
              className={`flex items-center justify-center px-3  h-8 leading-tight ${
                  currentPage === pageNumber ? 'text-white mx-2 font-semibold rounded-md bg-[#0E9F6E]' : 'text-gray-500 '
              }`}
              onClick={() => handlePageChange(pageNumber)}
          >
              {pageNumber}
          </button>
      )}
  </li>
));


const handlePreviousPage = () => {
  if (currentPage > 1) {
      handlePageChange(currentPage - 1);
  }
};

const handleNextPage = () => {
  if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
  }
};


  const openInputFieldsDrawer = (index) => {
  
    apiService.get(`/fields/getStudent-fields/${data[index].code_id}`,'ad').then((res)=>{
      
      
   
      setTextFields(res.data.data)
    }).catch((err)=>{
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
    })
    setShowTextFields(true);
  };

  const closeInputFieldsDrawer = () => {
    setShowTextFields(false);
  };


  const setValue = (e) => {
    const { id, value } = e.target; // Destructure id and value from e.target
  

  
    setFilterData({
      ...filterData,
      [id]: value
    });
  
    let data={...filterData,[id]:value}
   
    data.game_id=null
    if(data.game_name)
    {

      data.game_id=JSON.parse(data.game_name).game_id
    }
  
    data.current_page=1
    apiService.post('/students/search',data,'ad').then((res)=>
    {
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setData(res.data.data.data)
      if(e.target.value==='')
      {
    setShowFilter(false)

      }
      else
      {
    setShowFilter(true)

      }
    }).catch((err)=>
    {
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
    })
  
  }

  






  const deleteRecord = (i) => {

    setData(data.filter((element, index) => index !== i))
  }

  const flattenData = (record) => {
    const flattenedRecord = {
      ...record,
    };

    // record.products.forEach((product, index) => {
    //   flattenedRecord[`product_id_${index}`] = product.product_id;
    //   flattenedRecord[`quantity`] = product.quantity;
    //   flattenedRecord[`price`] = product.price;
    // });

    

    
    record.fields.forEach((field, index) => {
      // flattenedRecord[`field_id_${index}`] = field.field_id;
      
      flattenedRecord[`Label ${index+1}`] = field.label;
      flattenedRecord[`Value ${index+1}`] = field.value;
      flattenedRecord[`Type ${index+1}`] = field.type;
      flattenedRecord[`Status ${index+1}`] = field.status;
    });

    delete flattenedRecord.products;
    delete flattenedRecord.fields;

    return flattenedRecord;
  };


  
  const exportData = () => {

    apiService.get(`/students/export?game_id=${filterData.game_name? JSON.parse(filterData.game_name).game_id:''}&search_query=${filterData.search_query}`,'ad').then((res)=>
    {
  
      const flattenedData = res.data.data.map(flattenData);

      const ws = XLSX.utils.json_to_sheet(flattenedData);

// Create a workbook

const wb = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

XLSX.writeFile(wb, 'students.xlsx');
      // if (data.length > 0) {
      //   const ws = XLSX.utils.json_to_sheet(data);
      //   const wb = XLSX.utils.book_new();
      //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      //   XLSX.writeFile(wb, 'students.xlsx');
      // }
    }).catch((err)=>
    {

      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
  
    })
  }

  const setShowCheckboxDecision = () => {
    setShowCheckbox(prev => !prev)
  }

  const showModal = (i) => {

    setDeleteData([...deleteData, data[i]])
    setShowDeleteModal(prev => !prev)
  }

  const onDeleteCall = () => {

 
    if(headerCheckbox)
    {
      const selectedStudentIds = data.reduce((accumulator, element, index) => {
          accumulator.push(element.student_id);
     
        return accumulator;
      }, []);

      
      apiService.delete(`/students/delete/${selectedStudentIds}`,'ad').then((res)=>
      {


        setData([])
        setHeaderCheckbox(false)
        setShowCheckbox(false)
        setShowDeleteModal(prev => !prev)
        if(data.length===1)
        {
       
          if(currentPage-1>0)
          {
            handlePageChange(currentPage-1)
          }
        }
 
        if(data.length===1)
        {
       
          if(currentPage-1>0)
          {
            handlePageChange(currentPage-1)
          }
        }
 
        Toast(res.data.message,1)
      }).catch((err)=>
      {
        if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
      })

    }
   else if(checkboxRecords.includes(true))
    {
      
      const selectedStudentIds = data.reduce((accumulator, element, index) => {
        if (checkboxRecords[index]) {
          // If checkboxRecords[index] is true, add the student_id to the accumulator array
          accumulator.push(element.student_id);
        }
        return accumulator;
      }, []);

      apiService.delete(`/students/delete/${selectedStudentIds}`,'ad').then((res)=>
      {
        
  
        
        Toast(res,data.message,1)
        setData(data.filter((element, index) => !checkboxRecords[index]));
        setCheckboxRecords(Array(data.length).fill(false))
        setShowCheckbox(false)
        setShowDeleteModal(prev => !prev)
        if(data.length===1)
        {
       
          if(currentPage-1>0)
          {
            handlePageChange(currentPage-1)
          }
        }
 
      }).catch((err)=>
      {
        if(err.response.status===401)
        {
  
          Toast(err.response.data.message,0)
          localStorage.clear()
          WithAuth('ad')
          
        }
        Toast(err.response.data.error,0)
      })

    }

    else
    {

    
      apiService.delete(`/students/delete/${deleteData[0].student_id}`,'ad').then((res)=>
      {
        
        setData(data.filter((element, index) => !deleteData.includes(element)))
      setShowDeleteModal(prev => !prev)
      if(data.length===1)
      {
     
        if(currentPage-1>0)
        {
          handlePageChange(currentPage-1)
        }
      }

      
       
      }).catch((err)=>
      {
        if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
      })
    setDeleteData([])
  }

  }

  const onKeepCall = () => {
    setDeleteData([])

    setShowDeleteModal(prev => !prev)

  }

  const setDeleteRecords=(index)=>
  {
    if(index===-1)
    {
      setCheckboxRecords(Array(data.length).fill(true))
      setHeaderCheckbox(true)
    }
    else
    {

  
      const newCheckboxRecords = [...checkboxRecords]; 
      newCheckboxRecords[index] = !newCheckboxRecords[index]
      
      setHeaderCheckbox(false)
      setCheckboxRecords(newCheckboxRecords)

    }
    
  }


  const resetFilters=async()=>
  {

    setFilterData({
      search_query:'',
      game_name:''
    })

    setShowFilter(false)
    setLoading(true)

    apiService.get(`/students/get-all?orderBy=${'student_id'}&order=${'DESC'}&pageSize=${pageSize}&pageNumber=${1}`,'ad').then((res)=>
    {
     
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setData(res.data.data.data)
      setCurrentPage(1)
      setLoading(false)

    }).catch((err)=>
    {
     
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
    })
  }

  useEffect(() => {
    
  }, [showCheckbox,checkboxRecords])


    
  useEffect(()=>{
    apiService.get(`/students/get-all?orderBy=${'student_id'}&order=${'DESC'}&pageSize=${pageSize}&pageNumber=${1}`,'ad').then((res)=>
    {
     
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setData(res.data.data.data)
      setLoading(false)

    }).catch((err)=>
    {
     
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
    })
  },[])


  return (
   isAuthenticated && <div className='w-screen md:w-[85vw] overflow-x-hidden min-h-screen bg-[#F9FAFB] admin-font'>
      <AdminHeader tabName={'Students'} />

    <div className='flex justify-center mx-2 md:mx-10 '>
        <Headbar>


          <AdminButton btnText={'Export'}
            classess={' w-full md:w-[120px] bg-white text-[#444343] border-2 border-[#CACACA] font-normal hover:border-[#0E9F6E]'}

            onClickCall={exportData}
          />

          <input type='text' onChange={setValue} value={filterData.search_query} id='search_query' placeholder='Seach Students' className=' pl-4 w-full md:w-[480px] h-[40px] bg-[#F4F5F7] text-[#CACACA] placeholder-[#B7B7B7] border-[1px] border-[#CACACA] rounded-lg outline-[#0E9F6E] focus:text-[#0E9F6E]' />
          <SelectFilter id={'game_name'} onChange={setValue} value={filterData.game_name} options={['Game A', 'Game B', 'Game C']} classes={'w-full md:w-1/5'} />


          {
            !showCheckbox ?
              <AdminButton btnText={'Select'}
                classess={'w-20 md:w-[170px]'}
                onClickCall={setShowCheckboxDecision}
              />

              :
              <AdminButton btnText={'Delete'}
                classess={'w-20 md:w-[170px] bg-red-600'}

                onClickCall={showModal}
              />
          }





        </Headbar>

      </div>

      <div className='mt-10 flex mx-5 justify-center '>
        <Table headers={['checkbox', 'Game Name','Class', 'Name', 'Total Guests', 'Available Fund', 'Total Cost', 'Total Happiness Rating', 'Balance Shortfall', 'Adjusted Happiness Rating', 'Items','Text Field']}
          data={data}
          deleteRecord={showModal}
          showCheckbox={showCheckbox}
          setDeleteRecord={setDeleteRecords}
          checkedRecords={checkboxRecords}
          headerCheckbox={headerCheckbox}
          openInputFieldsDrawer={openInputFieldsDrawer}
          loading={loading}
        />
      </div>
      <DeleteModal showDeleteModal={showDeleteModal} onDeleteCall={onDeleteCall} onKeepCall={onKeepCall} />

      <TextFieldsDrawer heading={'Input Text Fields'} subheading={'Add you input and necessary information from here.'} isOpen={showTextFields} onClose={closeInputFieldsDrawer} 
        textFields={textFields}
        isStudent={true}
     
     
        
        />

<nav aria-label="Page navigation example trainer-stats-font">
            <ul className="flex justify-end my-5 mr-8 -space-x-px text-sm text-white items-center">
                    <li className="mr-2 cursor-pointer" onClick={handlePreviousPage}>
                        <img src={LeftArrow} alt="..." />
                    </li>
                    {paginationNumbers}
                    <li className="ml-2 cursor-pointer" onClick={handleNextPage}>
                        <img src={RightArrow} alt="..." />
                    </li>
                </ul>
            </nav>

            {
 
 showFilter  &&
 <div onClick={resetFilters} className='absolute cursor-pointer z-50 top-2 right-5 bg-[#0E9F6E] rounded-md p-2'>
 <img src={FilterIcon}
 className='h-6'
 />
 </div>
 }
      <ToastContainer/>
    </div>
  )
}
