import React from 'react'
import AdminButton from '../../components/Admin/AdminButton'
import { useEffect } from 'react';
export default function ResetPassword({showResetModal,resetModalView}) {

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click is outside the modal
      if (showResetModal && !event.target.closest('.modal')) {
        // Call the function to hide the modal
        resetModalView();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showResetModal,resetModalView]);

  return (
    <div className={`modal absolute left-[20%] md:left-[40%] bg-white w-60 h-32 md:w-[300px] md:h-[170px] flex justify-center items-center admin-font rounded-md shadow-lg  duration-500 
    ${showResetModal?'top-[40%]':'-top-[40%]'}`}>
      <div className='text-center space-y-5'>
        <div>
        <h1 className='text-[14px] md:text-[18px]'>New password sent to</h1>
        <h1 className='text-[#0E9F6E] text-[16px] md:text-lg'>d2d.finlitpass@gmail.com</h1>
        </div>
        <div>
        <AdminButton btnText={'Done'} classess={' w-auto md:w-[200px]'} onClickCall={()=>resetModalView(true)}/>
        </div>
      </div>
    </div>
  )
}
