import React, { useRef, useState } from "react";
import Header from "../../components/trainer/Header";
import { useNavigate } from "react-router-dom";
import apiService from "../../apiService/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import spinner from '../../assets/spinner.svg'
import Legals from "../../components/Legals";

export default function PinCode() {
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const pin = Array(4).fill("");
  const [loading,setLoading]=useState(false)

  document.title = `ScanQuest | Trainer | Login`;

  let navigate = useNavigate();

  const formSubmitted = () => {
    if (pin.some((element) => element === "")) {
      toast.error("Please enter all digits.", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
      });
    } else {
      setLoading(true)
      const data = {
        pin_code: pin.join(""),
        code_for: "trainer",
      };
 
      apiService
        .post("user/validate",data)
        .then((res) => {
          localStorage.setItem('tad',res.data.token)
          localStorage.setItem("tid",btoa(pin.join("")))
          localStorage.setItem("tgid",btoa(res.data.data[0].game_id))
      setLoading(false)

          navigate("/trainer/stats");
        })
        .catch((err) => {

      setLoading(false)

          if(err.response.status===401)
          {
            toast.error(err.response.data.message, {
              position: "top-center",
              autoClose: 2000,
              closeOnClick: true,
            });

            
          }

          pin.fill("");
          inputRefs.forEach((ref, index) => {
           ref.current.value = "";
         });
           inputRefs[0].current.focus();
        });

    }
  };
  // Function to handle input changes
  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (value && value.length === e.target.maxLength) {
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
        pin[index] = value;
      } else if (inputRefs.length - 1 === index) {
        pin[index] = value;
        formSubmitted();
      }
    } else {
      pin[index] = "";
    }
  };

  const handleBackspace = (e, index) => {
    if (e.keyCode === 8 && index > 0 && e.target.value === "") {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <div className="h-screen overflow-hidden">
      <Header />

      <div className="flex justify-center h-3/4 items-center">
        <div className="h-[314px] w-[366px]  rounded-lg border-[#CACACA] border-2">
          <div className="text-center bg-[#F1F5F9]  rounded-lg border-[#CACACA] border-b-2  h-[96px]">
            <h1 className="text-[#404EED] text-[24px] font-semibold pt-3">
              Enter 4 - digit code
            </h1>
            <p className="text-[#787878] text-[14px]">
              To view result of all participants
            </p>
          </div>

        <div className="flex justify-center text-2xl space-x-5  mt-10 mb-10">
            {inputRefs.map((ref, index) => (
              <input
                key={index}
                required
                type="text"
                className="w-14 h-16 bg-[#F0F0F0] focus:border-2 text-[#404EED] focus:border-[#404EED] focus:bg-[#FFFFFF] focus:outline-none rounded-lg text-center"
                maxLength={1}
                ref={ref}
                onInput={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleBackspace(e, index)}
              />
            ))}
          </div>

          <div onClick={formSubmitted} className="flex justify-center">
            <button className="bg-[#404EED] text-white px-10 mt-3 font-semibold text-[16px] py-2 rounded-lg" disabled={loading?true:false}>
              {" "}
              {!loading?'Submit':<img className='animate-spin text-center h-6 mx-auto' src={spinner} alt='Loading'/>}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Legals classes={' text-center my-2 text-[#404EED]'}/>

    </div>
  );
}
