import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import SelectFilter from './SelectFilter';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function HappinessRatingDrawer({ heading, subheading, isOpen, onClose, btnHandler, editData }) {
    const [formData, setFormData] = useState({
        rating_id:'',
        from: '',
        from_select:'Less',
        to: '',
        to_select:'Less',
        rating_deducted: '',
        game_name: ''
    })

    const [val,setVal]=useState(null)



    const onCancelled = () => {

        setFormData({
        rating_id:'',
            from: '',
            from_select:'Less',
            to: '',
            to_select:'Less',
            rating_deducted: '',
            game_name: ''
        })
        setVal('')
        onClose()
    }





    const formSubmitted = (e) => {
        e.preventDefault()


        
  if(formData.from==='')
  {
    toast.error("From field is empty!", {
      position: "top-center",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
 
 
    });
  }

  else if(formData.to==='')
  {
    toast.error("To field is empty!", {
      position: "top-center",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
 
 
    });
  }
  else if(formData.rating_deducted==='')
  {
    toast.error("Rating deducted field is empty!", {
      position: "top-center",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
 
 
    });
  }

  else
  {

  
       
        btnHandler(formData)

        setFormData({
        rating_id:'',
            from: '',
            from_select:'Less',
            to: '',
            to_select:'Less',
            rating_deducted: '',
            game_name: ''
        })

        setVal('')
    }
}

    const setValue = (e) => {
        const { id, value } = e.target; // Destructure id and value from e.target

        setFormData({
            ...formData,
            [id]: value
        });
    }

    useEffect(() => {
    }, [formData])


    useEffect(() => {
        if (heading === 'Update Happiness Rating') {

            setVal(editData.game_name)

            setFormData({
        rating_id:editData.rating_id,
                from: editData.from,
                from_select:editData.from_select,
                to: editData.to,
                to_select:editData.to_select,
                rating_deducted: editData.rating_deducted,
                game_name: JSON.stringify({game_name: editData.game_name, game_id:editData.game_id}),
            })

        }
    }, [editData, heading]);

    return (
        <div
            className={`fixed top-0 right-0 h-screen w-5/6 md:w-[597px] bg-white border-l shadow-lg transform transition-transform duration-300 admin-font ${isOpen ? 'translate-x-0' : 'translate-x-full'
                }`}
        >

            <div className=' flex items-center  h-[102px] bg-[#F4F5F7] border border-[#CACACA]'>
                <div className='ml-5'>

                    <h1 className='text-[24px]'>{heading}</h1>
                    <p className='text-[14px] text-[#787878]'>{subheading}</p>
                </div>

                <button onClick={onClose} className="absolute bg-white text-red-500 h-6 w-6 font-bold shadow rounded-full top-4 right-4 text-gray-500 hover:text-gray-700">
                    x
                </button>
            </div>

            <form onSubmit={formSubmitted}>

                <div className='mt-10 flex flex-col h-[74vh] justify-between'>

                    <div className='grid grid-cols-3   px-5 gap-y-6 md:gap-y-12 '>


                        <h1 className='text-[18px] md:col-span-1 col-span-3  pt-2'>From<sup className=' text-red-500'>*</sup> </h1>
                        <div className='flex col-span-3 md:col-span-2 space-x-2'>
                            <input type='number' onChange={setValue} value={formData.from} id='from' className='pl-2 w-[75%] h-[44px] bg-[#F4F5F7] placeholder-[#B7B7B7] text-[14px] text-[#B7B7B7] border-[1px] border-[#CACACA] rounded-md focus:text-[#0E9F6E] outline-[#0E9F6E]'  />
                            <SelectFilter options={['Less', 'Greater', 'Greater & Equal', 'Less & Equal']} classes={'h-[44px] w-[25%]'} id={'from_select'} value={formData.from_select} onChange={setValue} />
                        </div>

                        <h1 className='text-[18px] md:col-span-1 col-span-3 pt-2'>To<sup className=' text-red-500'>*</sup> </h1>
                        <div className='flex col-span-3  md:col-span-2 space-x-2 md:mb-0 mb-5'>
                            <input type='number' onChange={setValue} value={formData.to} id='to' className='pl-2 w-[75%] h-[44px] bg-[#F4F5F7] placeholder-[#B7B7B7] text-[14px] text-[#B7B7B7] border-[1px] border-[#CACACA] rounded-md focus:text-[#0E9F6E] outline-[#0E9F6E]'  />
                            <SelectFilter options={['Less', 'Greater', 'Greater & Equal', 'Less & Equal']} classes={'h-[44px] w-[25%]'} value={formData.to_select} id={'to_select'} onChange={setValue} />
                        </div>

                        <h1 className='text-[16px] md:text-[18px] pt-2'>Rating Deducted<sup className=' text-red-500'>*</sup> </h1>
                        <input type='number' onChange={setValue} value={formData.rating_deducted} id='rating_deducted' className='pl-2 col-span-2 h-[44px] bg-[#F4F5F7] placeholder-[#B7B7B7] text-[14px] text-[#B7B7B7] border-[1px] border-[#CACACA] rounded-md focus:text-[#0E9F6E] outline-[#0E9F6E]'  />

                        <h1 className='text-[18px]   pt-2'>Game<sup className=' text-red-500'>*</sup> </h1>
                        <div className='col-span-2'>
                            <SelectFilter id={'game_name'} options={['Game 1', 'Game 2', 'Game 3']}
                                classes={'w-full md:w-[372px]'}
                                value={formData.game_name} onChange={setValue}
                                selectVal={val}
                            />
                        </div>






                    </div>

                    <div className='flex justify-evenly'>
                        <button onClick={onCancelled} type='reset' className='w-2/5 md:w-[271px] h-[48px] bg-[#F1F5F9] flex justify-center items-center rounded-md text-red-500 font-semibold'>Cancel</button>
                        <input type='submit' className='w-2/5 text-[11px] md:text-[18px] md:w-[271px] h-[48px] bg-[#0E9F6E] flex justify-center items-center rounded-md text-white font-semibold cursor-pointer' value={heading} />
                    </div>
                </div>


            </form>
       
        </div>
    );
}

export default HappinessRatingDrawer;
