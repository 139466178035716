import React, { useEffect, useState } from 'react'
import apiService from '../../apiService/apiService'

export default function SelectFilter({id,options,value,onChange,classes,selectVal, categoies}) {
  const [gameOptions,setGameOptions]=useState(null)


  
useEffect(()=>{
  if(id==='game_name' && !gameOptions){
    
  apiService.get('/games/get-all','ad').then((res)=>
  {
    setGameOptions(res.data.data)
  }).catch((err)=>
  {
    console.log(err)
  })

  
}


},[])


  return (
    <select id={id} value={value} onChange={onChange} className={`pl-2 w-[250px] bg-[#F4F5F7] h-[40px] text-[#CACACA] placeholder-[#B7B7B7] border-[1px] border-[#CACACA] focus:border-[#0E9F6E] rounded-lg outline-[#0E9F6E] focus:text-[#0E9F6E] ${classes}`}>

      <option defaultValue hidden>
        {selectVal?selectVal
      :
      `Select ${id.split('_')[0]}`
    } 
      </option>
    {
     id==='game_name'? 
     gameOptions &&  gameOptions.map((option,index)=>
     {
       return <option className='text-black' value={JSON.stringify(option)} key={index}>{option.game_name}</option>
     }):
     id ==='category_name'?
     categoies &&  categoies.map((option,index)=>
     {
       return <option className='text-black' value={JSON.stringify(option)} key={index}>{option.category_name}</option>
     })
     :
     options &&  options.map((option,index)=>
        {
          return <option className='text-black' value={option} key={index}>{option}</option>
        })
        
        
     
    }
    
</select>
  )
}
