import React from 'react'
import elipsis from '../../assets/elipsis.png'
export default function StatsTile({text,price,classes,divHeight}) {
  return (
   
    <div className={`game-font w-full   pl-3 mt-2 md:mt-2 ${divHeight?'h-[35px]': ' h-[13vh] md:h-[11vh] lg:h-[12.5vh]  '}`}>
      <div className={`${classes}`}>

    <h1 className=' text-[16px] md:tezt-[20px]  lg:text-lg'>{text}</h1>
    <h1 className='text-[16px] lg:text-lg'>{price}</h1>
      </div>

    <img src={elipsis} alt='' className='h-[3px] w-full -ml-4 mt-1 md:mt-1 lg:mt-1' />
</div>
  )
}
