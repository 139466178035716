import {React, lazy} from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import addMediaIcon from '../../assets/admin/addMedia.png'
import SelectFilter from './SelectFilter';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qr_code from '../../assets/qr_code.png'
import ImageSelector from './ImageSelector';
import apiService from '../../apiService/apiService';

function ProductDrawer({ heading, subheading, isOpen, onClose, btnHandler, editData }) {
  const [selectGameVal,setGameVal]=useState(null)
  const [selectCategoryVal,setCategoryVal]=useState(null)
  const [product, setProduct] = useState({
    qr_code:'',
    image: '',
    name: '',
    game_name: null,
    category_name: null,
    cost: '',
    happiness_rating: '',
    file:''
  })

  const [isEditDataSet,setIsEditData]=useState(false)

  const [isFileSelected,setFileSelected]=useState(false)

  const [previousImage,setPreviousImage]=useState()
  const [categories,setCategories]=useState()



  const onCancelled = () => {

    onClose()
    setIsEditData(false)
    setProduct({
      qr_code:'',
      image: '',
      name: '',
      game_name: null,
      category_name: null,
      cost: '',
      happiness_rating: '',
      file:''
    })

    setCategories('')
    setCategoryVal('')
    setGameVal('')
  }
  

  function handleFileChange(event) {

    const file = event.target.files[0];

    setPreviousImage(product.file)
    if (file) {
      setFileSelected(true)
      const reader = new FileReader();
      reader.onloadend = () => {
   
        setProduct(prevProduct => ({
          ...prevProduct,
          // file: reader.result,
          image:file,
          file:reader.result
        }));
      };
      reader.readAsDataURL(file)
    }
    
  }
  
  function handleDrop(event) {
    
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile) {
      // Read the dropped file as a data URL and store it in the state
      const reader = new FileReader();
      reader.onloadend = () => {
        setProduct(prevProduct => ({
          ...prevProduct,
          // file: reader.result,
          image:reader.result
        }));
      };
      reader.readAsDataURL(droppedFile); // Read the dropped file as a data URL
    }
  }

  function handleDragOver(event) {
    event.preventDefault();


  }

  const setFile=(f,i)=>
  {
  
    setProduct({
      ...product,
      file:f,
      image:i
    })
  }
  const formSubmitted = (e) => {
    e.preventDefault()

    

  if(product.image==='')
  {
    toast.error("No image uploaded!", {
      position: "top-center",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
 
 
    });
  }
  else if(product.name==='')
  {
    toast.error("Name field is empty!", {
      position: "top-center",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
 
 
    });
  }
  else if(product.cost==='')
  {
    toast.error("Cost field is empty!", {
      position: "top-center",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
 
 
    });
  }
  else if(product.happiness_rating==='')
  {
    toast.error("Happiness Rating field is empty!", {
      position: "top-center",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
 
 
    });
  }

  else if(product.category_name==='')
  {
    toast.error("Category is not selected!", {
      position: "top-center",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
 
 
    });
  }
  else if(product.game_name==='')
  {
    toast.error("Game is not selected!", {
      position: "top-center",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
 
 
    });
  }
else if(product.name.length>30)
{
 
  toast.error("Product Name should be under 30 character!", {
    position: "top-center",
    autoClose: 3000, 
    hideProgressBar: false,
    closeOnClick: true,


  });
}

else if(product.qr_code.length>20)
{
 
  toast.error("QR Code should be under 20 character!", {
    position: "top-center",
    autoClose: 3000, 
    hideProgressBar: false,
    closeOnClick: true,


  });
}

  else
  {


  
  
    btnHandler(product)

  

    setProduct({
      qr_code:'',
      image: '',
      name: '',
      game_name: null,
      category_name: null,
      cost: '',
      happiness_rating: '',
      file:''
    })
  
    setCategoryVal('')
    setGameVal('')
    setCategories('')
  }
  
  

  }
  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomString = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters[randomIndex];
    }
  
    return randomString;
  }
  const setValue = (e,qr_code=0) => {
    e.preventDefault()
    
    const { id, value } = e.target; // Destructure id and value from e.target
    
   
    if(qr_code===1)
    {
      
      setProduct({
        ...product,
        ["qr_code"]: generateRandomString(12)
      });
      return
    }
  
    id==='game_name'?
    setProduct({
      ...product,
      [id]: value,
      category_name:''
    })
    :
    setProduct({
      ...product,
      [id]: value
    });
    
    if(id==='game_name' ){
   
      setCategoryVal('')
    
      apiService.get(`/categories/get-game-categories/${JSON.parse(value).game_id}`,'ad').then((res)=>
      {
       
        setCategories(res.data.data)
      }).catch((err)=>
      {
        console.log(err)
      })
    
      
    }
  }

  useEffect(() => {  

  }, [product])


  useEffect(() => {
    if (heading === 'Update Product' ) {

  
      setFileSelected(false)
   
      setGameVal(editData.game_name)
      setCategoryVal(editData.category_name)
      setProduct({
        qr_code:editData.qr_code,
        image:editData.image,
        name: editData.name,
        game_name: JSON.stringify({game_name: editData.game_name, game_id:editData.game_id}),
        category_name: JSON.stringify({category_name: editData.category_name, category_id:editData.category_id}),
        cost: editData.cost,
        happiness_rating: editData.happiness_rating,
        file:editData.image
      })

      if(editData.game_id!==undefined)
      {

        apiService.get(`/categories/get-game-categories/${editData.game_id}`,'ad').then((res)=>
        {
   
        setCategories(res.data.data)
      }).catch((err)=>
      {
        console.log(err)
      })
    }
  

    }
    else
    {
        

    setProduct({
      qr_code:'',
      image: '',
      name: '',
      game_name: null,
      category_name: null,
      cost: '',
      happiness_rating: '',
      file:''
    })
      setGameVal('')
    }
  }, [editData, heading]);

  return (
    <div
      className={`fixed top-0 right-0 h-screen  w-5/6 md:w-[597px] bg-white border-l shadow-lg transform transition-transform duration-300 admin-font ${isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
    >

      <div className=' flex items-center  h-[102px] bg-[#F4F5F7] border border-[#CACACA]'>
        <div className='ml-5'>

          <h1 className='text-[24px]'>{heading}</h1>
          <p className='text-[14px] text-[#787878]'>{subheading}</p>
        </div>

        <button onClick={onCancelled} className="absolute bg-white text-red-500 h-6 w-6 font-bold shadow rounded-full top-4 right-4 text-gray-500 hover:text-gray-700">
          x
        </button>
      </div>

      <form onSubmit={formSubmitted}>

        <div className='mt-10 flex  flex-col h-[74vh] justify-between'>

          <div className='grid grid-cols-3 pb-5 overflow-y-auto   px-5 gap-y-7 '>

          <h1 className='text-[18px] pt-2 flex '>QR Code<sup className='ml-3 cursor-pointer'>    <img src={qr_code} className='h-4' onClick={(e)=>setValue(e,1)}/></sup> 
      
           </h1>
       
          <div className="relative col-span-2">
                          
            <input type='text' onChange={setValue} value={product.qr_code} placeholder='QR Code' id='qr_code' className='pl-2  w-full h-[44px] bg-[#F4F5F7] placeholder-[#B7B7B7] text-[14px] text-[#B7B7B7] border-[1px] border-[#CACACA] rounded-md focus:text-[#0E9F6E] outline-[#0E9F6E] -z-10'  />

                        </div>

            <h1 className='text-[18px] pt-2'>Image<sup className=' text-red-500'>*</sup> </h1>

            <label htmlFor="image" className='col-span-2 h-[123px] flex justify-center items-center bg-white border-[#CACACA] border rounded-md cursor-pointer'
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e)}
          
            >
       
                {
                heading === 'Update Product' ?
                 <ImageSelector setFile={handleFileChange} previousImage={previousImage} initialImage={product.file}/>

//             <div className='grid-cols-2'>
// <img src={product.file} alt='Selected'  className='h-[123px] '/> :
//              <div>
              
//                <div className='text-center flex justify-center items-center'>
//                  <img src={addMediaIcon} alt='...' width='22px' />
//                </div>

//                <div className='text-sm mt-2 text-center'>

//               <p>Drop Your images here or</p>
//                  <p className='text-[#0E9F6E]'>click to browse</p>
//                </div>
//               </div>
//             </div>
              :

            product.file? 
              
              <img src={product.file} alt='Selected'  className='h-[123px] '/> :
             <div>
              
               <div className='text-center flex justify-center items-center'>
                 <img src={addMediaIcon} alt='...' width='22px' />
               </div>

               <div className='text-sm mt-2 text-center'>

              <p>Drop Your images here or</p>
                 <p className='text-[#0E9F6E]'>click to browse</p>
               </div>
              </div>
            }
</label>
            <input
              type='file'
              onChange={handleFileChange}
              accept='image/*'
              id='image'
              name='image'
              className='hidden pl-2 col-span-2 h-[44px] bg-[#F4F5F7] placeholder-[#B7B7B7] text-[14px] text-[#B7B7B7] border-[1px] border-[#CACACA] rounded-md focus:text-[#0E9F6E] outline-[#0E9F6E]'
            /> 




            <h1 className='text-[18px] pt-2'>Name<sup className=' text-red-500'>*</sup> </h1>
            <input type='text' onChange={setValue} value={product.name} placeholder='product title' id='name' className='pl-2 col-span-2 h-[44px] bg-[#F4F5F7] placeholder-[#B7B7B7] text-[14px] text-[#B7B7B7] border-[1px] border-[#CACACA] rounded-md focus:text-[#0E9F6E] outline-[#0E9F6E]'  />

            <h1 className='text-[18px]   pt-2'>Game<sup className=' text-red-500'>*</sup> </h1>
            <div className='col-span-2'>
              <SelectFilter id={'game_name'} options={['Game 1', 'Game 2', 'Game 3']}
              selectVal={heading === 'Update Product'?selectGameVal:''}
                classes={'w-full md:w-[372px]'}
                value={product.game_name? product.game_name:''} onChange={setValue}
              />
            </div>


            <h1 className='text-[18px]   pt-2'>Category<sup className=' text-red-500'>*</sup> </h1>
            <div className='col-span-2'>
              <SelectFilter id={'category_name'} options={['Drink', 'Shopping', 'Food']}
              selectVal={selectCategoryVal}
              categoies={categories}
                classes={'w-full md:w-[372px]'}
                value={product.category_name} onChange={setValue}
              />
            </div>


            <h1 className='text-[18px] pt-2'>Cost<sup className=' text-red-500'>*</sup> </h1>
            <input type='number' onChange={setValue} value={product.cost} id='cost' className='pl-2 col-span-2 h-[44px] bg-[#F4F5F7] placeholder-[#B7B7B7] text-[14px] text-[#B7B7B7] border-[1px] border-[#CACACA] rounded-md focus:text-[#0E9F6E] outline-[#0E9F6E]'  />


            <h1 className='text-[18px] pt-2'>Happiness Rating<sup className=' text-red-500'>*</sup> </h1>
            <input type='number' onChange={setValue} value={product.happiness_rating} id='happiness_rating' className='pl-2 col-span-2 h-[44px] bg-[#F4F5F7] placeholder-[#B7B7B7] text-[14px] text-[#B7B7B7] border-[1px] border-[#CACACA] rounded-md focus:text-[#0E9F6E] outline-[#0E9F6E]'  />

          </div>

          <div className='flex justify-evenly'>
            <button onClick={onCancelled} type='reset' className='w-2/5 md:w-[271px] h-[48px] bg-[#F1F5F9] flex justify-center items-center rounded-md text-red-500 font-semibold'>Cancel</button>
            <input type='submit' className='w-2/5 md:w-[271px] h-[48px] bg-[#0E9F6E] flex justify-center items-center rounded-md text-white font-semibold cursor-pointer' value={heading} />
          </div>
        </div>


      </form>
      
    </div>
  );
}

export default ProductDrawer;
