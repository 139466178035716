import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PinCode from './pages/game/PinCode';
import Objectives from './pages/game/Objectives';
import PartyPlanning from './pages/game/PartyPlanning';
import Cart from './pages/game/Cart';
import TrainerPinCode from './pages/trainer/PinCode';
import Statistics from './pages/trainer/Statistics';
import Login from './pages/admin/Login';
import AdminLayout from './pages/admin/AdminLayout';
import Games from './pages/admin/Games';
import Categories from './pages/admin/Categories';
import Products from './pages/admin/Products';
import HappinessRating from './pages/admin/HappinessRating';
import FourDigitCode from './pages/admin/FourDigitCode';
import Students from './pages/admin/Students';




function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Game Module Routes */}
        <Route path="/" element={<div className='bg-img'><PinCode /></div>} exact />
        <Route path= '/objectives' element={<div className='bg-img'><Objectives /></div>} />
        <Route path='/planning' element={<div className='bg-img'><PartyPlanning /></div>} />
        <Route path='/cart' element={<div className='bg-img'><Cart /></div>} />

        {/* Trainer Module Routes */}
        <Route path='/trainer/' element={<TrainerPinCode/>}/>
  <Route path='/trainer/stats' element={<Statistics/>} />

        {/* Admin Module Routes */}
        <Route path='/admin/*' element={<AdminLayout />}>
          <Route index element={<Login />} />
          <Route path='games' element={<Games />} />
          <Route path='categories' element={<Categories/>}/>
          <Route path='products' element={<Products/>} />
          <Route path='happiness-rating' element={<HappinessRating/>} />
          <Route path='4-digit-code' element={<FourDigitCode/>} />
          <Route path='students' element={<Students/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
