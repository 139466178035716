 const JsonData=[
    {
      "class": "Class A",
      "name": "John Doe",
      "total_guests": 50,
      "available_funds": "$5,000",
      "total_cost": "$4,000",
      "total_happiness_rating": 4.5,
      "balance_shortfall": "$1,000",
      "adjusted_happiness_rating": 4.2
    },
    {
      "class": "Class B",
      "name": "Jane Smith",
      "total_guests": 30,
      "available_funds": "$3,000",
      "total_cost": "$2,500",
      "total_happiness_rating": 4.2,
      "balance_shortfall": "$500",
      "adjusted_happiness_rating": 4.0
    },
    {
      "class": "Class C",
      "name": "Robert Johnson",
      "total_guests": 40,
      "available_funds": "$4,500",
      "total_cost": "$3,800",
      "total_happiness_rating": 4.4,
      "balance_shortfall": "$700",
      "adjusted_happiness_rating": 4.1
    },
    {
      "class": "Class D",
      "name": "Emily Davis",
      "total_guests": 20,
      "available_funds": "$2,200",
      "total_cost": "$1,800",
      "total_happiness_rating": 4.6,
      "balance_shortfall": "$400",
      "adjusted_happiness_rating": 4.3
    },
    {
      "class": "Class E",
      "name": "Michael Johnson",
      "total_guests": 45,
      "available_funds": "$4,800",
      "total_cost": "$4,200",
      "total_happiness_rating": 4.3,
      "balance_shortfall": "$600",
      "adjusted_happiness_rating": 4.0
    },
    {
      "class": "Class F",
      "name": "Sophia Lee",
      "total_guests": 55,
      "available_funds": "$5,500",
      "total_cost": "$5,200",
      "total_happiness_rating": 4.7,
      "balance_shortfall": "$300",
      "adjusted_happiness_rating": 4.5
    },
    {
      "class": "Class G",
      "name": "William Brown",
      "total_guests": 25,
      "available_funds": "$2,800",
      "total_cost": "$2,600",
      "total_happiness_rating": 4.2,
      "balance_shortfall": "$200",
      "adjusted_happiness_rating": 4.0
    },
    {
      "class": "Class H",
      "name": "Olivia Martin",
      "total_guests": 35,
      "available_funds": "$3,500",
      "total_cost": "$3,200",
      "total_happiness_rating": 4.5,
      "balance_shortfall": "$300",
      "adjusted_happiness_rating": 4.3
    },
    // {
    //   "class": "Class I",
    //   "name": "James Wilson",
    //   "total_guests": 60,
    //   "available_funds": "$6,000",
    //   "total_cost": "$5,400",
    //   "total_happiness_rating": 4.8,
    //   "balance_shortfall": "$600",
    //   "adjusted_happiness_rating": 4.6
    // },
    // {
    //   "class": "Class J",
    //   "name": "Sophia Garcia",
    //   "total_guests": 40,
    //   "available_funds": "$4,200",
    //   "total_cost": "$3,600",
    //   "total_happiness_rating": 4.4,
    //   "balance_shortfall": "$600",
    //   "adjusted_happiness_rating": 4.2
    // }
  ]

  export default JsonData
  