import React, { useEffect, useRef } from 'react';
import DeleteIcon from '../../assets/admin/modalDelete.png';
import '../../App.css';
import AdminButton from './AdminButton';

export default function DeleteModal({ from, showDeleteModal, onKeepCall, onDeleteCall, onResetCall }) {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside the modal
        onKeepCall();
      }
    }

    if (showDeleteModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
       
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDeleteModal]);

  return (
    <div
      ref={modalRef}
      className={`admin-font flex justify-center items-center absolute duration-500 left-[10%]  md:left-[35%] w-5/6 md:w-[600px] h-[300px] bg-white rounded-lg shadow-lg ${
        showDeleteModal ? 'top-[32%]' : '-top-[50%] '
      }`}
    >
            <div className='space-y-7'>

                <div className='text-center flex justify-center'>

                    <img src={DeleteIcon} alt='...' width='24px' height='24px' />

                </div>
                <div className='text-center px-6 space-y-2'>
                    <h1 className='text-md md:text-lg'>Are you sure you want to <span className='text-[#F60606]'>{from?'reset':'delete'} </span> it? </h1>
                    <p className='text-xs md:text-sm'>Do you really want to {from?'reset':'delete'} these records? You can't view this in your list
                        anymore if you delete!</p>

                </div>
<div className='flex justify-center space-x-2 md:space-x-5' >
                <AdminButton btnText={'No, Keep it'} classess={'bg-[#F4F5F7] w-24 md:w-2/5 text-[10px] md:text-[12px]   text-black '} onClickCall={onKeepCall}/>
                <AdminButton btnText={`Yes, ${from?'reset':'delete'} it`} classess={' w-24 md:w-2/5 text-[10px] md:text-[12px]   '} onClickCall={from?onResetCall: onDeleteCall}/>
</div>
            </div>
        </div>
    )
}
