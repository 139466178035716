import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import SelectFilter from './SelectFilter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CodeDrawer({ heading, subheading, isOpen, onClose, btnHandler, editData }) {
    const [formData, setFormData] = useState({
        code_id:'',
        code: '',
        game_name: '',
        for: 'Student',
        
    })

    const [val,setVal]=useState(null)

    const [no_of_codes,setCodeNumber]=useState()

    const onCancelled = () => {

        setFormData({
            code_id:'',
            code: '',
            game_name: '',
            for: 'Student'
        })

        setVal('')
        onClose()
    }

    const formSubmitted = (e) => {
        e.preventDefault()
    
        if(no_of_codes && formData.game_name )
        {
            formData.no_of_codes=no_of_codes
           formData.for='Student'
            btnHandler(formData)
            setFormData({
                code_id:'',
                code: '',
                game_name: '',
                for: 'Student',
            })
            setCodeNumber('')
             
        }
     
      else if(formData.code && formData.code.length===4 && formData.game_name)
        {
            btnHandler(formData)
            setFormData({
                code_id:'',
                code: '',
                game_name: '',
                for: 'Student',
            })
            setCodeNumber('')
            setVal('')

        }
       
        else
        {

             if(formData.code!=='' && formData.code>4 || formData.code<4)
             {
                toast.error("Pin Code should contain 4 digits!", {
                    position: "top-center",
                    autoClose: 3000, 
                    hideProgressBar: false,
                    closeOnClick: true,
                    
                    
                   });
             }

             else
             {

                 toast.error("Please fill all the field to continue!", {
                     position: "top-center",
                     autoClose: 3000, 
                     hideProgressBar: false,
                     closeOnClick: true,
                     
                     
                    });
                }
              
     
        
      
        
            }
           
    }
    
    const setValue = (e) => {
        const { id, value } = e.target; // Destructure id and value from e.target

        setFormData({
            ...formData,
            [id]: value
        });
    }

    useEffect(() => {
    }, [formData])


    useEffect(() => {
        if (heading === 'Update 4 digit code') {
            setVal(editData.game_name)
            setFormData({
                code_id:editData.code_id,
                code: editData.code,
                game_name: JSON.stringify({game_name: editData.game_name, game_id:editData.game_id}),
                for: editData.for
            })

        }
    }, [editData, heading]);

    return (
        <div
            className={`fixed top-0 right-0  h-screen w-5/6 md:w-[597px] bg-white border-l shadow-lg transform transition-transform duration-300 admin-font ${isOpen ? 'translate-x-0' : 'translate-x-full'
                }`}
        >

            <div className=' flex items-center  h-[102px] bg-[#F4F5F7] border border-[#CACACA]'>
                <div className='ml-5'>

                    <h1 className='text-[24px]'>{heading}</h1>
                    <p className='text-[14px] text-[#787878]'>{subheading}</p>
                </div>

                <button onClick={onClose} className="absolute bg-white text-red-500 h-6 w-6 font-bold shadow rounded-full top-4 right-4 text-gray-500 hover:text-gray-700">
                    x
                </button>
            </div>

            <form onSubmit={formSubmitted}>

                <div className='mt-10 flex flex-col h-[74vh] justify-between'>

                    <div className={`grid grid-cols-3   px-5  ${heading=== 'Update 4 digit code'?'gap-y-12':'gap-y-6'}`}>


{
    heading!== 'Update 4 digit code' &&
                    <h1 className='text-[20px] col-span-3 -mt-5 font-semibold text-center text-[#0E9F6E]'>Generate Single Code </h1>
}

                        <h1 className='text-[18px] pt-2'>Code<sup className=' text-red-500'>*</sup> </h1>
                        <input type='text' onChange={setValue} value={formData.code} id='code' className='pl-2 col-span-2 h-[44px] bg-[#F4F5F7] placeholder-[#B7B7B7] text-[14px] text-[#B7B7B7] border-[1px] border-[#CACACA] rounded-md focus:text-[#0E9F6E] outline-[#0E9F6E]'  />

                        <h1 className='text-[18px]   pt-2'>Game<sup className=' text-red-500'>*</sup> </h1>
                        <div className='col-span-2'>
                            <SelectFilter id={'game_name'} options={['Game 1', 'Game 2', 'Game 3']}
                                classes={'w-full md:w-[372px]'}
                                value={formData.game_name} onChange={setValue}
                                selectVal={val}
                            />
                        </div>


                        <h1 className='text-[18px]   pt-2'>For<sup className=' text-red-500'>*</sup> </h1>
                        <div className='col-span-2'>
                            <SelectFilter id={'for'} options={['Student', 'Trainer']}
                                classes={'w-full md:w-[372px]'}
                                value={formData.for} onChange={setValue}
                            />
                        </div>

{
heading!== 'Update 4 digit code' &&
<>
<div className='col-span-3 flex justify-center  items-center space-x-4 text-[#787878]'>
    <hr className='w-1/2'></hr>
    <h1>or</h1>
    <hr className='w-1/2'></hr>

</div>

<h1 className='text-[20px] col-span-3 -mt-5 font-semibold text-center text-[#0E9F6E]'>Generate Multiple Codes </h1>
                



                        <h1 className='text-[18px] pt-2'>Number of codes<sup className=' text-red-500'>*</sup> </h1>
                        <input type='text' onChange={(e)=>setCodeNumber(e.target.value)} value={no_of_codes} id='code_number' className='pl-2 col-span-2 h-[44px] bg-[#F4F5F7] placeholder-[#B7B7B7] text-[14px] text-[#B7B7B7] border-[1px] border-[#CACACA] rounded-md focus:text-[#0E9F6E] outline-[#0E9F6E]'  />



                        <h1 className='text-[18px]   pt-2'>Game<sup className=' text-red-500'>*</sup> </h1>
                        <div className='col-span-2'>
                            <SelectFilter id={'game_name'} options={['Game 1', 'Game 2', 'Game 3']}
                                classes={'w-full md:w-[372px]'}
                                value={formData.game_name} onChange={setValue}
                                selectVal={val}
                                />
                        </div>
                                </>
                }
                    </div>

                    <div className='flex justify-evenly'>
                        <button onClick={onCancelled} type='reset' className='w-2/5 md:w-[271px] h-[48px] bg-[#F1F5F9] flex justify-center items-center rounded-md text-red-500 font-semibold'>Cancel</button>
                        <input type='submit' className='w-2/5 md:w-[271px] h-[48px] md:text-[14px] text-[12px] bg-[#0E9F6E] flex justify-center items-center rounded-md text-white font-semibold cursor-pointer' value={heading} />
                    </div>
                </div>


            </form>

        </div>
    );
}

export default CodeDrawer;
