import React, { useEffect, useState } from 'react'
import Headers from '../../components/game/Headers'
import GameButton from '../../components/game/GameButton'
import { useNavigate } from 'react-router-dom'
import GoBack from '../../components/game/GoBack'
import partyPlanning from '../../assets/gameObj.png'
import WithAuth from '../../apiService/withAuth'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiService from '../../apiService/apiService'
import Toast from '../../components/Toast'
import spinner from '../../assets/spinner.svg'
import Legals from '../../components/Legals'

export default function PartyPlanning() {

    const isAuthenticated=WithAuth('cad')
    const navigate = useNavigate()
    const game=localStorage.getItem("game_name")
    
    const [textFields,setTextFields]=useState([])
    const [loading,setLoading]=useState(true)

    const [formData,setFormData]=useState({
        class:'',
        name:'',
        total_guests:'',
        available_fund:''
    })

    const handleInputChange = (index, event) => {
        const updatedTextFields = [...textFields]; // Create a copy of the original array
        if(event.target.id==='Checkbox')
        {
            updatedTextFields[index].value = event.target.checked===true?1:0; // Update the value of the specific field

        }
        else if(event.target.id==='Radio Button')
        {
          
            updatedTextFields[index].value = event.target.checked===true?1:0; // Update the value of the specific field

        }
        else
        {
            updatedTextFields[index].value = event.target.value; // Update the value of the specific field
        }
      

     
        setTextFields(updatedTextFields); // Update the state with the modified array
      };
    const formSubmitted = () => {
   

      const hasEmptyFields = textFields.some((field) => field.status === 0 && field.value === '');

      if (hasEmptyFields) {

          toast.error("Please fill all the fields.", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
      });
      return;
       }

       else
       {
        const data={
            textFields:textFields
        }
        apiService.post('/fields/update-student',data,'cad').then((res)=>
        {

            const fieldsToStore = textFields.map((field) => ({ label: field.label, value: field.value }));           
            localStorage.setItem('studentData',JSON.stringify(fieldsToStore))
            navigate('/cart')
        }).catch((err)=>
        {
            Toast(err.response.data.error,0)
        })
        }
}

const incrementStepper=(index)=>
{
    
    const updatedTextFields = [...textFields];
    
    updatedTextFields[index].value=Number(updatedTextFields[index].value)+1
  
    setTextFields(updatedTextFields)
}

const decrementStepper=(index)=>
{
    const updatedTextFields = [...textFields];

    if(updatedTextFields[index].value>0)
    {
        updatedTextFields[index].value=Number(updatedTextFields[index].value)-1
    }
    setTextFields(updatedTextFields)
}

    useEffect(()=>
    {

        document.title = `ScanQuest | Party Planning`;
       const code_id= atob(localStorage.getItem("id"))
    
  
       apiService.get(`/fields/getStudent-fields/${code_id}`,'cad').then((res)=>
       {
       
           setTextFields(res.data.data)
           setLoading(false)
       }).catch((err)=>
       {
        if(err.response.status===401)
        {
            localStorage.clear()
            WithAuth('cad')
        Toast(err.response.data.message,0)

        }    
      
        Toast('Please refresh the page and try again',0)
       })

    },[])
    return (
     isAuthenticated &&   <>
         <GoBack text='HOME' classes={'absolute'}/>
            <div className='flex items-center h-screen  justify-center align-center'>

                <div className='bg-black/50 rounded-[20px] md:mx-0 mx-4 mt-16 w-3/4 md:w-[880px] md:pb-8 pb-3   game-border'>
                    <Headers text={game?game:''} classes={'text-[22px] md:text-5xl'} imgName={partyPlanning} />
                    <div className='game-font text-white mt-4 md:mt-16 text-center flex justify-center'>

{
loading?
<img src={spinner} className='h-8 animate-spin'/>
:

                        <div>
                            <h1 className='text-[20px] md:text-4xl mb-4' >Enter your credentials</h1>
                            <div className=' md:space-y-10 space-y-5 mt-5 md:mt-10 mb-8 md:mb-10'>

                               

                                <div className='block md:grid  md:grid-cols-2 justify-between md:gap-x-10 gap-y-5 md:gap-y-10 overflow-y-auto h-[32vh] w-full  md:space-y-0 space-y-5 scrollbar scroll-smooth pr-2 md:pr-5 py-2 overflow-x-hidden '>
                                 
                                    
                                    {
                                      textFields &&  textFields.map((field,index)=>
                                        {
                                           return  (!field.hidden &&<div key={index}>

                                            <div className='w-3/4 m-auto'>
                                                <Headers text={field.label} classes={'text-[14px] md:text-[16px] md:text-lg tracking-widest font-semibold '} headerCLasses={'h-[34px] rounded-lg'} />
                                            </div>
                                            {
                                                field.type==='Text Field'?
                                                <input type='text' disabled={field.status===1?true:false}  id={field.label} value={field.value} onChange={(e)=>handleInputChange(index,e)} 
                                                className='-mt-2  h-[44px] w-[200px] md:w-[281px] bg-[#312323] focus:border-2 focus:border-[#EF9910] rounded-lg focus:outline-none  px-2 ' />
                                                :field.type==='Checkbox'?
                                                 <input type='checkbox' id={field.type} value={field.value} checked={field.value===1?true:false} onChange={(e)=>handleInputChange(index,e)} 
                                                className='  h-[24px] w-[100px] md:w-[80px] bg-[#312323] focus:border-2 focus:border-[#EF9910]  rounded-lg focus:outline-none  px-2 ' />
                                        
                                                :
                                                field.type==='Radio Button'?
                                                <input type='radio' id={field.type} value={field.value} checked={field.value===1?true:false} onChange={(e)=>handleInputChange(index,e)} 
                                                className='  h-[24px] w-[100px] md:w-[80px] bg-[#312323] focus:border-2 focus:border-[#EF9910]  rounded-lg focus:outline-none  px-2 ' />
                                        
                                                :field.type==='Input with stepper'?
                                                <div class="relative flex justify-center items-center -mt-1">
        <button type="button" id="decrement-button" data-input-counter-decrement="quantity-input" class="bg-[#312323]  hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none" onClick={()=>decrementStepper(index)} >
            <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
            </svg>
        </button>
        <input type="text"  id={field.type} value={field.value?field.value:0} disabled data-input-counter data-input-counter-min="1" data-input-counter-max="50" aria-describedby="helper-text-explanation" className=" border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5  bg-[#312323]  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
        <button type="button" id="increment-button" data-input-counter-increment="quantity-input" class=" bg-[#312323]  dark:hover:bg-gray-600  border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none" onClick={()=>incrementStepper(index)}>
            <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"/>
            </svg>
        </button>
    </div>
                                                   :
                                                   field.type==='Date Time Picker'?
                                                   <input type='date' disabled={field.status===1?true:false}  id={field.label} value={field.value} onChange={(e)=>handleInputChange(index,e)} 
                                                   className='-mt-2  h-[44px] w-[200px] md:w-[281px] bg-[#312323] focus:border-2 focus:border-[#EF9910] rounded-lg focus:outline-none  px-2 ' />
                                                   :
                                                   ''
                                            }
    
                                        </div>
                                           ) 
                                    })
                                    }
                                   
                                </div>

                            </div>

                            <GameButton text='Submit' callFunction={formSubmitted} />
                        </div>
}
                    </div>
                </div>


            </div>
            <div className="fixed left-0 right-0 -bottom-1 flex justify-center">


<Legals isGame={true} classes={'text-center '}/>
      </div>

            <ToastContainer />

        </>
    )
}
