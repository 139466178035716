import React from "react";
import Headbar from "../../components/Admin/Headbar";
import SelectFilter from "../../components/Admin/SelectFilter";
import AdminButton from "../../components/Admin/AdminButton";
import AdminHeader from "../../components/Admin/AdminHeader";
import { useState } from "react";
import Table from "../../components/Admin/Table";
import HappinessRatingDrawer from "../../components/Admin/HappinessRatingDrawer";
import CodeDrawer from "../../components/Admin/CodeDrawer";
import DeleteModal from "../../components/Admin/DeleteModal";
import WithAuth from "../../apiService/withAuth";
import apiService from "../../apiService/apiService";
import Toast from "../../components/Toast";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import LeftArrow from "../../assets/pagination-left-arrow.png";
import RightArrow from "../../assets/pagination_right_arrow.png";
import * as XLSX from "xlsx";
import FilterIcon from '../../assets/filterIcon.png'


export default function FourDigitCode() {
  const isAuthenticated = WithAuth("ad");

  const [data, setData] = useState([]);
  const [editData, setEditData] = useState({
    code: "",
    game_name: "",
    for: "",
  });
  const [deleteData, setDeleteData] = useState([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showFilter,setShowFilter]=useState(false)


  const [filterData, setFilterData] = useState({
    game_name: "",
    code_for: "",
    search_query: "",
  });

  document.title = `ScanQuest | Admin | Pin Codes`;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [pageSize] = useState(10);

const [loading,setLoading]=useState(true)

  // Calculate the total number of pages
  const [totalPages, setTotalPages] = useState();

  // Pagination logic to handle page changes
  const handlePageChange = (page) => {
    if (currentPage !== page) {
      setCurrentPage(page);

      if (
        filterData.search_query ||
        filterData.game_name ||
        filterData.code_for
      ) {
        filterData.game_id = null;

        if (filterData.game_name) {
          filterData.game_id = JSON.parse(filterData.game_name).game_id;
        }
        filterData.current_page = page;
        apiService
          .post("/pin-codes/search", filterData, "ad")
          .then((res) => {
            setTotalRecords(res.data.total_records);
            setTotalPages(Math.ceil(res.data.data.total_records / pageSize));
            setData(res.data.data.data);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              Toast(err.response.data.message, 0);
              localStorage.clear();
              WithAuth("ad");
            }
            Toast(err.response.data.error, 0);
          });
      } else {
        apiService
          .get(
            `/pin-codes/get-all?pageSize=${pageSize}&pageNumber=${page}`,
            "ad"
          )
          .then((res) => {
            setTotalRecords(res.data.total_records);
            setTotalPages(Math.ceil(res.data.data.total_records / pageSize));
            setData(res.data.data.data);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              Toast(err.response.data.message, 0);
              localStorage.clear();
              WithAuth("ad");
            }
            Toast(err.response.data.error, 0);
          });
      }
    }
    // Implement logic to fetch data for the selected page from the backend
    // Example API call: fetchDataFromBackend(page);
  };

  const generatePageNumbers = () => {
    const maxVisiblePages = 7;
    const pageNumbers = [];
    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startPage = Math.max(
        1,
        currentPage - Math.floor(maxVisiblePages / 2)
      );
      let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
      if (endPage - startPage < maxVisiblePages - 1) {
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (startPage > 1) {
        pageNumbers.unshift("...");
      }
      if (endPage < totalPages) {
        pageNumbers.push("...");
      }
    }
    return pageNumbers;
  };
  // Generate pagination numbers based on total pages
  const paginationNumbers = generatePageNumbers().map((pageNumber, index) => (
    <li key={index}>
      {pageNumber === "..." ? (
        <span className="px-2">...</span>
      ) : (
        <button
          className={`flex items-center justify-center px-3  h-8 leading-tight ${
            currentPage === pageNumber
              ? "text-white mx-2 font-semibold rounded-md bg-[#0E9F6E]"
              : "text-gray-500 "
          }`}
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </button>
      )}
    </li>
  ));

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const setValue = (e) => {
    const { id, value } = e.target; // Destructure id and value from e.target

    setFilterData({
      ...filterData,
      [id]: value,
    });

    let data = { ...filterData, [id]: value };
   
    data.game_id = null;

    if (data.game_name) {
      data.game_id = JSON.parse(data.game_name).game_id;
    }

    data.current_page = 1;
    apiService
      .post("/pin-codes/search", data, "ad")
      .then((res) => {
        setCurrentPage(1);
        setTotalRecords(res.data.data.total_records);
        setTotalPages(Math.ceil(res.data.data.total_records / pageSize));
        setData(res.data.data.data);

        if(e.target.value==='')
        {
      setShowFilter(false)
  
        }
        else
        {
      setShowFilter(true)
  
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Toast(err.response.data.message, 0);
          localStorage.clear();
          WithAuth("ad");
        }
        Toast(err.response.data.error, 0);
      });
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const closeEditDrawer = () => {
    setEditDrawerOpen(false);
  };

  const addFourDigitCode = (record) => {

    const game = JSON.parse(record.game_name);
    record.game_name = game.game_name;
    record.game_id = game.game_id;
    record.code_for = record.for;
    record.pin_code = record.code;

    const pinCodeData = {
      pin_code: record,
      current_page: currentPage,
    };
    apiService
      .post("/pin-codes/insert", pinCodeData, "ad")
      .then((res) => {
        setTotalRecords(res.data.total_records);
        setTotalPages(Math.ceil(res.data.data.total_records / pageSize));
        setData(res.data.data.data);

        Toast(res.data.message, 1);

        setIsDrawerOpen(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          Toast(err.response.data.message, 0);
          localStorage.clear();
          WithAuth("ad");
        }
        Toast(err.response.data.error, 0);
      });
  };

  const editRecord = (recordData) => {
    setEditData(recordData);
    setEditDrawerOpen(true);
  };

  const updateRecord = (updatedData) => {


    const records = [...data];

    const game = JSON.parse(updatedData.game_name);
    updatedData.game_id = game.game_id;
    updatedData.code_for = updatedData.for;
    updatedData.pin_code = updatedData.code;
    apiService
      .put(`/pin-codes/update/${updatedData.code_id}`, updatedData, "ad")
      .then((res) => {
        records[editData.index].code = updatedData.code;
        records[editData.index].game_name = game.game_name;
        records[editData.index].for = updatedData.for;

        setEditData(records);
        setEditDrawerOpen(false);
        Toast(res.data.message, 1);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Toast(err.response.data.message, 0);
          localStorage.clear();
          WithAuth("ad");
        }
        Toast(err.response.data.error, 0);
      });
  };

  const deleteRecord = (i) => {
    setData(data.filter((element, index) => index !== i));
  };

  const showModal = (i) => {
    setDeleteData([...deleteData, data[i]]);
    setShowDeleteModal((prev) => !prev);
  };

  const onDeleteCall = () => {
    apiService
      .delete(`/pin-codes/delete/${deleteData[0].code_id}`, "ad")
      .then((res) => {
        setData(data.filter((element, index) => !deleteData.includes(element)));
        setShowDeleteModal((prev) => !prev);

        if (data.length === 1) {
          if (currentPage - 1 > 0) {
            handlePageChange(currentPage - 1);
          }
        }

        Toast(res.data.message, 1);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Toast(err.response.data.message, 0);
          localStorage.clear();
          WithAuth("ad");
        }
        Toast(err.response.data.error, 0);
      });

    setDeleteData([]);
  };

  const onKeepCall = () => {
    setDeleteData([]);

    setShowDeleteModal((prev) => !prev);
  };

  const exportData = () => {
    // const ws = XLSX.utils.json_to_sheet(flattenedData);

    // const wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // XLSX.writeFile(wb, 'students.xlsx');

    if (
      filterData.search_query ||
      filterData.game_name ||
      filterData.code_for
    ) {
      filterData.game_id = null;

      if (filterData.game_name) {
        filterData.game_id = JSON.parse(filterData.game_name).game_id;
      }
      filterData.current_page=null
      apiService
        .post("/pin-codes/search", filterData, "ad")
        .then((res) => {
     

          const exportData = res.data.data.data.map((item) => ({
            "Game Name": item.game_name,
            "Game Objectives": item.game_objectives,
            "Code": item.code,
            "For": item.for, // 'for' is a reserved word, so access it using bracket notation
          }));
          const ws = XLSX.utils.json_to_sheet(exportData);

          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          XLSX.writeFile(wb, "pincodes.xlsx");
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Toast(err.response.data.message, 0);
            localStorage.clear();
            WithAuth("ad");
          }
          Toast(err.response.data.error, 0);
        });
    } else {
      apiService
        .get(`/pin-codes/get-all`, "ad")
        .then((res) => {
      

          const exportData = res.data.data.data.map((item) => ({
            "Game Name": item.game_name,
            "Game Objectives": item.game_objectives,
            "Code": item.code,
            "For": item.for, // 'for' is a reserved word, so access it using bracket notation
          }));
          const ws = XLSX.utils.json_to_sheet(exportData);

          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          XLSX.writeFile(wb, "pincodes.xlsx");
          
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Toast(err.response.data.message, 0);
            localStorage.clear();
            WithAuth("ad");
          }
          Toast(err.response.data.error, 0);
        });
    }
  };

  const resetFilters=async()=>
  {

    setFilterData({
      game_name: "",
      code_for: "",
      search_query: "",
    })

    setShowFilter(false)
    setLoading(true)

    apiService
    .get(`/pin-codes/get-all?pageSize=${pageSize}&pageNumber=${1}`, "ad")
    .then((res) => {
      setTotalRecords(res.data.total_records);
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize));
      setData(res.data.data.data);
      setCurrentPage(1)
      setLoading(false)
    })
    .catch((err) => {
      if (err.response.status === 401) {
        Toast(err.response.data.message, 0);
        localStorage.clear();
        WithAuth("ad");
      }
      Toast(err.response.data.error, 0);
    });
  }

  useEffect(() => {
    apiService
      .get(`/pin-codes/get-all?pageSize=${pageSize}&pageNumber=${1}`, "ad")
      .then((res) => {
        setTotalRecords(res.data.total_records);
        setTotalPages(Math.ceil(res.data.data.total_records / pageSize));
        setData(res.data.data.data);
        setLoading(false)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Toast(err.response.data.message, 0);
          localStorage.clear();
          WithAuth("ad");
        }
        Toast(err.response.data.error, 0);
      });
  }, []);

  return (
    isAuthenticated && (
      <div className="w-screen md:w-[85vw] min-h-screen overflow-y-auto bg-[#F9FAFB] admin-font">
        <AdminHeader tabName={"4-digit-code"} />

        <div className="flex justify-center mx-2 md:mx-10 ">
          <Headbar>
            <AdminButton
              btnText={"Export"}
              classess={
                " w-full md:w-44 bg-white text-[#444343] border-2 border-[#CACACA] font-normal hover:border-[#0E9F6E]"
              }
              onClickCall={exportData}
            />
            <input
              type="text"
              id="search_query"
              onChange={setValue}
              value={filterData.search_query}
              placeholder="Seach 4 digit code"
              className=" pl-4 w-full md:w-1/3 h-[40px] bg-[#F4F5F7] text-[#CACACA] placeholder-[#B7B7B7] border-[1px] border-[#CACACA] rounded-lg outline-[#0E9F6E] focus:text-[#0E9F6E]"
            />
            <SelectFilter
              id={"code_for"}
              onChange={setValue}
              value={filterData.code_for}
              options={["Student", "Trainer"]}
              classes={"w-full md:w-48"}
            />
            <SelectFilter
              id={"game_name"}
              value={filterData.game_name}
              onChange={setValue}
              options={["Game A", "Game B", "Game C"]}
              classes={"w-full md:w-48"}
            />
            <AdminButton
              btnText={"+ Add 4 digit code"}
              classess={"w-[180px]"}
              onClickCall={openDrawer}
            />

            <CodeDrawer
              heading={"Add 4 digit code"}
              subheading={
                "Add your 4 digit code and necessary information from here."
              }
              isOpen={isDrawerOpen}
              onClose={closeDrawer}
              btnHandler={addFourDigitCode}
              editData={editData}
            />
            <CodeDrawer
              heading={"Update 4 digit code"}
              subheading={
                "Update your 4 digit code and necessary information from here."
              }
              isOpen={editDrawerOpen}
              onClose={closeEditDrawer}
              btnHandler={updateRecord}
              editData={editData}
            />
          </Headbar>
        </div>

        <div className="mt-10 flex mx-2 md:mx-5 justify-center ">
          <Table
            headers={["Code", "Game Name", "For"]}
            data={data}
            editRecord={editRecord}
            deleteRecord={showModal}
            loading={loading}
          />
        </div>

        <nav aria-label="Page navigation example trainer-stats-font">
          <ul className="flex justify-end my-5 mr-8 -space-x-px text-sm text-white items-center">
            <li className="mr-2 cursor-pointer" onClick={handlePreviousPage}>
              <img src={LeftArrow} alt="..." />
            </li>
            {paginationNumbers}
            <li className="ml-2 cursor-pointer" onClick={handleNextPage}>
              <img src={RightArrow} alt="..." />
            </li>
          </ul>
        </nav>

        <DeleteModal
          showDeleteModal={showDeleteModal}
          onDeleteCall={onDeleteCall}
          onKeepCall={onKeepCall}
        />

{
 
 showFilter  &&
 <div onClick={resetFilters} className='absolute cursor-pointer z-50 top-2 right-5 bg-[#0E9F6E] rounded-md p-2'>
 <img src={FilterIcon}
 className='h-6'
 />
 </div>
 }
        <ToastContainer />
      </div>
    )
  );
}
