import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_PUBLIC_URL


axios.defaults.baseURL=API_BASE_URL
axios.defaults.withCredentials = true;

const apiService = {
  get: async (endpoint,token='',data='') => {

    let decToken
    if(token)
    {
        decToken= atob(localStorage.getItem(token))
     
    }
      const response = await axios({
        method:'get',
        url:endpoint,
        data:data,
        headers:{
           'Authorization': `Bearer ${decToken}`}
      });
      return response;
 
  },

  post: async (endpoint, data,token='') => {

    let decToken
    if(token)
    {
        decToken= atob(localStorage.getItem(token))
     
    }

      const response = await axios({
        method:'post',
        url:endpoint,
        data:data,
        headers:{
           'Authorization': `Bearer ${decToken}`}
      });
      
      return response;
 
  },

  put: async (endpoint, data,token='') => {

    let decToken
    if(token)
    {
        decToken= atob(localStorage.getItem(token))
     
    }
      const response = await axios({
        method:'put',
        url:endpoint,
        data:data,
        headers:{
           'Authorization': `Bearer ${decToken}`}
      });
      return response;
  },

  delete: async (endpoint,token='') => {
   
    let decToken
    if(token)
    {
        decToken= atob(localStorage.getItem(token))
     
    }
      const response = await axios({
        method:'delete',
        url:endpoint,
        headers:{
           'Authorization': `Bearer ${decToken}`}
      });
      return response
  },
};

export default apiService;
