import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Toast(message,type)
{
    if(type===1)
    {
       return toast.success(message, {
            position: "top-center",
            autoClose: 2000,
            closeOnClick: true,
          });
    }
    else
    {
      return  toast.error(message, {
            position: "top-center",
            autoClose: 2000,
            closeOnClick: true,
          });
    }
}

export default Toast