import React from 'react'

export default function Headers({text,classes,headerCLasses,imgName}) {
  return (
//     <div className={`game-font w-full relative text-white flex justify-center items-center game-headers-bg  my-1 ${headerCLasses}`}>
//     <div className='absolute  '>
//       {/* <img src={imgName} /> */}
//     </div>
//     <h1 className={`text-6xl py-3 font-semibold z-10  ${classes}`} >{text}</h1>
// </div>
<div className={`game-font relative text-white flex justify-center items-center game-headers-bg mx-1 rounded-[16px] my-1 ${headerCLasses}`}>
<h1 className={`text-6xl py-2 font-semibold z-10 text-shad  ${classes}`} >{text}</h1>
<div className='absolute -top-1 left-1 opacity-75 h-4  game-headers-bg2 w-full '></div>
<div className='absolute  bottom-0 left-20 opacity-75 h-3 game-headers-bg2 w-3/4 '></div>
<div className='absolute  -left-5 opacity-[10%] h-16 game-headers-bg2 w-32 '></div>
<div className='absolute  -right-5 opacity-[10%] h-16  game-headers-bg2 w-32 '></div>
<div className='absolute bottom-1 shadow-md  rounded-br-[16px] rounded-bl-[16px] w-full opacity-25   h-1/2 bg-[#572b03]'>

</div>
</div>
  )
}
