import React, { useRef } from 'react';
import GameButton from './GameButton';
import elipsis from '../../assets/elipsis.png'
import { Link } from 'react-router-dom';
import { useOutsideClick } from '../../pages/admin/UseOutsideClick';


export default function ResetModal({isOpen, onClose, onYesClick}) {

  const modalRef=useRef(null)
  useOutsideClick(modalRef, () => {
    if (isOpen) {

    // Close the dropdown when a click occurs outside the modal
   onClose()
    }
  });
  return (
    <div ref={modalRef} className={`absolute left-[10vw] right-[5vw] sm:right-auto sm:left-1/3  md:left-[47vw] inset-0 flex items-center  z-50 shadow-lg game-font justify-center ${isOpen ? 'visible' : 'hidden'}`}>
      <div className="absolute inset-0  "></div>
      <div className="z-100  p-4  h-[205px] w-[373px] pt-10 box rounded-lg shadow-lg text-center">
        <p className="text-2xl font-semibold mb-6 text-2xl md:text-4xl">Do you really wish to reset the cart?</p>
     

     <GameButton callFunction={onYesClick} text={'Yes'} classes={' text-lg cursor-pointer mr-5 '}/>


<GameButton callFunction={onClose} text={'No'} classes={' text-lg cursor-pointer '}/>


<div className='relative'>
     <img src={elipsis} alt='...' className='h-[4px] w-full mt-5'/>
     <div className='absolute pin-box h-12 -top-5 w-full'></div>
</div>
     
      
      </div>
    </div>
  );
}
