import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Login from './Login'
import AdminSidebar from '../../components/Admin/AdminSidebar'
import AdminMobileSidebar from '../../components/Admin/AdminMobileSidebar';

export default function AdminLayout() {
  const location = useLocation();

 
  // Check if the current route is '/admin'
  const isLoginPage = location.pathname === '/admin';
  
  return (
    <>
    {
        isLoginPage ?<Login/>
        : 
 <div className='flex h-screen w-screen'>

   <AdminSidebar/>
   <AdminMobileSidebar/>
<Outlet/>
</div>
}
    </>
  )
}
