import React, { useEffect, useRef, useState } from 'react'
import StatsTile from './StatsTile'
import GameButton from './GameButton'
import User from '../../assets/user.png'
import Modal from './Modal';
import RightArrow from '../../assets/rightArrow.png'
import LeftArrow from '../../assets/leftArrow.png'
import Toast from '../Toast';
import apiService from '../../apiService/apiService';
import WithAuth from '../../apiService/withAuth';
import { useNavigate } from 'react-router-dom';


export default function Sidebar({studentData,products,rules}) {
    
 
  const navigate=useNavigate()

  
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);


    const thankYouModal=useRef(null)
    const thankYouModalMobile=useRef(null)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);


    const [categories,setCategories]=useState()

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
     
    };
  
    const handleButtonClick = () => {
      if(products.length>0)
      {
 

       
   
      const categoryCounts = products.reduce((counts, product) => {
        const categoryId = product.category_id;
        counts[categoryId] = (counts[categoryId] || 0) + 1;
        return counts;
      }, {});

  
      let flag=false
      for (const category of categories) {
        const categoryId = category.category_id;
        const count = categoryCounts[categoryId] || 0;
        const minimumProducts = category.minimum_products;
      
    
        if (count < minimumProducts) {
          flag=true
          // Replace this with your toast notification logic
          
          Toast(`You need to add minimum ${minimumProducts} products for ${category.category_name} category`,0)
          break;
        
          // Here, you can trigger your toast notification
          // showToast(`Category ${category.name} has insufficient products.`);
        } else {
          console.log(`Category ${category.category_name} has enough products.`);
          // Proceed with your logic here
        }
      }

        if(flag===true)
        {
          return;
        }
  
 

        studentData.pin_code=atob(localStorage.getItem('cid'))
        studentData.products=JSON.stringify(products)
        studentData.code_id=atob(localStorage.getItem('id'))
        studentData.game_id=atob(localStorage.getItem('gid'))
     
        apiService.post('/students/insert',studentData,'cad').then((res)=>
        {
          
          localStorage.removeItem('products')
          localStorage.removeItem('studentsMaintained')
          setIsModalOpen(true); // Open the modal when the button is clicked
      
        }).catch((err)=>
        {
          if(err.response.status===401)
          {
            WithAuth('cad')
          }
          Toast('Sorry. We can not process your request at this moment.',0)
        })
      }
      else{
        Toast('Please add products to proceed')
      }
    };


    const handleMobileButtonClick = () => {
      if(products.length>0)
      {
 

       
   
      const categoryCounts = products.reduce((counts, product) => {
        const categoryId = product.category_id;
        counts[categoryId] = (counts[categoryId] || 0) + 1;
        return counts;
      }, {});

  
      let flag=false
      for (const category of categories) {
        const categoryId = category.category_id;
        const count = categoryCounts[categoryId] || 0;
        const minimumProducts = category.minimum_products;
      
    
        if (count < minimumProducts) {
          flag=true
          // Replace this with your toast notification logic
          
          Toast(`You need to add minimum ${minimumProducts} products for ${category.category_name} category`,0)
          break;
        
          // Here, you can trigger your toast notification
          // showToast(`Category ${category.name} has insufficient products.`);
        } else {
          console.log(`Category ${category.category_name} has enough products.`);
          // Proceed with your logic here
        }
      }

        if(flag===true)
        {
          return;
        }
  
 

        studentData.pin_code=atob(localStorage.getItem('cid'))
        studentData.products=JSON.stringify(products)
        studentData.code_id=atob(localStorage.getItem('id'))
        studentData.game_id=atob(localStorage.getItem('gid'))
     
        apiService.post('/students/insert',studentData,'cad').then((res)=>
        {
         
          setIsMobileModalOpen(true); // Open the modal when the button is clicked
        }).catch((err)=>
        {
          if(err.response.status===401)
          {
            WithAuth('cad')
          }
          Toast('Sorry. We can not process your request at this moment.',0)
        })
      }
      else{
        Toast('Please add products to proceed')
      }
    };

    const handleCloseModal = () => {
 

        setIsModalOpen(false); // Close the modal when the close button is clicked
      
        navigate('/planning', { replace: true });
      };

      const handleMobileCloseModal = () => {
 
        
        setIsMobileModalOpen(false); // Close the modal when the close button is clicked
      
        navigate('/planning', { replace: true });
      };

useEffect(()=>
{
 
  apiService.get(`/categories/get-product-categories/${atob(localStorage.getItem('gid'))}`,'cad').then((res)=>
  {
 
    setCategories(res.data.data)
   
   

   
  }).catch((err)=>
  {
    if(err.response.status===401)
    {

      Toast(err.response.data.message,0)
      localStorage.clear()
      WithAuth('cad')
      
    }
    Toast(err.response.data.error,0)

  })



},[])
      
const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside the modal
       setIsSidebarOpen(false)
      }
    }

    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
       
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);


  
  useEffect(() => {
    function handleClickOutside(event) {
      if (thankYouModal.current && !thankYouModal.current.contains(event.target)) {
        // Clicked outside the modal
       handleCloseModal()
      }
    }

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
       
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);


  
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (thankYouModalMobile.current && !thankYouModalMobile.current.contains(event.target)) {
        // Clicked outside the modal
       handleCloseModal()
      }
    }

    if (isMobileModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
       
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileModalOpen]);


  return (
  <>
    <div className='w-[328px] h-[82vh] pb-2 rounded-lg mx-2 my-2 box px-3 md:block hidden '>
    <div className='w-full h-[70px] rounded-lg my-2 bg-[#FFCD1433] flex items-center px-2 space-x-3 game-border   '>
        <div className='w-[58px] h-[58px] bg-[#FFCD1433] shadow-md flex items-center justify-center rounded-lg'>
            <img src={User} alt='...'/>
        </div>

        <div className='game-font'>
            <h1 className='text-2xl'>{studentData.student_name}</h1>
            <h4>{studentData.total_guests} {studentData.student_class}</h4>
        </div>
    </div>


<div className='flex-1 h-inherit pt-5  flex flex-col  justify-between items-center'>


  <StatsTile text='Available Balance' price={'$'+studentData.available_fund} />
  <StatsTile text='Total Cost' price={'$'+studentData.total_cost}/>
  <StatsTile text='Amount Left' price={'$'+studentData.amount_left}/>
  {/* <StatsTile text='Total Happiness Rating' price={studentData.total_happiness_rating}/> */}
  {/* <StatsTile text='Balance/Shortfall Amount' price={studentData.balance_shortfall}/> */}
  <StatsTile text='Total Happiness Rating' price={studentData.total_happiness_rating}/>



  <GameButton text={'Checkout'} classes={'w-full text-center px-2  mt-2'}  callFunction={handleButtonClick}/>
</div>


<div ref={thankYouModal}  className={`absolute  left-[0vw] sm:left-[12vw]   md:left-[43vw] top-0 md:top-[10vh] flex items-center   shadow-lg game-font justify-center  ${isModalOpen ? 'block' : 'hidden'}`}>
   
      <Modal  isOpen={isModalOpen} onClose={handleCloseModal}  rules={rules}  adjusted_happiness_rating={studentData.adjusted_happiness_rating}  total_happiness_rating={studentData.total_happiness_rating} />
   </div>
</div>

<div ref={modalRef} className={`  flex  md:hidden  absolute block   `}>
      <div
        className={` h-[82vh] w-[328px] rounded-lg mx-2 my-2 box  sidebar z-20 transform transition-transform duration-300 px-3 ${
          isSidebarOpen ? '-translate-x-1' : '-translate-x-[95%]  '
        }`}
      >
        {/* Toggle Button */}
    
        <div className='w-full h-[54px] rounded-lg my-2 bg-[#FFCD1433] flex items-center px-2 space-x-3 game-border '>
        <div className='w-[40px] h-[40px] bg-[#FFCD1433] shadow-md flex items-center justify-center rounded-lg '>
            <img src={User} alt='...'/>
        </div>

        <div className='game-font'>
        <h1 className='text-2xl'>{studentData.student_name}</h1>
            <h4>{studentData.total_guests} {studentData.student_class}</h4>
        </div>
    </div>

    <div className='flex-1 h-inherit pt-5  flex flex-col  justify-between items-center'>


    <StatsTile text='Available Balance' price={'$'+studentData.available_fund} />
  <StatsTile text='Total Cost' price={'$'+studentData.total_cost}/>
  <StatsTile text='Amount Left' price={'$'+studentData.amount_left}/>
  {/* <StatsTile text='Total Happiness Rating' price={studentData.total_happiness_rating}/> */}
  {/* <StatsTile text='Balance/Shortfall Amount' price={studentData.balance_shortfall}/> */}
  <StatsTile text='Total Happiness Rating' price={studentData.total_happiness_rating}/>

  <GameButton text={'Checkout'} classes={'w-full text-center px-2 mt-2'}  callFunction={handleMobileButtonClick}/>
</div>


<div ref={thankYouModalMobile}  className={`absolute  left-[0vw] sm:left-[12vw]   md:left-[43vw] top-12 md:top-[10vh] flex items-center   shadow-lg game-font justify-center  ${isMobileModalOpen ? 'block' : 'hidden'}`}>
   
      <Modal isOpen={isMobileModalOpen} onClose={handleMobileCloseModal} rules={rules}  adjusted_happiness_rating={studentData.adjusted_happiness_rating}  total_happiness_rating={studentData.total_happiness_rating} />
   </div>
        
      </div>

      {/* Content */}
    

      <button
          onClick={toggleSidebar}
          className={`md:hidden  text-[#EF9910] shadow-lg font-bold box toggle-button absolute top-5 transform transition-transform duration-300  text-center cursor-pointer  p-2 top-[40%] rounded-tr-full rounded-br-full  h-16 w-8  ${
            isSidebarOpen? 'translate-x-[320px]   z-10 ' : 'translate-x-[18%]   z-20 '
          }`}
        >
          {isSidebarOpen ? 
          <img src={LeftArrow} alt='...' className='z-10'/>
          : <img src={RightArrow} alt='...' className='z-10' />}
        </button>
    </div>
    </>
  )
}
