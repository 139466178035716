import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import SelectFilter from './SelectFilter';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiService from '../../apiService/apiService';

function CategoryDrawer({ heading,subheading,isOpen, onClose , btnHandler,editData}) {
  const [val,setVal]=useState(null)
  const [formData,setFormData]=useState({
    category_id:'',
    category_name:'',
    game_name:'',
    minimum_products:''
  })

  
  const onCancelled=()=>
  {
    setFormData({ category_id:'',category_name:'',
    game_name:'',
    minimum_products:''  
  })
    onClose()
  }



  
  
const formSubmitted=(e)=>
{
  e.preventDefault()


  if(formData.category_name==='')
  {
    toast.error("Name field is empty!", {
      position: "top-center",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
 
 
    });
  }
  else if(formData.minimum_products==='')
  {
    toast.error("Minimum products field is empty!", {
      position: "top-center",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
 
 
    });
  }

  else
  {

    btnHandler(formData)
    
    setFormData({
      category_id:'',
      category_name:'',
  game_name:'',
  minimum_products:''
})
}
 

}

const [options,setOptions]=useState(null)
const setValue = (e) => {
  const { id, value } = e.target; // Destructure id and value from e.target



  setFormData({
    ...formData,
    [id]: value
  });


}



useEffect(()=>
{
  apiService.get('/games/get-all','ad').then((res)=>
  {
    setOptions(res.data.data)
  })
},[formData])


useEffect(() => {
  if(heading==='Update Category')
  {

    setVal(editData.game_name)
    setFormData({
      category_id:editData.category_id,
      category_name: editData.category_name,
      game_name: JSON.stringify({game_name: editData.game_name, game_id:editData.game_id}),
      minimum_products:editData.minimum_products
    });


  }
  }, [editData,heading]);

  return (
    <div
      className={`fixed top-0 right-0 h-screen w-5/6 md:w-[597px] bg-white border-l shadow-lg transform transition-transform duration-300 admin-font ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
   
   <div className=' flex items-center  h-[102px] bg-[#F4F5F7] border border-[#CACACA]'>
    <div className='ml-5'>

    <h1 className='text-[24px]'>{heading}</h1>
    <p className='text-[14px] text-[#787878]'>{subheading}</p>
    </div>

      <button onClick={onClose} className="absolute bg-white text-red-500 h-6 w-6 font-bold shadow rounded-full top-4 right-4 text-gray-500 hover:text-gray-700">
        x
      </button>
   </div>

   <form onSubmit={formSubmitted}>
    
   <div className='mt-10 flex flex-col h-[74vh] justify-between '>

<div className='grid grid-cols-3   px-5 gap-y-10'>
<h1 className='text-[18px] pt-2'>Name<sup className=' text-red-500'>*</sup> </h1>
<input type='text' onChange={setValue} value={formData.category_name} placeholder='category title' id='category_name'  className='pl-2 col-span-2 h-[44px] bg-[#F4F5F7] placeholder-[#B7B7B7] text-[14px] text-[#B7B7B7] border-[1px] border-[#CACACA] rounded-md focus:text-[#0E9F6E] outline-[#0E9F6E]' />


    

<h1 className='text-[14px] md:text-[18px]   pt-2'>Game<sup className=' text-red-500'>*</sup> </h1>
<div className='col-span-2'>
<SelectFilter id={'game_name'} options={['Game 1', 'Game 2','Game 3']} selectVal={val} value={formData.game_name} onChange={setValue} classes={'w-full md:w-[372px]'}/>
</div>

<h1 className='text-[18px]   pt-2'>Minimum products<sup className=' text-red-500'>*</sup> </h1>
<input type='text' onChange={setValue} value={formData.minimum_products} placeholder='2' id='minimum_products'  className='pl-2 col-span-2 h-[44px] bg-[#F4F5F7] placeholder-[#B7B7B7] text-[14px] text-[#B7B7B7] border-[1px] border-[#CACACA] rounded-md focus:text-[#0E9F6E] outline-[#0E9F6E]' />


</div>

<div className='flex justify-evenly'>
  <button onClick={onCancelled} type='reset' className='w-[140px] text-[14px] md:text-[18px] md:w-[271px] h-[48px] bg-[#F1F5F9] flex justify-center items-center rounded-md text-red-500 font-semibold'>Cancel</button>
  <input type='submit' className='w-[140px] text-[14px] md:text-[18px] md:w-[271px] h-[48px] bg-[#0E9F6E] flex justify-center items-center rounded-md text-white font-semibold cursor-pointer' value={heading}/>
</div>
   </div>

   
   </form>
   
    </div>
  );
}

export default CategoryDrawer;
