import React from 'react'
import AdminHeader from '../../components/Admin/AdminHeader'
import AdminButton from '../../components/Admin/AdminButton'
import Table from '../../components/Admin/Table'
import { useState } from 'react'
import Drawer from '../../components/Admin/Drwaer'
import { useEffect } from 'react'
import Headbar from '../../components/Admin/Headbar'
import DeleteModal from '../../components/Admin/DeleteModal'
import TextFieldsDrawer from '../../components/Admin/TextFieldsDrawer'
import AddInputField from '../../components/Admin/AddInputField'
import WithAuth from '../../apiService/withAuth'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiService from '../../apiService/apiService'
import Toast from '../../components/Toast'
import LeftArrow from '../../assets/pagination-left-arrow.png'
import RightArrow from '../../assets/pagination_right_arrow.png'
import FilterIcon from '../../assets/filterIcon.png'

export default function Games() {
  
  const isAuthenticated=WithAuth('ad')
  
  document.title = `ScanQuest | Admin | Games`;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [games,setGames]=useState([])
  const [editData,setEditData]=useState({
    game_name:'',
    game_objectives:''
  })
  
  const [deleteData, setDeleteData] = useState([])
  
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showTextFields,setShowTextFields]=useState(false)
  const [textFields,setTextFields]=useState(Array(games.length).fill([]))
  
  const [showInputFieldModal,setShowInputFieldModal]=useState(false)
  const [editInputField,setEditInputField]=useState(false)
  const [fieldEditData,setFieldEditData]=useState({})
  const [textFieldIndex,setTextFieldIndex]=useState(null)
  const [search,setSearch]=useState('')

  const [showFilter,setShowFilter]=useState(false)



  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [pageSize] = useState(10);

  const [forReset,setForReset]=useState(false)
  const [loading,setLoading]=useState(true)
// Calculate the total number of pages
const [totalPages,setTotalPages] =useState()
//  Math.ceil(totalRecords / pageSize);

// Pagination logic to handle page changes
const handlePageChange = (page) => {
  setCurrentPage(page);
  if(search)
  {
    const data={
      search_query:search,
      current_page:page
    }
 
    apiService.post('/games/search',data,'ad').then((res)=>
    {
    setTotalRecords(res.data.data.total_records)
    setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
    setGames(res.data.data.data)
   
  
  }).catch((err)=>
  {
    if(err.response.status===401)
    {

      Toast(err.response.data.message,0)
      localStorage.clear()
      WithAuth('ad')
      
    }
    Toast(err.response.data.error,0)
  })
}

else
{

  
  apiService.get(`/games/get-all?pageSize=${pageSize}&pageNumber=${page}`,'ad').then((res)=>{
    
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setGames(res.data.data.data)
    }).catch((err)=>{
      
      if(err.response.status===401)
      {
        
        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      
      toast.error('Error fetching data. Please refresh the page', {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
      });
    })
  }
    // Implement logic to fetch data for the selected page from the backend
    // Example API call: fetchDataFromBackend(page);
};

const generatePageNumbers = () => {
  const maxVisiblePages = 7;
  const pageNumbers = [];
  if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
      }
  } else {
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
      let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
      if (endPage - startPage < maxVisiblePages - 1) {
          startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
      }

      if (startPage > 1) {
          pageNumbers.unshift('...');
      }
      if (endPage < totalPages) {
          pageNumbers.push('...');
      }
  }
  return pageNumbers;
};
// Generate pagination numbers based on total pages
const paginationNumbers = generatePageNumbers().map((pageNumber, index) => (
  <li key={index}>
      {pageNumber === '...' ? (
          <span className="px-2">...</span>
      ) : (
          <button
              className={`flex items-center justify-center px-3 h-8 leading-tight ${
                  currentPage === pageNumber ? 'text-white font-semibold rounded-md bg-[#0E9F6E]' : 'text-gray-500 '
              }`}
              onClick={() => handlePageChange(pageNumber)}
          >
              {pageNumber}
          </button>
      )}
  </li>
));


const handlePreviousPage = () => {
  if (currentPage > 1) {
      handlePageChange(currentPage - 1);
  }
};

const handleNextPage = () => {
  if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
  }
};



  useEffect(()=>
  {
  
  
  },[games,editData,textFields,showInputFieldModal,fieldEditData])
  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const onSearchChange=(e)=>
  {
    setSearch(e.target.value)
    const data={
      search_query:e.target.value,
      current_page:1
    }

 
    apiService.post('/games/search',data,'ad').then((res)=>
    {
      setCurrentPage(1)
      setTotalRecords(res.data.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setGames(res.data.data.data)
      if(e.target.value==='')
      {
        setShowFilter(false)
      }
      else
      {

        setShowFilter(true)
      }
      
    
    }).catch((err)=>
    {
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
    })

   
  }

  const openInputFieldsDrawer = (index) => {
    setTextFieldIndex(index)
    apiService.get(`/fields/get-all/${games[index].game_id}`,'ad').then((res)=>{
      
      
   
      setTextFields(res.data.data)
    }).catch((err)=>{
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
    })
    setShowTextFields(true);
  };

  const closeInputFieldsDrawer = () => {
    setShowTextFields(false);
  };


  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };



  const shouldShowInputFieldModal=()=>
  {
    
    setShowInputFieldModal(prev=>!prev)
    setEditInputField(false)
    setFieldEditData({})
   
  }


  const closeEditDrawer = () => {
    setEditDrawerOpen(false);
  };
  

  const addGame = (game) => {

 
    const gameData={
      gameRecord:game,
      current_page:currentPage
    }
    apiService.post('/games/insert',gameData,'ad').then((res)=>
    {
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
    setGames(res.data.data.data)
      
      setIsDrawerOpen(false)
      toast.success('Game Added Successfully', {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
      });
    }).catch((err)=>
    {
      if(err.response.status===401)
      {

        Toast('Wrong Credentials',0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      toast.error(err.response.data.error, {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
      });
      console.log(err)
    })
  };

  const editRecord=(recordData)=>
  {
 
    setEditData(recordData)
    setEditDrawerOpen(true)
  
  }
  
  const updateRecord=(updatedData)=>
  {
    const records=[...games]
  
    
    records[editData.index].game_name=updatedData.game_name
    records[editData.index].game_objectives=updatedData.game_objectives
    
    const game_id=games[editData.index].game_id

    const data=records[editData.index]
    apiService.put(`/games/update/${game_id}`,data,'ad').then((res)=>{
      
     
      setGames(prevGames => {
        const updatedGames = [...prevGames]; // Create a copy of the original games array using the spread operator
        updatedGames[editData.index] = data; // Update the specific game object at the given index with the new data
        return updatedGames; // Return the updated array to update the state
      });
    
      toast.success('Game updated successfully', {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
      });
    setEditDrawerOpen(false)
  }).catch((err)=>{
    if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
  })
    
  }

  

  
  const showModal = (i,reset=false) => {
    
    if(reset===true)
    {
      setForReset(true)
    }
    else
    {
      setForReset(false)
    }
    setDeleteData([...deleteData, games[i]])
    setShowDeleteModal(prev => !prev)
  }
  
  const onDeleteCall = () => {
    


    apiService.delete(`games/delete/${deleteData[0].game_id}`,'ad').then((res)=>
    {
      
      
    setGames(games.filter((element, index) => !deleteData.includes(element)))
      
      setShowDeleteModal(prev => !prev)
    
      toast.success(res.data.message, {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
      });
      
      
      if(games.length===1)
      {
     
        if(currentPage-1>0)
        {
          handlePageChange(currentPage-1)
        }
      }
    }).catch((err)=>
    {
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.message,0)
    })
    
    setDeleteData([])
  }

  const onResetCall = () => {
    


    apiService.delete(`games/reset/${deleteData[0].game_id}`,'ad').then((res)=>
    {
      
      
      
      setShowDeleteModal(prev => !prev)
    
      toast.success(res.data.message, {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
      });
      
      
      if(games.length===1)
      {
     
        if(currentPage-1>0)
        {
          handlePageChange(currentPage-1)
        }
      }
    }).catch((err)=>
    {
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.message,0)
    })
    
    setDeleteData([])
  }
  const onKeepCall = () => {
    setDeleteData([])

    setShowDeleteModal(prev => !prev)

  }

  const addTextField=(FieldData)=>
  {   
    const textField={label:FieldData.label,value:FieldData.value,type:FieldData.type,status:FieldData.status}

  
    const textFieldData=[...textFields]
    textField.game_id=games[textFieldIndex].game_id
   
    apiService.post('/fields/insert',textField,'ad').then((res)=>{

    
      
   Toast(res.data.message,1)
      
      setShowInputFieldModal(false)
      setTextFields(res.data.data)
    }).catch((err)=>{

      console.log(err)
      Toast(err.response.data.error,0)
    })
    


  }

  const deleteTextField=(i)=>
  {
   
    const textFieldData=[...textFields]
   
    apiService.delete(`/fields/delete/${textFieldData[i].field_id}`,'ad').then((res)=>
    {
      Toast(res.data.message,1)
      const indexData=textFieldData.filter((element,index)=>index!==i)
  
     setTextFields(indexData)
      
    }).catch((err)=>{
      if(err.response.status===401)
      {
        Toast(err.response.data.message,0)

       localStorage.clear()
       WithAuth('ad')
      }
      Toast(err.response.data.error,0)
    })

  }

  const updateFieldRecord=(updatedData,hideCall=false)=>
  {
    
    
    if(hideCall)
    {
      
      
      const records=[...textFields]
      const index=updatedData.index
      
      records[index].label=updatedData.label
      records[index].value=updatedData.value
      records[index].type=updatedData.type
      records[index].status=updatedData.status
      updatedData.hidden=Number(updatedData.hidden)===1?0:1
      records[index].hidden=updatedData.hidden
      // updatedData.game_id=fieldEditData.game_id
      console.log(updatedData)
      apiService.put(`/fields/update/${records[index].field_id}`,updatedData,'ad').then((res)=>{
        
    
        Toast(res.data.message,1)
        setTextFields(records)
        setEditInputField(false)
        setFieldEditData({})
  setShowInputFieldModal(false)
}).catch((err)=>{
    Toast(err.response.data.error,0)
})
    }
    else
    {
      const records=[...textFields]
      const index=fieldEditData.index
   
      records[index].label=updatedData.label
      records[index].value=updatedData.value
      records[index].type=updatedData.type
      records[index].status=updatedData.status
      updatedData.game_id=fieldEditData.game_id
      
      console.log(updatedData)
        apiService.put(`/fields/update/${records[index].field_id}`,updatedData,'ad').then((res)=>{
        
    
          Toast(res.data.message,1)
          setTextFields(records)
          setEditInputField(false)
          setFieldEditData({})
    setShowInputFieldModal(false)
  }).catch((err)=>{
      Toast(err.response.data.error,0)
  })

}
  
  
  }
  const editFieldData=(data)=>
  {

   
  
    setFieldEditData(data)
    setEditInputField(true)
    setShowInputFieldModal(true)
    
  }
  

  const resetFilters=async()=>
  {
    setSearch('')
    setShowFilter(false)
    setLoading(true)
    apiService.get(`/games/get-all?pageSize=${pageSize}&pageNumber=${1}`,'ad').then((res)=>{
      setTotalRecords(res.data.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setGames(res.data.data.data)
      setCurrentPage(1)
      setLoading(false)
    }).catch((err)=>{

        if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }

      toast.error('Error fetching data. Please refresh the page', {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
      });
    })
  }

  useEffect(()=>
  {

  },[showFilter])
  useEffect(()=>{

  
    
    apiService.get(`/games/get-all?pageSize=${pageSize}&pageNumber=${currentPage}`,'ad').then((res)=>{
      setTotalRecords(res.data.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setGames(res.data.data.data)
      setLoading(false)
    }).catch((err)=>{

        if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }

      toast.error('Error fetching data. Please refresh the page', {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
      });
    })
  },[])
  return (
  isAuthenticated &&  <div className='w-screen md:w-[85vw] min-h-screen bg-[#F9FAFB] admin-font overflow-y-auto'>
        <AdminHeader tabName={'Games'}/>
<div className='flex justify-center mx-2 md:mx-10 '>
<Headbar>

            <input type='text' id='search' value={search} onChange={onSearchChange} placeholder='Search Game' className='pl-4 w-full h-[40px] bg-[#F4F5F7] text-[#CACACA] placeholder-[#B7B7B7] border-[1px] border-[#CACACA] rounded-lg outline-[#0E9F6E]'/>

        <AdminButton btnText={'+ Add Game'} onClickCall={openDrawer}  />
        <Drawer heading={'Add Game'} subheading={'Add your game and necessary information from here.'} isOpen={isDrawerOpen} onClose={closeDrawer} btnHandler={addGame} editData={editData}/>
        <Drawer heading={'Update Game'} subheading={'Update your game and necessary information from here.'} isOpen={editDrawerOpen} onClose={closeEditDrawer} btnHandler={updateRecord}  editData={editData}/>
        <TextFieldsDrawer heading={'Input Text Fields'} subheading={'Add you input and necessary information from here.'} isOpen={showTextFields} onClose={closeInputFieldsDrawer} btnHandler={updateRecord}  editData={editData}
        textFields={textFields}
        addTextField={addTextField}
        deleteTextField={deleteTextField}
        shouldShowInputFieldModal={shouldShowInputFieldModal}
        updateFieldRecord={editFieldData}
        hideField={updateFieldRecord}
        
        />

</Headbar>
      
    </div>

<div className='mt-10 flex mx-2 md:mx-5 justify-center '>
    <Table headers={['Game Name','Game Objectives','Total Products','Total Categories','Text Field']} data={games} editRecord={editRecord} deleteRecord={showModal} openInputFieldsDrawer={openInputFieldsDrawer} loading={loading} />
</div>


<nav aria-label="Page navigation example trainer-stats-font">
            <ul className="flex justify-end my-5 mr-8 -space-x-px text-sm text-white items-center">
                    <li className="mr-2 cursor-pointer" onClick={handlePreviousPage}>
                        <img src={LeftArrow} alt="..." />
                    </li>
                    {paginationNumbers}
                    <li className="ml-2 cursor-pointer" onClick={handleNextPage}>
                        <img src={RightArrow} alt="..." />
                    </li>
                </ul>
            </nav>


<DeleteModal from={forReset} showDeleteModal={showDeleteModal} onDeleteCall={onDeleteCall} onKeepCall={onKeepCall} onResetCall={onResetCall} />
<AddInputField showInputFieldModal={showInputFieldModal} shouldShowInputFieldModal={shouldShowInputFieldModal} addTextField={addTextField} fieldEditData={fieldEditData}
 editInputField={editInputField}
 onUpdateCall={updateFieldRecord}
 
 />

{
 
showFilter  &&
<div onClick={resetFilters} className='absolute cursor-pointer z-50 top-2 right-5 bg-[#0E9F6E] rounded-md p-2'>
<img src={FilterIcon}
className='h-6'
/>
</div>
}
 <ToastContainer/>
</div>
  )
}
