import React, { useEffect, useRef } from 'react';

import '../App.css';


export default function ItemsModal({from ,name, showItemsModal, modalItems,onOutsideClick, onDeleteCall }) {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside the modal
        onOutsideClick();
      }
    }

    if (showItemsModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
       
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showItemsModal]);


  return (
    <div
      ref={modalRef}
      className={`admin-font flex flex-col  pt-5 absolute duration-500 left-[10%]  md:left-[28%] w-5/6 md:w-1/2 h-[450px] bg-white rounded-lg shadow-lg border border-[#CACACA]  ${
        showItemsModal ? 'top-[28%]' : '-top-[80%] '
      }`}
    >
        <h1 className='text-[#787878} text-xl text-center mb-1'><b className={`  mr-1 ${from==="trainer"?'text-[#404EED]':'text-[#0E9F6E]'}`}>
             {name}
             </b>
             Items
             </h1>
             <button className='absolute right-3 text-xl top-1' onClick={onOutsideClick}>x</button>
   <hr className='mx-10 text-[#CACACA]'></hr>
         <div className={`grid grid-cols-1 gap-3  mt-4 place-items-center  overflow-y-auto my-6  ${modalItems.length>=3?' md:grid-cols-3':modalItems.length===2?' md:grid-cols-2':'md:grid-cols-1'} ${from==='trainer'?'trainer-scrollbar':'admin-scrollbar'}`}>
            
         {
            modalItems && modalItems.map((product,index)=>
            {

       
         return <div key={index} className=" top-[20%] -left-[102%] bg-[#F1F5F9] h-auto rounded-lg pb-2 w-52 pr-2 ">
                                <div className="mx-2 my-2">
                                  <ul className="space-y-3">
                                    <li className=" rounded-[5px]  text-black">
                                      <div className="flex  items-center ml-2 space-x-2 ">
                                        <img
                                          src={product.image}
                                          alt="..."
                                          className="w-full h-28 rounded-md"
                                        />
                                      
                                      </div>
                                      <h1 className='text-center font-semibold my-2'>{product.name}</h1>
                                      <hr className='mx-1 text-[#CACACA]' ></hr>
                                    </li>

                                    <li>
                                      <div className="flex justify-between items-center">
                                        <h1>Price</h1>
                                        <h1 className={`font-semibold ${from==='trainer'?'text-[#404EED]':'text-[#0E9F6E] '}`}>
                                          ${product.price}
                                        </h1>
                                      </div>
                                    
                                    </li>

                                      <li>
                                      <div className="flex justify-between items-center">
                                        <h1>Quantity</h1>
                                        <h1 className={` font-semibold ${from==='trainer'?'text-[#404EED]':'text-[#0E9F6E] '}`}>
                                         {product.quantity}
                                        </h1>
                                      </div>
                                 
                                    </li>

                                    <li>
                                      <div className="flex justify-between items-center">
                                        <h1>Happiness Rating</h1>
                                        <h1 className={`font-semibold ${from==='trainer'?'text-[#404EED]':'text-[#0E9F6E] '}`}>
                                         {product.happiness_rating}
                                        </h1>
                                      </div>
                                   
                                    </li>
                                  </ul>
                                </div>
                              </div> 
              })
            }
        </div>

        
        </div>

    )
}
