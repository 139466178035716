import React from 'react'
import LogoutIcon from '../../assets/logout.png'
import ExportIcon from '../../assets/admin/export.png'
export default function AdminButton({ btnText, onClickCall,classess}) {
    return (
        <div onClick={onClickCall} className={`h-[40px] w-full md:w-[185px] bg-[#0E9F6E] rounded-lg flex justify-center space-x-3 items-center  admin-font cursor-pointer ${btnText!=='Export'&& btnText!=='No, Keep it'?'text-white':''} ${btnText==='No, Keep it' || btnText==='Yes, Delete it'?'w-1/3 md:w-[185px]':''} ${classess}`}>
            {
                btnText==='Log Out'? 
                <img src={LogoutIcon} alt='...'/>
                :
                btnText==='Export'?
                <img src={ExportIcon} alt='...'/>
                
                :''
            }
      
            <h1 className={`text-[10px] text-center  ${btnText==='+ Add Happiness Rating'?'md:text-[12px]':'md:text-[14px]'} `}>{btnText}</h1>
        </div>
    )
}
