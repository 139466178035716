import React from 'react'
import Headers from '../../components/game/Headers'
import GameButton from '../../components/game/GameButton'
import { Link } from 'react-router-dom'
import GoBack from '../../components/game/GoBack'
import gameObj from '../../assets/gameObj.png'
import WithAuth from '../../apiService/withAuth'
import { useEffect } from 'react'
import { useState } from 'react'
import Legals from '../../components/Legals'

export default function Objectives() {

    const isAuthenticated=WithAuth('cad')
    const [objectives,setObjectives]=useState()
  document.title = `ScanQuest | Objectives`;

    useEffect(()=>{
        setObjectives(localStorage.getItem('objectives'))
    },[])
    return (
     isAuthenticated &&   <>
      <GoBack text='GAME OBJECTIVES' classes={'absolute'}/>
                <div className='flex items-center h-screen  justify-center align-center'>

            <div className='bg-black/60   rounded-[20px] w-[880px]  md:mx-0 mx-4   pb-10 game-border game-font'>
                <Headers text='Game Objectives' classes={'text-[20px]  md:text-5xl py-0 md:py-3'} imgName={gameObj}  />
<div className=' flex flex-col justify-between  min-h-[44vh]'>

                <div className=' text-white text-center max-h-[32vh] overflow-y-auto  scrollbar text-[24px] md:text-2xl  flex justify-center  mx-10 mt-10'>
                    <p>
                        “{objectives}”
                    </p>
                </div>
 
                <Link to='/planning'>
                
                <div className='flex justify-center mt-5 text-white '>
                <GameButton text='Start'/>
                </div>
                </Link>
</div>
            </div>

        </div>
        <div className="fixed left-0 right-0 bottom-2 flex justify-center">


<Legals isGame={true} classes={'text-center '}/>
      </div>
        </>

    )
}
