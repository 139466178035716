import React, { useState } from 'react'
import AdminHeader from '../../components/Admin/AdminHeader'
import Headbar from '../../components/Admin/Headbar'
import SelectFilter from '../../components/Admin/SelectFilter'
import AdminButton from '../../components/Admin/AdminButton'
import Table from '../../components/Admin/Table'
import ProductDrawer from '../../components/Admin/ProductDrawer'
import DeleteModal from '../../components/Admin/DeleteModal'
import WithAuth from '../../apiService/withAuth'
import apiService from '../../apiService/apiService'
import Toast from '../../components/Toast'
import { useEffect } from 'react'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LeftArrow from '../../assets/pagination-left-arrow.png'
import RightArrow from '../../assets/pagination_right_arrow.png'
import FilterIcon from '../../assets/filterIcon.png'


export default function Products() {
  const isAuthenticated=WithAuth('ad')

  const [products,setProducts]=useState([])
  const [editData,setEditData]=useState({
    qr_code:'',
    image:'',
    name:'',
    game_name:'',
    category_name:'',
    cost:'',
    happiness_rating:'',
    file:''
  })

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
 
  document.title = `ScanQuest | Admin | Products`;


  const [deleteData,setDeleteData]=useState([])

  const [showDeleteModal,setShowDeleteModal]=useState(false)

  const [filterData,setFilterData]=useState({
    search_query:'',
    game_name:'',
    category_name:''
  })

  const [showFilter,setShowFilter]=useState(false)

  
  const [categories,setCategories]=useState()
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [pageSize] = useState(10);


// Calculate the total number of pages
const [totalPages,setTotalPages] =useState()

const [loading,setLoading]=useState(true)


// Pagination logic to handle page changes
const handlePageChange = (page) => {

  if(currentPage!==page)
  {


    setCurrentPage(page);

   
    if(filterData.search_query || filterData.game_name || filterData.category_name)
    {
      filterData.game_id=null
    
  
      if(filterData.game_name)
      {
    
  
        filterData.game_id=JSON.parse(filterData.game_name).game_id
      }
      if(filterData.category_name)
      {
    
  
        filterData.category_id=JSON.parse(filterData.category_name).category_id
      }
      filterData.current_page=page
      apiService.post('/products/search',filterData,'ad').then((res)=>
      {
        setTotalRecords(res.data.total_records)
        setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
        setProducts(res.data.data.data)
      }).catch((err)=>
      {
        if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)

      })
    }

    else
    {

      apiService.get(`/products/get-all?pageSize=${pageSize}&pageNumber=${page}`,'ad').then((res)=>{
        setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setProducts(res.data.data.data)
    }).catch((err)=>{
      
      if(err.response.status===401)
      {
        
        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)

    })
  }
  }
  // Implement logic to fetch data for the selected page from the backend
    // Example API call: fetchDataFromBackend(page);
};

const generatePageNumbers = () => {
  const maxVisiblePages = 7;
  const pageNumbers = [];
  if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
      }
  } else {
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
      let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
      if (endPage - startPage < maxVisiblePages - 1) {
          startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
      }

      if (startPage > 1) {
          pageNumbers.unshift('...');
      }
      if (endPage < totalPages) {
          pageNumbers.push('...');
      }
  }
  return pageNumbers;
};
// Generate pagination numbers based on total pages
const paginationNumbers = generatePageNumbers().map((pageNumber, index) => (
  <li key={index}>
      {pageNumber === '...' ? (
          <span className="px-2">...</span>
      ) : (
          <button
              className={`flex items-center justify-center px-3  h-8 leading-tight ${
                  currentPage === pageNumber ? 'text-white mx-2 font-semibold rounded-md bg-[#0E9F6E]' : 'text-gray-500 '
              }`}
              onClick={() => handlePageChange(pageNumber)}
          >
              {pageNumber}
          </button>
      )}
  </li>
));


const handlePreviousPage = () => {
  if (currentPage > 1) {
      handlePageChange(currentPage - 1);
  }
};

const handleNextPage = () => {
  if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
  }
};




  const setValue = (e) => {
    const { id, value } = e.target; // Destructure id and value from e.target
  

  
    setFilterData({
      ...filterData,
      [id]: value
    });
  
    let data={...filterData,[id]:value}
  
    data.game_id=null
    data.category_id=null
    if(data.category_name)
    {

      data.category_id=JSON.parse(data.category_name).category_id
    }

    if(data.game_name)
    {

      data.game_id=JSON.parse(data.game_name).game_id
    }
  
    data.current_page=1
    apiService.post('/products/search',data,'ad').then((res)=>
    {
      setTotalRecords(res.data.total_records)
        setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
        setProducts(res.data.data.data)
        if(e.target.value==='')
        {
      setShowFilter(false)
  
        }
        else
        {
      setShowFilter(true)
  
        }
    }).catch((err)=>
    {
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)

    })
  
  }

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {

    setEditData({
      qr_code:'',
      image:'',
      name:'',
      game_name:'',
      category_name:'',
      cost:'',
      happiness_rating:'',
      file:''
    })
    setIsDrawerOpen(false);
  };



  const closeEditDrawer = () => {

    setEditData({
      qr_code:'',
      image:'',
      name:'',
      game_name:'',
      category_name:'',
      cost:'',
      happiness_rating:'',
      file:''
    })
    setEditDrawerOpen(false);
  };


  
  const addProduct = (product) => {

  
    product.game_id=JSON.parse(product.game_name).game_id
    product.game_name=JSON.parse(product.game_name).game_name

    product.category_id=JSON.parse(product.category_name).category_id
    product.category_name=JSON.parse(product.category_name).category_name

    const formData = new FormData();
    formData.append('qr_code',product.qr_code)
  formData.append('image', product.image);
  formData.append('name', product.name);

    formData.append('game_id', product.game_id);
  
    formData.append('category_id', product.category_id);
  
  formData.append('cost', product.cost);
  formData.append('happiness_rating', product.happiness_rating);

  formData.append('current_page',currentPage)

    apiService.post('/products/insert',formData,'ad').then((res)=>
    {
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setProducts(res.data.data.data)

      Toast(res.data.message,1)
      setIsDrawerOpen(false)
    }).catch((err)=>
    {
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)

    })
  
  };

  const editRecord=(recordData)=>
  {

    setEditData(recordData)
    setEditDrawerOpen(true)
  
  }

  const updateRecord=(updatedData)=>
  {
    const records=[...products]

 
    records[editData.index].qr_code=updatedData.qr_code

    records[editData.index].image=updatedData.image
    records[editData.index].file=updatedData.file

    records[editData.index].name=updatedData.name
    records[editData.index].game=updatedData.game
    records[editData.index].category=updatedData.category
    records[editData.index].cost=updatedData.cost
    records[editData.index].happiness_rating=updatedData.happiness_rating

    
    const game=JSON.parse(updatedData.game_name)
    updatedData.game_name=game.game_name
    updatedData.game_id=game.game_id
    const category=JSON.parse(updatedData.category_name)
    updatedData.category_id=category.category_id
    records[editData.index].game_name=game.game_name
    records[editData.index].category_name=category.category_name
    const formData=new FormData()
    formData.append('qr_code',updatedData.qr_code)
    formData.append('image', updatedData.image);
    formData.append('name', updatedData.name);
  
      formData.append('game_id', updatedData.game_id);
    
      formData.append('category_id', updatedData.category_id);
    
    formData.append('cost', updatedData.cost);
    formData.append('happiness_rating', updatedData.happiness_rating);
  
    formData.append('current_page',currentPage)
    
    apiService.put(`products/update/${products[editData.index].product_id}`,formData,'ad').then((res)=>
    {
      const url=res.data.url
      records[editData.index].image=url

   
      setProducts(records)
      setEditDrawerOpen(false)
      Toast(res.data.message,1)
    }).catch((err)=>
    {
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
    })
   
  
  }

  const deleteRecord=(i)=>
  {
    
    setProducts(products.filter((element, index) => index !== i))
  }

  const showModal = (i) => {

    setDeleteData([...deleteData, products[i]])
    setShowDeleteModal(prev => !prev)
  }

  const onDeleteCall = () => {

   
    apiService.delete(`/products/delete/${deleteData[0].product_id}`,'ad').then((res)=>
  {
    setProducts(products.filter((element, index) => !deleteData.includes(element)))
    setShowDeleteModal(prev => !prev)
    Toast(res.data.message,1)

    if(products.length===1)
    {
   
      if(currentPage-1>0)
      {
        handlePageChange(currentPage-1)
      }
    }
  }).catch((err)=>
  {
    if(err.response.status===401)
    {

      Toast(err.response.data.message,0)
      localStorage.clear()
      WithAuth('ad')
      
    }
    Toast(err.response.data.error,0)

  })

    setDeleteData([])

  }

  const onKeepCall = () => {
    setDeleteData([])

    setShowDeleteModal(prev => !prev)

  }

  const resetFilters=async()=>
  {

    setFilterData({
      search_query:'',
      game_name:'',
      category_name:''
    })
    setShowFilter(false)
    
    setLoading(true)
    apiService.get(`/products/get-all?pageSize=${pageSize}&pageNumber=${1}`,'ad').then((res)=>
    {
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setProducts(res.data.data.data)
      setCurrentPage(1)
      setLoading(false)


    }).catch((err)=>
    {
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)

    })
  }
  useEffect(()=>{
    apiService.get(`/products/get-all?pageSize=${pageSize}&pageNumber=${1}`,'ad').then((res)=>
    {
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setProducts(res.data.data.data)
      setLoading(false)


    }).catch((err)=>
    {
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)

    })

    apiService.get(`/categories/get-all`,'ad').then((res)=>
    {
     
      setCategories(res.data.data)
    }).catch((err)=>
    {
      console.log(err)
    })
  
    

  },[])


  
  return (
   isAuthenticated && <div className='w-screen md:w-[85vw] min-h-screen overflow-y-auto bg-[#F9FAFB] admin-font  '>
    <AdminHeader tabName={'Products'}/>
    
<div className='flex justify-center mx-2 md:mx-10 text-[10px] md:text-[14px] '>
<Headbar>

        <input type='text' id='search_query' value={filterData.search_query} onChange={setValue} placeholder='Seach Product' className=' pl-4 w-full md:w-[420px] h-[40px] bg-[#F4F5F7] text-[#CACACA] placeholder-[#B7B7B7] border-[1px] border-[#CACACA] rounded-lg outline-[#0E9F6E] focus:text-[#0E9F6E]'/>
    <SelectFilter id={'category_name'} value={filterData.category_name} onChange={setValue} options={['Category','Game B','Game C']} classes={'w-full md:w-[222px]'} categoies={categories}/>
    <SelectFilter id={'game_name'} value={filterData.game_name} onChange={setValue} options={['Game A','Game B','Game C']}   classes={'w-full md:w-[222px]'}/>
    <AdminButton btnText={'+ Add Product'} onClickCall={openDrawer} />
   
    <ProductDrawer heading={'Add Product'} subheading={'Add your product and necessary information from here.'} isOpen={isDrawerOpen} onClose={closeDrawer} btnHandler={addProduct} editData={editData}/>
    <ProductDrawer heading={'Update Product'} subheading={'Update your product and necessary information from here.'} isOpen={editDrawerOpen} onClose={closeEditDrawer} btnHandler={updateRecord} editData={editData}/>


</Headbar>
    </div>

    <div className='mt-10 flex mx-2 md:mx-5 justify-center '>
    <Table headers={['Image','Name','Game Name','Category Name','Cost','Happiness Rating']} data={products} editRecord={editRecord}   deleteRecord={showModal} loading={loading} />
</div>


<nav aria-label="Page navigation example trainer-stats-font">
            <ul className="flex justify-end my-5 mr-8 -space-x-px text-sm text-white items-center">
                    <li className="mr-2 cursor-pointer" onClick={handlePreviousPage}>
                        <img src={LeftArrow} alt="..." />
                    </li>
                    {paginationNumbers}
                    <li className="ml-2 cursor-pointer" onClick={handleNextPage}>
                        <img src={RightArrow} alt="..." />
                    </li>
                </ul>
            </nav>

<DeleteModal showDeleteModal={showDeleteModal} onDeleteCall={onDeleteCall} onKeepCall={onKeepCall} />

 
{
showFilter  &&
 <div onClick={resetFilters} className='absolute cursor-pointer z-50 top-2 right-5 bg-[#0E9F6E] rounded-md p-2'>
 <img src={FilterIcon}
 className='h-6'
 />
 </div>
 }
    <ToastContainer/>
    </div>
  )
}
