import React from 'react'
import '../App.css'

export default function Legals({classes, isGame}) {
  return (
    <>
   {

//      !isGame ?
//      <div className={` legals ${classes}`}>
//       <a href='https://mayonity.com/' target='_blank' className=' hover:text-indigo-500'>Developed by <b>Mayonity</b> 	&#169; </a>
//     </div>
//     :
//     <div className={`${classes}`}>

//     <a href='https://mayonity.com/' target='_blank'>
// <div className={`game-font relative px-10 text-white flex justify-center items-center game-headers-bg  rounded-md mb-2 `}>
// <h1 className={`text-xl py-2 font-semibold z-10 text-shad  `} >Developed by Mayonity</h1>
// <div className='absolute -top-1 left-1 opacity-75 h-2  game-headers-bg2 w-full '></div>
// <div className='absolute  bottom-0 left-20 opacity-75 h-2 game-headers-bg2 w-3/4 '></div>
// <div className='absolute  -left-5 opacity-[10%] h-12 game-headers-bg2 w-32 '></div>
// <div className='absolute  -right-5 opacity-[10%] h-12  game-headers-bg2 w-32 '></div>
// <div className='absolute bottom-1 shadow-md  rounded-br-[16px] rounded-bl-[16px] w-full opacity-25   h-1/2 bg-[#572b03]'>

// </div>
// </div>
   
//     </a>
//       </div>
    }
    </>
  )
}
