import React, { useEffect } from "react";
import EditIcon from "../../assets/admin/edit.png";
import TrashIcon from "../../assets/admin/trash.png";
import ResetIcon from '../../assets/admin/reset.png'
import "../../App.css";
import { useState } from "react";
import { useOutsideClick } from "../../pages/admin/UseOutsideClick";
import { useRef } from "react";
import apiService from "../../apiService/apiService";
import Toast from "../Toast";
import ItemsModal from "../ItemsModal";
import WithAuth from "../../apiService/withAuth";
import spinner from '../../assets/spinner.svg'
import visibleIcon from  '../../assets/visible.png'
import NotVisibleIcon from '../../assets/not_visible.png'

export default function Table({
  headers,
  data,
  editRecord,
  deleteRecord,
  showCheckbox,
  setDeleteRecord,
  checkedRecords,
  headerCheckbox,
  openInputFieldsDrawer,
  isStudent=false,
  loading,
  hideField
}) {
  const [shouldViewItems, setShouldViewItems] = useState(
    data?
    Array(data.length).fill(false):''
  );
  const modalRef = useRef(null);

  
  const [product,setProduct]=useState([])

  const [modalItems,setModalItems]=useState([])
  const [showItemsModal,setItemsModal]=useState(false)
  const [personName,setPersonName]=useState()

  const viewItems = (i,name) => {
  
    const prod= {
      "products": data[i].products
    }
    apiService.post('/students/get-products',prod,'ad').then((res)=>
    {
      
   
      setPersonName(name)
      const responseData= res.data.data
      setModalItems(responseData)
      setItemsModal(true)
      const updatedMenuVisibility = [...shouldViewItems];
      updatedMenuVisibility[i] = !updatedMenuVisibility[i];
      setShouldViewItems(updatedMenuVisibility);

    }).catch((err)=>
    {
      
      if(err.response.status===401)
      {
        
        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
    })
  
  };

  useOutsideClick(modalRef, () => {
    // Close the dropdown when a click occurs outside the modal
    setProduct([])
    setShouldViewItems(Array(shouldViewItems.length).fill(false));
  });


  const showProduct=(index)=>
  {

      
    const productData=[{
      image:modalItems[index].image,
      name:modalItems[index].name,
      price:modalItems[index].price,
      quantity:modalItems[index].quantity,
      happiness_rating:modalItems[index].happiness_rating
    }]
    setProduct(productData)

  }
  const transformedHeaders = headers.map((header) => {
    // Convert "Game Objective" to "game_objectives"
    return header === "Game Objective"
      ? "game_objectives"
      : header.toLowerCase().replace(/ /g, "_");
  });

  const onOutsideModalClick=()=>
  {
    setItemsModal(false)
    setPersonName('')
  }

  useEffect(()=>
  {},[product])
  return (
    <div className="rounded-lg  mx-1 md:mx-5 border-[1.2px]   mb-5 md:overflow-x-auto overflow-x-scroll w-full  border-[#CACACA]  admin-font">
      <table className="w-full">
        <thead>
          <tr className="bg-[#F4F5F7] text-[#818181]  text-[10px] md:text-[14px] rounded-lg text-center uppercase">
            {headers.map((col, index) => {
              return col === "checkbox" && showCheckbox ? (
                <td
                  key={index}
                  className="px-6 py-4 border-[#CACACA] border-b-[1.2px]  "
                >
                  <input
                    type="checkbox"
                    className="w-[24px] h-[16px]"
                    onClick={() => setDeleteRecord(-1)}
                    checked={headerCheckbox}
                  />
                </td>
              ) : col === "checkbox" ? (
                ""
              ) : (
                <td
                  key={index}
                  className={`px-6 py-4 border-[#CACACA] border-b-[1.2px] ${col==='Game Objectives'?'text-start pl-10':''} `}
                >
                  {col}
                </td>
              );
            })}
            {  showCheckbox ? (
              ""
            ) :!isStudent? (
              <td className="px-6 py-4 border-[#CACACA] border-b-[1.2px]  ">
                Actions
              </td>
            )
          :
          <td className="px-6 py-4 border-[#CACACA] border-b-[1.2px]  ">
          
        </td>
          }
          </tr>
        </thead>

        <tbody className="text-[#444343]">
           { loading?
           <div className="absolute left-1/3 md:left-1/2 ml-10 mt-10">
            <img className='animate-spin text-center h-8 bg-[#0E9F6E] rounded-md p-1 mx-auto' src={spinner} alt='Loading'/>
           </div>
           : data && data.length===0?
            <div className="absolute left-1/3 md:left-1/2 mt-10 font-semibold">No Data Found.</div>
           : data && data.length ? (
            data.map((item, index) => {
              return (
                <tr
                  key={index}
                  className="text-center text-[10px] md:text-[14px]"
                >
                  {transformedHeaders.map((key, columnIndex) => {
                    return key === "checkbox" && showCheckbox ? (
                      <td
                        key={columnIndex}
                        className=" px-3 py-4 border-[#CACACA] border-b-[1.2px]"
                      >
                        <input
                          type="checkbox"
                          onClick={() => setDeleteRecord(index)}
                          checked={checkedRecords[index]}
                          className="border-solid border border-gray-300 bg-white p-2 rounded w-[24px] h-[16px]"
                        />
                      </td>
                    ) : key === "checkbox" ? (
                      ""
                    ) : (
                      <td
                        key={columnIndex}
                        className={`px-3 py-4 border-[#CACACA] border-b-[1.2px] ${key==='game_objectives'?'text-justify pl-10':''}`}
                      >
                        {key === "image" ? (
                          <div className=" flex items-center justify-center ">
                            <img
                              src={item.image}
                              alt="..."
                              className="w-[46px] h-[40px] rounded-md"
                            />
                          
                          </div>
                        ) : key === "items" ? (
                          <div className="relative ">
                            <button
                              onClick={() => viewItems(index,item.name)}
                              className={`cursor-pointer hover:text-[#0E9F6E] text-sm `}
                            >
                              View Items
                            </button>
                            {/* {shouldViewItems[index] && 
                            ( <div
                             key={index}
                              ref={modalRef}
                              className="absolute bg-white w-[185px]  h-auto rounded-lg pb-4 border border-[#CACACA] mt-2 -ml-40 md:-ml-20 p-2 shadow-lg z-10 "
                            >
                              <div className="flex justify-center text-[16px] my-2 pb-2 mx-2 space-x-2 border-b-[1px] border-[#CACACA]">
                                <h1 className="text-[#0E9F6E] font-semibold  ">
                                  {item.name}
                                </h1>
                                <h1>Items</h1>
                              </div>
                              {
                                modalItems && modalItems.map((modalItem,index)=>{

                             return <ul className="mt-4 space-y-3" key={index}>
                                <li className="bg-[#F1F5F9] rounded-[5px] cursor-pointer text-black" onClick={()=>showProduct(index)}>
                                  <div className="flex  items-center ml-2 space-x-2 h-[39px]">
                                    <img
                                      src={modalItem.image}
                                      alt="..."
                                      className="w-[35px] h-[32px] rounded-md"
                                    />
                                    <h1>{modalItem.name}</h1>
                                  </div>
                                </li>

                        
                              </ul>
                              
                            })
                          }

                            {
                              product.length>0 &&
                       
                             <div className="absolute top-[20%] -left-[102%] bg-white h-auto rounded-lg pb-2 border border-[#CACACA] w-[185px] ">
                                <div className="mx-2 my-2">
                                  <ul className="space-y-3">
                                    <li className="bg-[#F1F5F9] rounded-[5px]  text-black">
                                      <div className="flex  items-center ml-2 space-x-2 h-[39px]">
                                        <img
                                          src={product[0].image}
                                          alt="..."
                                          className="w-[35px] h-[32px] rounded-md"
                                        />
                                        <h1>{product[0].name}</h1>
                                      </div>
                                    </li>

                                    <li>
                                      <div className="flex justify-between items-center">
                                        <h1>Price</h1>
                                        <h1 className="text-[#0E9F6E] font-semibold">
                                          ${product[0].price}
                                        </h1>
                                      </div>
                                      <hr className="mt-1"></hr>
                                    </li>

                                      <li>
                                      <div className="flex justify-between items-center">
                                        <h1>Quantity</h1>
                                        <h1 className="text-[#0E9F6E] font-semibold">
                                         {product[0].quantity}
                                        </h1>
                                      </div>
                                      <hr className="mt-1"></hr>
                                    </li>

                                    <li>
                                      <div className="flex justify-between items-center">
                                        <h1>Happiness Rating</h1>
                                        <h1 className="text-[#0E9F6E] font-semibold">
                                         {product[0].happiness_rating}
                                        </h1>
                                      </div>
                                   
                                    </li>
                                  </ul>
                                </div>
                              </div>     } 
                            </div>
                            )
                          }
                           */}
                          </div>
                        ) : (
                          <div>
                            {key === "text_field" ? (
                              <button
                                onClick={() => openInputFieldsDrawer(index)}
                              >
                                view
                              </button>
                            ) : key === "status" ? (
                              Number(item[key]) === 1  ? (
                                "Predefine"
                              ) : (
                                "Default"
                              )
                            ) : (
                              item[key]
                            )}
                          </div>
                        )}
                      </td>
                    );
                  })}

                  {showCheckbox ? (
                    ""
                  ) : (
                    <td className="px-6 py-4 border-[#CACACA] border-b-[1.2px]">
                      <div className={"flex   justify-center space-x-4 "}>
                        {headers.includes("Items") ? (
                          ""
                        ) : !isStudent &&(
                          !isStudent?
                          <img
                            onClick={() =>
                              editRecord({ index: index, ...item })
                            }
                            className="cursor-pointer"
                            src={EditIcon}
                            alt="..."
                          />
                        :'')}
{
   !isStudent &&(
    headers.includes('Label')?
    item.hidden?
    <img
    onClick={() =>
      hideField({ index: index ,...item },true)
    }
    className="cursor-pointer "
    src={NotVisibleIcon}
    alt="..."/>:
    <img 
    className="cursor-pointer "
    onClick={() =>
      hideField({ index: index ,...item },true)
    }
    src={visibleIcon}
    alt="..."
    />
    :''
 

   )
}
                       {
                        !isStudent &&(
                          item.label!=='Name' && item.label !=='Class' && item.label !=='Available Funds' && item.label !=='Total Guests' ? 
                         <img
                         onClick={() => deleteRecord(index)}
                         className="cursor-pointer"
                         src={TrashIcon}
                         alt="..."
                         />
                     
             :'') }

             {
              !isStudent && (
                headers.includes('Game Objectives')?  <img
                onClick={() => deleteRecord(index,true)}
                className="cursor-pointer"
                src={ResetIcon}
                alt="..."
                />:''
              )
             }
             
                      </div>
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={transformedHeaders.length + 2}
                className="text-center"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <ItemsModal name={personName} showItemsModal={showItemsModal} modalItems={modalItems} onOutsideClick={onOutsideModalClick}/>
    </div>
  );
}
