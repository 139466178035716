import React, { useEffect, useRef, useState } from "react";
import "../../App.css";
import AdminButton from "./AdminButton";
import SelectFilter from "./SelectFilter";

export default function AddInputField({
  showInputFieldModal,
  shouldShowInputFieldModal,
  addTextField,
  fieldEditData,
  editInputField,
  onUpdateCall,
}) {
  const modalRef = useRef(null);

  const [formData, setFormData] = useState({
    label: "",
    value: "",
    type: "Text Field",
    status: false,
  });

  const setValue = (e) => {
    const { id, value, type } = e.target; // Destructure id and value from e.target

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [id]: !formData.status,
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const onSubmission = () => {
    addTextField(formData);

    setFormData({
      label: "",
      value: "",
      type: "Text Field",
      status: false,
    });
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setFormData({
          label: "",
          value: "",
          type: "Text Field",
          status: false,
        });
        // Clicked outside the modal
        shouldShowInputFieldModal();
      }
    }

    if (showInputFieldModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formData, showInputFieldModal, shouldShowInputFieldModal]);

  useEffect(() => {
    if (editInputField === true) {
      setFormData({
        label: fieldEditData.label,
        value: fieldEditData.value,
        type: fieldEditData.type,
        status: fieldEditData.status,
        hidden: fieldEditData.hidden,
      });
    }
  }, [fieldEditData, editInputField]);

  const updateCall = () => {
    onUpdateCall(formData);
    setFormData({
      label: "",
      value: "",
      type: "Text Field",
      status: false,
    });
  };
  return (
    <div
      ref={modalRef}
      className={`z-100 admin-font flex justify-center  absolute duration-500 left-[10%] md:left-[35%] w-[300px] md:w-[400px] h-[600px] bg-white rounded-lg shadow-lg ${
        showInputFieldModal ? "top-[10%]" : "-top-[100%] "
      }`}
    >
      <div className="w-full text-center">
        <h1 className="text-[18px] my-4">
          {editInputField ? "Edit Input Field" : "Add Input Field"}
        </h1>
        <button
          onClick={shouldShowInputFieldModal}
          className="absolute top-2 right-3 text-red-500"
        >
          x
        </button>
        <hr className="mx-10"></hr>
        <div className="text-start mx-10 my-8 space-y-7 ">
          <div className="space-y-1">
            <h1>
              Enter Label<sup className="text-red-500 ">*</sup>
            </h1>
            <input
              disabled={
                editInputField &&
                formData.label === "Name" ||
                formData.label === "Class"||
                formData.label === "Available Funds" ||
                formData.label === "Total Guests"
                  ? true
                  : false
              }
              id="label"
              value={formData.label}
              onChange={setValue}
              className="border-2 border-[#CACACA] pl-2 outline-[#0E9F6E] h-[39px] w-full rounded-md"
              type="text"
            />
          </div>
          <div className="space-y-1">
            <h1>Enter Value</h1>
            <input
              id="value"
              value={formData.value}
              onChange={setValue}
              className="border-2 border-[#CACACA] pl-2 outline-[#0E9F6E] h-[39px] w-full rounded-md"
              type="text"
            />
          </div>

          <div className="space-y-1">
            <h1>
              Type<sup className="text-red-500 ">*</sup>
            </h1>
            <SelectFilter
              value={formData.type}
              onChange={setValue}
              id={"type"}
              options={[
                "Text Field",
                "Input with stepper",
                "Checkbox",
                "Radio Button",
                "Date Time Picker",
              ]}
              classes={"w-full h-[39px] bg-white "}
            />
          </div>
          <div className="space-y-1">
            <h1>Status</h1>

            <div className="border-2 border-[#CACACA] px-2 outline-[#0E9F6E] h-[39px] w-full rounded-md flex justify-between items-center">
              <h1>Predefine</h1>
              <input
                id="status"
                value={formData.status}
                onChange={setValue}
                checked={formData.status}
                className="w-[24px] h-[16px]"
                type="checkbox"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-center mx-10 pt-4">
          {editInputField ? (
            <AdminButton
              btnText={"Update Field"}
              classess={"w-full"}
              onClickCall={updateCall}
            />
          ) : (
            <AdminButton
              btnText={"Add Field"}
              classess={"w-full"}
              onClickCall={onSubmission}
            />
          )}
        </div>
      </div>
    </div>
  );
}
