import React, { useRef, useState } from "react";
import elipsis from "../../assets/elipsis.png";
import { Link, useNavigate } from "react-router-dom";
import Headers from "../../components/game/Headers";
import GameButton from "../../components/game/GameButton";
import orangePng from "../../assets/orange.png";
import apiService from "../../apiService/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Legals from "../../components/Legals";


export default function PinCode() {
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const pin = Array(4).fill("");
  const [loading,setLoading]=useState(false)
  document.title = `ScanQuest | Login`;

  localStorage.removeItem('products')
  localStorage.removeItem('studentsMaintained')
  // Function to handle input changes
  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (value && value.length === e.target.maxLength) {
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
        pin[index] = value;
      } else if (inputRefs.length - 1 === index) {
        pin[index] = value;

        formSubmitted();
      }
    }
    else
    {
        pin[index]=''
    }
  };

  const handleBackspace = (e, index) => {
    if (e.keyCode === 8 && index > 0 && e.target.value === "") {
      inputRefs[index - 1].current.focus();
    }
  };
  let navigate = useNavigate();

  const formSubmitted = () => {
    if (pin.some((element) => element === "")) {
      toast.error("Please enter all digits.", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
      });
    } else {
      setLoading(true)
    
        
      const data = {
        pin_code: pin.join(""),
        code_for: "student",
      };
      apiService
        .post("user/validate",data)
        .then((res) => {
          localStorage.setItem("cad",res.data.token)

          localStorage.setItem("game_name",res.data.data[0].game_name)
          localStorage.setItem("id",btoa(res.data.data[0].code_id))
          localStorage.setItem("gid",btoa(res.data.data[0].game_id))
          localStorage.setItem('cid',btoa(pin.join('')))
          localStorage.setItem('objectives',res.data.data[0].game_objectives)
      setLoading(false)

          navigate("/objectives");
        })
        .catch((err) => {

      setLoading(false)
      

          if(err.response.status===401)
          {
          toast.error(err.response.data.message, {
            position: "top-center",
            autoClose: 2000,
            closeOnClick: true,
          });
        }
          
         pin.fill("");
         inputRefs.forEach((ref, index) => {
          ref.current.value = "";
        });
          inputRefs[0].current.focus();
          console.log(err)
        });
 
    }
  };

  return (
    <>
    <div className="flex items-center h-screen   justify-center align-center">
     

      <div className="bg-black/60  w-full md:mx-0 mx-4 md:w-[418px] rounded-[20px] pb-20 game-border">
        <Headers
          text="Welcome"
          imgName={orangePng}
          classes={"md:text-5xl text-[24px] uppercase"}
          />

        <div className="game-font text-white mt-16 text-center flex justify-center">
          <div className="box  h-80 pt-5 mt-2 rounded-[16px] w-80">
            <div>
              <h1 className="text-2xl mb-4">Enter 4-digit pin</h1>
              <div className="mx-5">
                <img src={elipsis} alt="" className="h-[4px] w-full " />
              </div>

              <div className="flex justify-center text-2xl space-x-5  mt-10 mb-10">
                {inputRefs.map((ref, index) => (
                  <input
                    key={index}
                    required
                    type="text"
                    className="w-12 h-14 bg-[#cacaca4d] focus:border-2 focus:border-[#EF9910] focus:bg-[#ffcd1433] focus:outline-none rounded-lg text-center"
                    maxLength={1}
                    ref={ref}
                    onInput={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleBackspace(e, index)}
                    inputMode="numeric"
                    />
                    ))}
              </div>

              <GameButton text="Submit" callFunction={formSubmitted}loading={loading} />

              <div className="mx-5 mt-6 relative">
                <img src={elipsis} alt="" className="h-[4px] w-full " />
                <div className="absolute pin-box h-12 -top-5 w-full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <ToastContainer />
      <div className="fixed left-0 right-0 bottom-2 flex justify-center">
  <Legals isGame={true} classes={'text-center'} />
</div>
                    </>
  );
}
