import React from 'react'
import spinner from '../../assets/spinner.svg'
export default function GameButton({text,callFunction,classes,loading=false}) {
  return (
    <div onClick={callFunction} className={`relative  inline-block rounded-[20px] cursor-pointer md:mb-0 mb-4 ${classes} `}>
    <div className='game-headers-bg game-font rounded-lg '>
      {
loading?
<img src={spinner} className='animate-spin text-center h-10 mx-auto px-16 py-2' alt='loading'/>
:  
        <input  type='submit' value={text} className=' text-[16px] md:text-[24px] text-shad cursor-pointer md:text-2xl text py-2 px-10 z-20 stroke-[10px] stroke-red-700' />
      }
    </div>
    <div className='absolute -top-1 left-1 opacity-75 h-4  game-headers-bg2 w-full '></div>
    <div className='absolute  -bottom-1 left-2 opacity-75 h-3 game-headers-bg2 w-full '></div>
    <div className='absolute  -left-5 opacity-[10%] h-16 game-headers-bg2 w-16 '></div>
    <div className='absolute  -right-5 opacity-[10%] h-16  game-headers-bg2 w-16 '></div>
    <div className='absolute  bottom-1 shadow-md   rounded-br-md rounded-bl-md w-full opacity-25 h-1/2 bg-[#A55409]/50'>
    </div>
</div>
  )
}
