import React, { useEffect, useRef, useState } from "react";
import GoBack from "../../components/game/GoBack";
import ScanImg from "../../assets/scan.png";
import ResetImg from "../../assets/reset.png";
import ProductContainer from "../../components/game/ProductContainer";
import Sidebar from "../../components/game/Sidebar";
import Scan from "./Scan";

import ProductsData from "./ProductData";
import WithAuth from "../../apiService/withAuth";
import apiService from "../../apiService/apiService";
import Toast from "../../components/Toast";
import { ToastContainer } from "react-toastify";
import ResetModal from "../../components/game/ResetModal";
import Legals from "../../components/Legals";
import ObjectivesIcon from '../../assets/objectivesIcon.png'
import ObjectiveModal from '../../components/game/ObjectiveModal'

export default function Cart() {
  const isAuthenticated = WithAuth("cad");

  document.title = `ScanQuest | Cart`;

  const [studentData, setStudentData] = useState({
    student_name: "",
    student_class: "",
    total_guests: "0",
    available_fund: "0",
    total_cost: "0",
    amount_left: "0",
    total_happiness_rating: "0",
    balance_shortfall: "0",
    adjusted_happiness_rating: "0",
  });

  const [rules, setRules] = useState(null);

  const [showResetModal, setShowResetModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [showObjective,setShowObjective]=useState(false)

  const onResetButtonClick = () => {
    setStudentData({
      ...studentData,
      total_cost: "0",
      amount_left: "0",
      total_happiness_rating: "0",
      balance_shortfall: "0",
      adjusted_happiness_rating: "0",
    });

    setProducts([]);

    localStorage.removeItem('products')
    localStorage.removeItem('studentsMaintained')
    setShowResetModal(false);
  };
  function minusClick(number) {
 
    const updatedProducts = [...products];
    
    const i=number-1

  

 
    const cost = Number(studentData.total_cost) - (Number(products[i].cost)*Number(products[i].quantity));
    const amount_left = Number(studentData.available_fund) - Number(cost);
    const happiness_rating =
      Number(studentData.total_happiness_rating) -
      Number(updatedProducts[i].happiness_rating);
    const balance_shortfall =
      Number(studentData.available_fund) - Number(cost);

    let adjusted_happiness_rating;

    if (Number(balance_shortfall) < 0) {
      const testRating =
        Math.abs(Number(balance_shortfall)) /
        Number(studentData.total_guests);
      const rating = evaluateRulesForData(testRating, rules);

      adjusted_happiness_rating =  happiness_rating - rating;
    } else {
      adjusted_happiness_rating = happiness_rating;
    }

    setStudentData({
      ...studentData,
      total_cost: cost,
      amount_left: amount_left===studentData.available_fund?0:amount_left,
      total_happiness_rating: happiness_rating,
      balance_shortfall: balance_shortfall===cost?0:amount_left,
      adjusted_happiness_rating: adjusted_happiness_rating,
    });
    localStorage.setItem('studentsMaintained',JSON.stringify( {...studentData,
      total_cost: cost,
      amount_left: amount_left,
      total_happiness_rating: happiness_rating,
      balance_shortfall: balance_shortfall,
      adjusted_happiness_rating: adjusted_happiness_rating}))


    updatedProducts.splice(number - 1, 1);

    
    setProducts(updatedProducts);
    localStorage.setItem('products',JSON.stringify(updatedProducts))
  }

  const decreaseCount = (i, quantity) => {
    if (Number(quantity) - 1 > 0) {
      setProducts((prevProducts) => {
        const updatedProducts = [...prevProducts];
        updatedProducts[i].quantity = Number(quantity) - 1;

      localStorage.setItem('products',JSON.stringify(updatedProducts))

        const cost = Number(studentData.total_cost) - Number(products[i].cost);
        const amount_left = Number(studentData.available_fund) - Number(cost);
        const happiness_rating =studentData.total_happiness_rating
          // Number(studentData.total_happiness_rating) -
          // Number(updatedProducts[i].happiness_rating);
        const balance_shortfall =
          Number(studentData.available_fund) - Number(cost);

        let adjusted_happiness_rating;

        if (Number(balance_shortfall) < 0) {
          const testRating =
            Math.abs(Number(balance_shortfall)) /
            Number(studentData.total_guests);
          const rating = evaluateRulesForData(testRating, rules);

          adjusted_happiness_rating = happiness_rating - rating;
        } else {
          adjusted_happiness_rating = happiness_rating;
        }

       

        setStudentData({
          ...studentData,
          total_cost: cost,
          amount_left: amount_left,
          // total_happiness_rating: happiness_rating,
          balance_shortfall: balance_shortfall,
          adjusted_happiness_rating: adjusted_happiness_rating,
        });
        localStorage.setItem('studentsMaintained',JSON.stringify( {...studentData,
          total_cost: cost,
          amount_left: amount_left,
          total_happiness_rating: happiness_rating,
          balance_shortfall: balance_shortfall,
          adjusted_happiness_rating: adjusted_happiness_rating}))


        return updatedProducts;
      });
    }
  };

  function evaluateRulesForData(rating, rules) {
  
    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i];

      const ratingValue = parseFloat(rating);
      const ruleRatingFrom = parseFloat(rule.rating_from);
      const ruleRatingTo = parseFloat(rule.rating_to);

      const isFromConditionMet =
        (rule.from_select === "Greater" && ratingValue > ruleRatingFrom) ||
        (rule.from_select === "Less" && ratingValue < ruleRatingFrom) ||
        (rule.from_select === "Greater & Equal" && ratingValue >= ruleRatingFrom)||
        (rule.from_select === "Less & Equal" && ratingValue <= ruleRatingFrom);
        

      const isToConditionMet =
        (rule.to_select === "Greater" && ratingValue > ruleRatingTo) ||
        (rule.to_select === "Less" && ratingValue < ruleRatingTo) ||
        (rule.to_select === "Greater & Equal" && ratingValue >= ruleRatingTo)||
        (rule.to_select === "Less & Equal" && ratingValue <= ruleRatingTo);

      if (isFromConditionMet && isToConditionMet) {
  
        return rule.rating_deducted;
      }
    }
    return 0; // Return null if no rule matches
  }

  const increaseCount = (i, quantity) => {
  
    setProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts[i].quantity = Number(quantity) + 1;

      localStorage.setItem('products',JSON.stringify(updatedProducts))
      const cost = Number(studentData.total_cost) + Number(products[i].cost);
      const amount_left = Number(studentData.available_fund) - Number(cost);
      const happiness_rating =studentData.total_happiness_rating
        // Number(updatedProducts[i].happiness_rating) +
        // Number(studentData.total_happiness_rating);
      const balance_shortfall =
        Number(studentData.available_fund) - Number(cost);
      let adjusted_happiness_rating;

      if (Number(balance_shortfall) < 0) {
        const testRating =
        Math.abs(Number(balance_shortfall)) /
        Number(studentData.total_guests);
    
        const rating = evaluateRulesForData(testRating, rules);

        adjusted_happiness_rating = happiness_rating - rating;
      } else {
        adjusted_happiness_rating = happiness_rating;
      }

    
      setStudentData({
        ...studentData,
        total_cost: cost,
        amount_left: amount_left,
        // total_happiness_rating: happiness_rating,
        balance_shortfall: balance_shortfall,
        adjusted_happiness_rating: adjusted_happiness_rating,
      });

      localStorage.setItem('studentsMaintained',JSON.stringify( {...studentData,
        total_cost: cost,
        amount_left: amount_left,
        total_happiness_rating: happiness_rating,
        balance_shortfall: balance_shortfall,
        adjusted_happiness_rating: adjusted_happiness_rating}))

      return updatedProducts;
    });
  };

  const [shouldScan, setShouldScan] = useState(false);

  function setScanner(value, data = "") {
    if(data)
    {

    
    const tempProd = products;
    let flag = 0;
    products.map((item) => {
      if (item.qr_code === data) {
        flag = 1;
        item.quantity = item.quantity + 1;
        const cost = Number(studentData.total_cost) + Number(item.cost);
        const amount_left = Number(studentData.available_fund) - Number(cost);
        const happiness_rating =studentData.total_happiness_rating
          // Number(item.happiness_rating) +
          // Number(studentData.total_happiness_rating);
        const balance_shortfall =
          Number(studentData.available_fund) - Number(cost);
        const test =
          (Number(studentData.available_fund) -
            Number(studentData.total_cost)) /
          Number(studentData.total_guests);

        let adjusted_happiness_rating;

        if (Number(balance_shortfall) < 0) {
          const testRating =
            Math.abs(Number(balance_shortfall)) /
            Number(studentData.total_guests);
          const rating = evaluateRulesForData(testRating, rules);

          adjusted_happiness_rating = happiness_rating - rating;
        } else {
          adjusted_happiness_rating = happiness_rating;
        }

        setStudentData({
          ...studentData,
          total_cost: cost,
          amount_left: amount_left,
          total_happiness_rating: happiness_rating,
          balance_shortfall: balance_shortfall,
          adjusted_happiness_rating: adjusted_happiness_rating,
        });

        localStorage.setItem('studentsMaintained',JSON.stringify( {...studentData,
          total_cost: cost,
          amount_left: amount_left,
          total_happiness_rating: happiness_rating,
          balance_shortfall: balance_shortfall,
          adjusted_happiness_rating: adjusted_happiness_rating}))

      }
    });

    if (flag === 1) {
      // Exit the function early if flag is 1
   
      setShouldScan(value);
      return;
    }
    apiService
      .get(
        `/products/get-product?product_id=${data}&game_id=${atob(
          localStorage.getItem("gid")
        )}`,
        "cad"
      )
      .then((res) => {
        if (res.data.data.length > 0) {
          res.data.data[0].quantity = 1;
          setProducts((prevProducts) => [...prevProducts, res.data.data[0]]);

          const updatedProducts=[...products,res.data.data[0]]
          localStorage.setItem('products',JSON.stringify(updatedProducts))
          const cost =
            Number(studentData.total_cost) + Number(res.data.data[0].cost);
          const amount_left = Number(studentData.available_fund) - Number(cost);
          const happiness_rating =
            Number(res.data.data[0].happiness_rating) +
            Number(studentData.total_happiness_rating);
          const balance_shortfall =
            Number(studentData.available_fund) - Number(cost);

          let adjusted_happiness_rating;

          if (Number(balance_shortfall) < 0) {
            const testRating =
              Math.abs(Number(balance_shortfall)) /
              Number(studentData.total_guests);
            const rating = evaluateRulesForData(testRating, rules);

            adjusted_happiness_rating = happiness_rating - rating;
          } else {
            adjusted_happiness_rating = happiness_rating;
          }
         

          setStudentData({
            ...studentData,
            total_cost: cost,
            amount_left: amount_left,
            total_happiness_rating: happiness_rating,
            balance_shortfall: balance_shortfall,
            adjusted_happiness_rating: adjusted_happiness_rating,
          });

          localStorage.setItem('studentsMaintained',JSON.stringify( {...studentData,
          total_cost: cost,
          amount_left: amount_left,
          total_happiness_rating: happiness_rating,
          balance_shortfall: balance_shortfall,
          adjusted_happiness_rating: adjusted_happiness_rating}))

      
        } else {
          Toast("Product not found", 0);
        }
        setShouldScan(value);
      })
      .catch((err) => {
        // Toast('Product Not found',0)
        if (err.response.status === 401) {
          localStorage.clear();
          WithAuth("cad");
        }
        Toast(err.response.data.error, 0);
      });
    }
    else
    {
        setShouldScan(value)
    }
  }

  const showResetCartModal = () => {
    if (products.length > 0) {
      setShowResetModal(!showResetModal);
    } else {
      Toast("No products in the cart to reset!", 0);
    }
  };
       
const modalRef = useRef(null);
 


  useEffect(() => {
   
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside the modal
       setShowObjective(false)
      }
    }
  
    if (showObjective) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  
    return () => {
       
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [products, studentData,showObjective]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("studentData"));
    const dataMappings = {
      'Name': 'student_name',
      'Class': 'student_class',
      'Total Guests': 'total_guests',
      'Available Funds': 'available_fund'
      // Add more mappings if needed for other indices
  };

  const updatedStudentData = { ...studentData };

  data.forEach((item) => {
    const key = dataMappings[item.label];
    
      if (key) {
          updatedStudentData[key] = item.value;
      }
  });

 
  setStudentData(updatedStudentData);

    apiService
      .get(
        `/happiness-rating/get-rules/${atob(localStorage.getItem("gid"))}`,
        "cad"
      )
      .then((res) => {
        setRules(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Toast(err.response.data.message, 0);
          localStorage.clear();
          WithAuth("cad");
        }
        Toast(err.response.data.error, 0);
      });

       if(localStorage.getItem('products'))
       {
        
        setProducts(JSON.parse(localStorage.getItem('products')))
        setStudentData(JSON.parse(localStorage.getItem('studentsMaintained')))
       }
    
  }, []);

  const openObjective=()=>
  {
    setShowObjective(true)
  }

  const closeObjective=()=>
  {
    setShowObjective(false)
  }
  return (
    isAuthenticated && (
      <div>
        <div className="sm:flex justify-between pt-5 mx-5 overflow-x-hidden">
          <GoBack text={"BACK"} classes={"static "} />
          <hr className="border-[#EF9910] my-2 border-[1.5px] block sm:hidden "></hr>
          <div className="flex space-x-4 md:space-x-10 mr-5  ">
          <button
              className="flex text-white items-center text-[20px] md:text-[26px] game-font text-[#F8EDE4] space-x-2"
              onClick={openObjective}
            >
              <img src={ObjectivesIcon} alt="..." className="md:w-full  w-[24px]" />
              <h1>Objectives</h1>
            </button>
            <button
              onClick={() => setShouldScan(true)}
              className="flex text-white items-center text-[20px] md:text-[26px] game-font text-[#F8EDE4] space-x-2 "
            >
              <img src={ScanImg} alt="..." className="md:w-full  w-[24px]" />
              <h1>SCAN</h1>
            </button>
            <button
              className="flex text-white items-center text-[20px] md:text-[26px] game-font text-[#F8EDE4] space-x-2"
              onClick={showResetCartModal}
            >
              <img src={ResetImg} alt="..." className="md:w-full  w-[24px]" />
              <h1>RESET</h1>
            </button>
          </div>
        </div>

        <div className="flex md:mt-8 mt-2 ">
          <Sidebar studentData={studentData} products={products} rules={rules}/>

          <div
            className={` w-full h-[82vh] bg-black/60 mt-2 md:mr-8  mr-2 ml-8  md:mx-0 game-border rounded-lg ${
              shouldScan === false ? "overflow-y-scroll scrollbar " : ""
            } pb-4`}
          >
            {shouldScan ? (
              <Scan setScanner={setScanner} />
            ) : (
              <div className="md:flex justify-start md:justify-evenly flex-wrap md:px-10">
                {products.map((product, index) => {
                  return (
                    <ProductContainer
                    key={index}
                      number={index + 1}
                      image={product.image}
                      title={product.name}
                      price={product.cost}
                      rating={product.happiness_rating}
                      quantity={product.quantity}
                      onMinusClick={minusClick}
                      increaseCount={increaseCount}
                      decreaseCount={decreaseCount}
                    />
                  );
                })}
              </div>
            )}
        
          </div>
        </div>
        <ToastContainer />
        <ResetModal
          isOpen={showResetModal}
          onYesClick={onResetButtonClick}
          onClose={showResetCartModal}
        />
        <div ref={modalRef} className={`absolute  left-[5vw] sm:left-[12vw]   md:left-[43vw] top-[15vh] md:top-[20vh] flex items-center   shadow-lg game-font justify-center  ${showObjective ? 'block' : 'hidden'}`}>
   
        <ObjectiveModal
          isOpen={showObjective}

          onClose={closeObjective}
        />
        </div>
      
      </div>
    )
  );
}
