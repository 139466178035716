import React, { useState } from 'react'
import AdminHeader from '../../components/Admin/AdminHeader'
import Headbar from '../../components/Admin/Headbar'
import AdminButton from '../../components/Admin/AdminButton'
import Table from '../../components/Admin/Table'
import SelectFilter from '../../components/Admin/SelectFilter'
import CategoryDrawer from '../../components/Admin/CategoryDrawer'
import { useEffect } from 'react'
import DeleteModal from '../../components/Admin/DeleteModal'
import WithAuth from '../../apiService/withAuth'
import apiService from '../../apiService/apiService'
import Toast from '../../components/Toast'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeftArrow from '../../assets/pagination-left-arrow.png'
import RightArrow from '../../assets/pagination_right_arrow.png'
import FilterIcon from '../../assets/filterIcon.png'

export default function Categories() {

  const isAuthenticated=WithAuth('ad')
  const [categories, setCategories] = useState([])

  document.title = `ScanQuest | Admin | Categories`;

  const [editData, setEditData] = useState({
    category_id:'',
    category_name: '',
    game_name: '',
    minimum_products: ''
  })

  const [loading,setLoading]=useState(true)

  const [showFilter,setShowFilter]=useState(false)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);

  const [deleteData, setDeleteData] = useState([])

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [filterData,setFilterData]=useState({
    search_query:'',
    game_name:''
  })


  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [pageSize] = useState(10);


// Calculate the total number of pages
const [totalPages,setTotalPages] =useState()


// Pagination logic to handle page changes
const handlePageChange = (page) => {

  if(currentPage!==page)
  {


    setCurrentPage(page);
    if(filterData.search_query || filterData.game_name)
    {
      filterData.game_id=null
    
  
      if(filterData.game_name)
      {
        alert('hell')
  
        filterData.game_id=JSON.parse(filterData.game_name).game_id
      }
      filterData.current_page=page
      apiService.post('/categories/search',filterData,'ad').then((res)=>
      {
        setCategories(res.data.data.data)
      }).catch((err)=>
      {
        if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)
      })
    }

    else
    {

    

    apiService.get(`/categories/get-all?pageSize=${pageSize}&pageNumber=${page}`,'ad').then((res)=>{
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setCategories(res.data.data.data)
    }).catch((err)=>{

      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)

    })
  }
}
    // Implement logic to fetch data for the selected page from the backend
    // Example API call: fetchDataFromBackend(page);
};

const generatePageNumbers = () => {
  const maxVisiblePages = 7;
  const pageNumbers = [];
  if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
      }
  } else {
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
      let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
      if (endPage - startPage < maxVisiblePages - 1) {
          startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(i);
      }

      if (startPage > 1) {
          pageNumbers.unshift('...');
      }
      if (endPage < totalPages) {
          pageNumbers.push('...');
      }
  }
  return pageNumbers;
};
// Generate pagination numbers based on total pages
const paginationNumbers = generatePageNumbers().map((pageNumber, index) => (
  <li key={index}>
      {pageNumber === '...' ? (
          <span className="px-2">...</span>
      ) : (
          <button
              className={`flex items-center justify-center px-3  h-8 leading-tight ${
                  currentPage === pageNumber ? 'text-white mx-2 font-semibold rounded-md bg-[#0E9F6E]' : 'text-gray-500 '
              }`}
              onClick={() => handlePageChange(pageNumber)}
          >
              {pageNumber}
          </button>
      )}
  </li>
));


const handlePreviousPage = () => {
  if (currentPage > 1) {
      handlePageChange(currentPage - 1);
  }
};

const handleNextPage = () => {
  if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
  }
};




  const setValue = (e) => {
    const { id, value } = e.target; // Destructure id and value from e.target
  

  
    setFilterData({
      ...filterData,
      [id]: value
    });
  
    let data={...filterData,[id]:value}
   
    data.game_id=null
    if(data.game_name)
    {

      data.game_id=JSON.parse(data.game_name).game_id
    }
     
    data.current_page=1
    
    apiService.post('/categories/search',data,'ad').then((res)=>
    {
      setCurrentPage(1)
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setCategories(res.data.data.data)
      if(e.target.value==='')
      {
    setShowFilter(false)

      }
      else
      {
    setShowFilter(true)

      }
    }).catch((err)=>
    {
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)

    })
  
  }

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const closeEditDrawer = () => {
    setEditDrawerOpen(false);
  };

  const addCategoty = (category) => {
  

    const game= JSON.parse(category.game_name)
    category.game_name=game.game_name
    category.game_id=game.game_id

    const data={
      categoryData:category,
      current_page:currentPage
    }
    apiService.post('/categories/insert',data,'ad').then((res)=>
    {
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setCategories(res.data.data.data);
  
      Toast(res.data.message,1)
      setIsDrawerOpen(false)

    }).catch((err)=>
    {
      console.log(err)
    Toast(err.response.data.error,0)

    })

  };

  const editRecord = (recordData) => {

  
    setEditData(recordData)
    setEditDrawerOpen(true)

  }
  const updateRecord = (updatedData) => {
    const records = [...categories]
  
    
    const game=JSON.parse(updatedData.game_name)
    updatedData.game_name=game.game_name
    updatedData.game_id=game.game_id
    
    records[editData.index].category_name = updatedData.category_name
    records[editData.index].game_name = updatedData.game_name
    records[editData.index].minimum_products = updatedData.minimum_products
    records[editData.index].game_name=game.game_name

    apiService.put(`/categories/update/${updatedData.category_id}`,updatedData,'ad').then((res)=>
    {
      setCategories(records)
      setEditDrawerOpen(false)
      Toast(res.data.message,1)
    }).catch((err)=>{
      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)

    })
   

  }

  const deleteRecord = (i) => {

    setCategories(categories.filter((element, index) => index !== i))
  }

  const showModal = (i) => {

    setDeleteData([...deleteData, categories[i]])
    setShowDeleteModal(prev => !prev)
  }

  const onDeleteCall = () => {

  
  apiService.delete(`/categories/delete/${deleteData[0].category_id}`,'ad').then((res)=>
  {
    setCategories(categories.filter((element, index) => !deleteData.includes(element)))
    setShowDeleteModal(prev => !prev)
    Toast(res.data.message,1)

    if(categories.length===1)
    {
   
      if(currentPage-1>0)
      {
        handlePageChange(currentPage-1)
      }
    }
  }).catch((err)=>
  {
    if(err.response.status===401)
    {

      Toast(err.response.data.message,0)
      localStorage.clear()
      WithAuth('ad')
      
    }
    Toast(err.response.data.error,0)

  })

    setDeleteData([])

  }

  const onKeepCall = () => {
    setDeleteData([])

    setShowDeleteModal(prev => !prev)

  }

  const resetFilters=()=>
  {
    setFilterData({
      search_query:'',
      game_name:''
    })

    setShowFilter(false)
    setLoading(true)
    apiService.get(`/categories/get-all?pageSize=${pageSize}&pageNumber=${1}`,'ad').then((res)=>{
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setCategories(res.data.data.data)
      setCurrentPage(1)
      setLoading(false)
    }).catch((err)=>{

      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)

    })
  }

  useEffect(() => { }, [categories, editData,showFilter])

  useEffect(()=>{
    apiService.get(`/categories/get-all?pageSize=${pageSize}&pageNumber=${1}`,'ad').then((res)=>{
      setTotalRecords(res.data.total_records)
      setTotalPages(Math.ceil(res.data.data.total_records / pageSize))
      setCategories(res.data.data.data)
      setLoading(false)
    }).catch((err)=>{

      if(err.response.status===401)
      {

        Toast(err.response.data.message,0)
        localStorage.clear()
        WithAuth('ad')
        
      }
      Toast(err.response.data.error,0)

    })
  },[])

  return (
   isAuthenticated && <div className='w-screen md:w-[85vw] min-h-screen overflow-y-auto bg-[#F9FAFB] admin-font'>
      <AdminHeader tabName={'Categories'} />
      <div className='flex justify-center mx-2 md:mx-10 '>
        <Headbar>

          <input type='text' placeholder='Seach Category' id="search_query" value={filterData.search_query} onChange={setValue} className=' pl-4 w-full md:w-[541px] h-[40px] bg-[#F4F5F7] text-[#CACACA] placeholder-[#B7B7B7] border-[1px] border-[#CACACA] rounded-lg outline-[#0E9F6E] focus:text-[#0E9F6E]' />
          <SelectFilter id={'game_name'} value={filterData.game_name} onChange={setValue} options={['Game A', 'Game B', 'Game C']} classes={'w-full md:w-[372px]'} />
          <AdminButton btnText={'+ Add Category'} onClickCall={openDrawer} />

          <CategoryDrawer heading={'Add Category'} subheading={'Add your product category and necessary information from here.'} isOpen={isDrawerOpen} onClose={closeDrawer} btnHandler={addCategoty} />
          <CategoryDrawer heading={'Update Category'} subheading={'Update you product category and necessary information from here.'} isOpen={editDrawerOpen} onClose={closeEditDrawer} btnHandler={updateRecord} editData={editData} />

        </Headbar>
      </div>

      <div className='mt-10 flex mx-2 md:mx-5 justify-center text-center '>
        <Table headers={['Category Name', 'Game Name', 'Total Products', 'Minimum Products']} data={categories} editRecord={editRecord} deleteRecord={showModal} loading={loading} />
      </div>

      <nav aria-label="Page navigation example trainer-stats-font">
            <ul className="flex justify-end my-5 mr-8 -space-x-px text-sm text-white items-center">
                    <li className="mr-2 cursor-pointer" onClick={handlePreviousPage}>
                        <img src={LeftArrow} alt="..." />
                    </li>
                    {paginationNumbers}
                    <li className="ml-2 cursor-pointer" onClick={handleNextPage}>
                        <img src={RightArrow} alt="..." />
                    </li>
                </ul>
            </nav>

      <DeleteModal showDeleteModal={showDeleteModal} onDeleteCall={onDeleteCall} onKeepCall={onKeepCall} />

      {
 
 showFilter  &&
 <div onClick={resetFilters} className='absolute cursor-pointer z-50 top-2 right-5 bg-[#0E9F6E] rounded-md p-2'>
 <img src={FilterIcon}
 className='h-6'
 />
 </div>
 }
<ToastContainer/>
    </div>

  )
}
