import React from 'react'
import Logout from '../../assets/logout.png'
import { useNavigate } from 'react-router-dom'
export default function Header({isStats,showFilter}) {
    const navigate=useNavigate()

    function goToMainPage()
    {
        
        localStorage.clear()
        navigate('/trainer/')
    }
    return (
        <div className='flex-1 items-center mt-1 px-5 py-3 shadow-md'>
            <div className='flex items-center justify-between space-x-4 md:pr-5'>
                <div className='flex items-center space-x-4'>

                    <h1 className='trainer-font text-[18px] md:text-[36px]'>SQ</h1>
                    <div className='w-[1.5px] bg-[#404EED] h-5 md:h-9'></div>
                    <h1 className='text-[18px] md:text-[28px] font-semibold'>Trainers</h1>
                </div>
                {
                    isStats?
                    <div className={`cursor-pointer ${showFilter?'pr-10':'pr-0'}`} onClick={goToMainPage}>
                    <div className='flex space-x-3 bg-[#404EED] text-white px-4 font-semibold text-[12px] md:text-[16px] py-2 rounded-lg'>
                        <img src={Logout} alt='...' className='md:h-auto h-4' />
                        <button className='pr-2'>Main Page</button>
                    </div>

                </div>:''
                }

            </div>


        </div>
    )
}
