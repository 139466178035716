

export default function WithAuth(token) {
  

  const isAuthenticated = localStorage.getItem(token); // Check if the token exists in localStorage


  if (!isAuthenticated) {
    
    if(token==='tad')
    {
      window.location.replace('/trainer/')
    }
   else if(token==='ad')
    {
      
      window.location.replace('/admin')
     
    }
    else
    {
      window.location.replace('/');
    }

  }

  return isAuthenticated;
}
//  withAuth;
