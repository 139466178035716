import React from 'react'
import LoginImg from '../../assets/admin/loginImg.png'
import { useNavigate } from 'react-router-dom';
import ResetPassword from './ResetPassword';
import { useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiService from '../../apiService/apiService';
import Toast from '../../components/Toast';
import spinner from '../../assets/spinner.svg'
import Legals from '../../components/Legals';

export default function Login() {

    const [formData,setFormData]=useState({
        email:'',
        password:''
    })
    const [loading,setLoading]=useState(false)
  document.title = `ScanQuest | Admin | Login`;

    
    const navigate=useNavigate()
    const [showResetModal,setShowResetModal]=useState(false)
   
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [id]: value
        }));
      };

      const onLogin=()=>
      {
        if(Object.values(formData).some(value => value === ''))
        {
 
           toast.error("Please enter both email and password.", {
         position: "top-center",
         autoClose: 2000,
         closeOnClick: true,
       });
    }
    else
    {
      setLoading(true)

   

        apiService.post('/user/login',formData).then((res)=>
        {
            localStorage.setItem('ad',res.data.token)
            setLoading(false)
            navigate('/admin/games')
        }).catch((err)=>{
          setLoading(false)
            if(err.response.status===401)
            {
              toast.error(err.response.data.message, {
                position: "top-center",
                autoClose: 2000,
                closeOnClick: true,
              });
              
            }
        })
 
    }
      } 

      const resetModalView=(emailSent)=>
      {
        if(emailSent)
        {
          setShowResetModal(false)
          
        }
        else
        {

          
          apiService.post('/user/forget-password').then(()=>
          {
            setShowResetModal(true)
        }).catch((err)=>
        {
          Toast('Error occured while resetting. Please try again')
        })

      }
        
      }
   
    return (
      <>
        <div className=' flex justify-center  items-center h-[85vh] md:h-[95vh] w-[100vw] admin-font text-black md:px-0 px-2'>
            <div className='w-auto h-2/3  md:w-[634px] md:h-[536px]  shadow-lg flex '>
                <img src={LoginImg} alt='...'  className='w-1/2 md:block hidden'/>
                <div className='flex justify-center items-center ml-2 md:ml-4'>
                    <div className='space-y-5 md:px-0 px-1'>
                        <h1 className='text-[24px] md:text-[30px] font-semibold'>Login</h1>
                        <div className='space-y-4 md:pr-0 pr-2'>

                            <div>
                                <span className='text-[14px] md:text-[18px]'>Email</span><br></br>

                                <input type='email' id='email' value={formData.email} onChange={handleInputChange} className='bg-[#F4F5F7] pl-2 w-full md:w-[261px] h-[35px] rounded-md p-[2px] border border-[#CACACA]' />

                            </div>

                            <div>
                                <span className='text-[14px] md:text-[18px]'>Password</span><br></br>

                                <input type='password' id='password' value={formData.password} onChange={handleInputChange} className='bg-[#F4F5F7] pl-2 w-full md:w-[261px] h-[35px] rounded-md p-[2px] border border-[#CACACA]' />

                            </div>
                        </div>
 

                        <button onClick={onLogin} className='bg-[#0E9F6E]   w-full md:w-[261px] h-[35px] text-[14px] md:text-[18px] rounded-md p-[2px] text-white text-center font-semibold border border-[#CACACA]' disabled={loading?true:false}>{!loading?'Login':<img className='animate-spin text-center h-6 mx-auto' src={spinner} alt='Loading'/>} </button>


                        <hr></hr>

                       <div onClick={()=>resetModalView(false)} className='text-center cursor-pointer'>
                        <p className='text-[#0E9F6E] text-[14px] md:text-[18px]'>Reset Password</p>
                       </div>
                       
                    </div>
                </div>
            </div>
         
               <ResetPassword showResetModal={showResetModal} resetModalView={resetModalView}/>

        </div>
        <Legals classes={' text-[#0E9F6E] text-center '}/>
           <ToastContainer/>
      </>
    )
}
